import React from "react";
import FormFooter from "../FormFooter/FormFooter";
import Images from "../../assets";
import moment from "moment";
import { useSelector } from "react-redux";
const SblcConfirm = () => {
  // const retrievedData = JSON.parse(sessionStorage.getItem("formdata"));
  const retrievedData = useSelector((state) => state?.formdata)
 
  return (
    <>
      <div className="status-waiver-block pt-2">
        <div className="px-4 " style={{ height: "1133px" }}>
          <div className="status-logo py-1 ">
            <img
              src={Images.logo}
              alt="logo"
              style={{ height: "90%", width: "30%" }}
            />
          </div>
          <div className="d-flex flex-column py-4">
            <div className="d-flex justify-content-between py-2">
              <p className="status-waiver-content">Letter No.</p>
              <p className="status-waiver-content">
                Date : {moment().format("DD.MM.YYYY")}
              </p>
            </div>
            <div className="d-flex flex-column  py-2">
              <p className="status-waiver-content">To</p>
              <p className="status-waiver-content">The Branch Manager</p>
              <p className="status-waiver-content">Branch : </p>
              <p className="status-waiver-content">The South Indian Bank Ltd</p>
            </div>

            <div className="pt-2 d-flex flex-column  py-2">
              <p className="status-waiver-content">Sir/Madam</p>
              <p className="status-waiver-content">
                Sub: We request you to issue/rollover trade credit through stand
                by letter of credit on our behalf for the following outstanding
                import bill,
              </p>
            </div>
            <table className="sblc-table my-2  py-2">
              <thead></thead>
              <tbody>
                <tr>
                  <td className="sblc-table-data">Bill Reference No:</td>
                  <td className="sblc-table-data">{retrievedData?.blNo}</td>
                </tr>
                <tr>
                  <td className="sblc-table-data">Drawer:</td>
                  <td className="sblc-table-data">
                    {/* FORWOOD INTERNATIONAL PTE LTD */}
                    {retrievedData && retrievedData?.exporter
                      ? retrievedData && retrievedData?.exporter
                      : null}
                  </td>
                </tr>
                <tr>
                  <td className="sblc-table-data">Drawee:</td>
                  <td className="sblc-table-data">BEST TIMBERS</td>
                </tr>
                <tr>
                  <td className="sblc-table-data">Currency and Amount:</td>
                  <td className="sblc-table-data">
                    {" "}
                    USD
                    {retrievedData && retrievedData?.bgAmtAndInterestAmt
                      ? retrievedData && retrievedData?.bgAmtAndInterestAmt
                      : null}
                  </td>
                </tr>
                <tr>
                  <td className="sblc-table-data">Bill Due Date:</td>
                  <td className="sblc-table-data">
                    {/* {moment(retrievedData?.dueDate).format("DD/MM/YYYY")} */}
                  </td>
                </tr>
                <tr>
                  <td className="sblc-table-data">Tenor</td>
                  <td className="sblc-table-data">180 DAYS </td>
                </tr>
              </tbody>
            </table>
            <p className="status-waiver-content  py-2">
              Please process the trade credit request and arrange to send your
              stand by letter of credit to
              <u>
                {/* UCO BANK, HONG KONG */}{" "}
                {retrievedData && retrievedData?.foreignBankName
                  ? retrievedData && retrievedData?.foreignBankName
                  : null}
              </u>{" "}
              Bank and confirm.
            </p>
            <p className="status-waiver-content  py-2">
              We hereby agree with all the terms and conditions of stand by
              letter of credit which will be issued by you to the lending party
              on our behalf.
            </p>
            <p className="status-waiver-content  py-2">
              We confirm that we have not availed trade credit against above
              mentioned import transaction from any other bank or financial
              institution.
            </p>
            <p className="status-waiver-content  py-2">
              We also confirm that the period of trade credit is within our
              operating cycle.
            </p>
            <p className="status-waiver-content  py-2">
              We hereby authorize you to debit our following account number with
              you 0138084000000004 towards repayment of trade credit together
              with interest and other charges on maturity.
            </p>
            <p className="status-waiver-content  py-2">Thanking you,</p>
            <p className="status-waiver-content  mt-4">BEST TIMBERS</p>
            <p className="status-waiver-content mt-4">PARTNER</p>
            <p className="status-waiver-content mt-4">Encl: Form A1</p>
          </div>
        </div>
        <footer>
          <FormFooter />
        </footer>
      </div>
    </>
  );
};

export default SblcConfirm;
