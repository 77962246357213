import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import InputField from "../inputField/InputField";
import Strings from "../../res/String";
import addIcon from "../../assets/img/addIcon.svg";
import deleteIcon from "../../assets/img/delete.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import PopUpModal from "../popupmodal/PopUpModal";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import logo from "../../assets/img/timber_logo.png";
import downloadfile from "../../assets/img/downloadfile.png";
import { Button, IconButton } from "@mui/material";
import FileUploadSection from "./FileUploadSection";
import { useMutation } from "react-query";
import axios from "axios";
import FileUploadContainer from "./FileUploadContainer";
import * as XLSX from "xlsx";
import { useLocation, useNavigate } from "react-router-dom";
import RightSideDrawer from "../Drawer/Drawer";
import { file } from "jszip";
import { useSelector } from "react-redux";
import { KeyboardReturnRounded } from "@mui/icons-material";

const NewDataForm = () => {
  const { state } = useLocation();
  const copydocs = useSelector((state) => state?.copydoc);

  // API Start
  const navigate = useNavigate();

  console.log("state", state);
  const { mutate, isLoading, isError, isSuccess } = useMutation(
    (postData) => {
      // Log postData before making the API request
      console.log("postData before API call:", postData);

      return axios
        .post(`${process.env.REACT_APP_HOST}invoices`, postData, {
          maxContentLength: Infinity,
          maxBodyLength: Infinity,
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("API response:", response.data);
          return response.data; // Return response data if needed
        })
        .catch((error) => {
          console.error("Error occurred:", error);
          throw error; // Re-throw error to be caught by onError
        });
    },
    {
      onSuccess: () => {
        console.log("User added successfully..");
        setPopupOpen(1);
      },
      onError: (error) => {
        console.error("Mutation failed:", error);
      },
    }
  );
  // UPDATE API Start
  const UpdateAPI = useMutation(
    (postdata) =>
      axios.put(
        `${process.env.REACT_APP_HOST}/invoices/${state && state?.id}`,
        postdata
      ),
    {
      onSuccess: (val) => {
        console.log("data updated successfully..");
        setPopupOpen(!isPopupOpen);
      },
      onError: (error) => {
        console.error("Mutation failed:", error);
      },
    }
  );

  // API END
  const [base64String, setBase64String] = useState([]);
  const [base64Xlsx, setBase64Xlsx] = useState("");
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fileName: "",
      exporter: "",
      dataEntryForm: [
        {
          containerNo: "",
          averageLength: "",
          avgCftAvgGirth: "",
          pcs: "",
          grossCbm: "",
          netCbm: "",
          avgRate: "",
          amt: "",
          lorry: "",
          sellRate: "",
          sellCbm: "",
          brijeshComm: "",
          agent: "",
          pkl: "",
          containerImage: [],
        },
      ],
    },
  });

  const { append, remove, fields } = useFieldArray({
    name: "dataEntryForm",
    control,
  });
  const [imageName, setImageName] = useState({});
  const excelInputRef = useRef(null);
  const fileInputRef = useRef(null);

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const [isPopupOpen, setPopupOpen] = useState(false);

  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };
  // const dataEntryForm = watch("dataEntryForm", []);
  const {
    pqRelaxcheck,
    pqWeightcheck,
    billno,
    exporter,
    address,
    invoiceno,
    invoicedt,
    invoiceQuantity,
    invoiceRate,
    sblcInterestCharges,
    sblcInterestUsdRate,
    bgAmtInterestAmt,
    bgNumber,
    bgDate,
    foreignBankName,
    bgCharges,
    upFrontCharges,
    advice,
    margin10,
    margin5,
    adviceGst,
    M1AccountNumber,
    M2AccountNumber,
    beNo,
    beDate,
    beUsd,
    customDuty,
    socialWelfareCharges,
    beIgst,
    assessValue,
    shippingBill,
    pqFumigation,
    pqWeight,
    fumigationCharge,
    containersNumber,
    chaSurveyNew,
    cfsChargesNew,
    dueDate,
    bankDeductionDate,
    commodify,
    exchangeRate,
    dateOfShipping,
    forwardContractDate,
    etaDate,
    fileName,
    forwardcontractamount,
    cfsCharges,
    chaSurvey,
    cfsGroundRent,
    examinationChargenew,
    cfsGroundRentNew,
    freightChargeNew,
    unloadingNew,
    sec49New,
    yardRentNew,
    examinationCharge,
    freightCharge,
    unLoading,
    extraExpenses,
    sec49,
    totalCft,
    totalInt,
    od,
    agents,
    yardRent,
    cfsName,
    totalIndirectExpenses,
    dutyPaymentDate,
    cost,
    totalPcs,
    totalGrossCbm,
    totalNetCbm,
    totalAvgRate,
    totalAmount,
    roundOff,
    totalInvoiceAmt,
    brijesh,
    dataEntryForm,
  } = watch();
  // <<<<<START
  useEffect(() => {
    // BG-CHARGES
    const bggst = (Number(bgCharges) * 18) / 100;
    const bgInvs = Number(bgCharges) + Number(bggst);
    const bgval = Number(
      (bgInvs * 6) / 100 / Number(invoiceQuantity) / 35.315
    ).toFixed(2);

    // UPFRONTCHARGES
    const frongst = (Number(upFrontCharges) * 18) / 100;
    const fronInvs = Number(upFrontCharges) + Number(frongst);
    const fronval = Number(
      (fronInvs * 6) / 100 / Number(invoiceQuantity) / 35.315
    ).toFixed(2);

    // ADVICE
    const advisinst = Number(advice) + Number(adviceGst);
    const advisval = Number(
      (advisinst * 6) / 100 / Number(invoiceQuantity) / 35.315
    ).toFixed(2);

    // MARGIN 10
    const marginval = Number(
      (margin10 * 2.5) / 100 / Number(invoiceQuantity) / 35.315
    ).toFixed(2);

    // MARGIN 5
    const marginvalfive = Number(
      (margin5 * 2.5) / 100 / Number(invoiceQuantity) / 35.315
    ).toFixed(2);

    // customDuty
    const customDutyval = Number(
      (customDuty * 6) / 100 / Number(invoiceQuantity) / 35.315
    ).toFixed(2);

    // socialWelfareCharge
    const socialWelfareChargeval = Number(
      (Number(socialWelfareCharges) * 6) /
        100 /
        Number(invoiceQuantity) /
        35.315
    ).toFixed(2);
    // shippingBill
    const shippingBillgst = (Number(shippingBill) * 18) / 100;

    const shippingBillInvs = Number(shippingBill) + Number(shippingBillgst);

    const shippingBillval = Number(
      (shippingBillInvs * 6) / 100 / Number(invoiceQuantity) / 35.315
    ).toFixed(2);

    // PQ WEIGHT
    const pqWeightInvs = (Number(pqWeightcheck) - 1) * 200 + 3500;
    const pqWeightval = Number(
      (pqWeightInvs * 6) / 100 / Number(invoiceQuantity) / 35.315
    ).toFixed(2);

    // PQ FUMIGATION
    const pqFumigationInvs = pqFumigation * 0;
    const pqFumigationVal = Number(
      (pqFumigationInvs * 6) / 100 / Number(invoiceQuantity) / 35.315
    ).toFixed(2);

    //FUMIGATION CHARGES
    const fumigationChargeGst = (Number(fumigationCharge) * 18) / 100;
    const fumigationChargeInvs =
      Number(fumigationCharge) + Number(fumigationChargeGst);
    const fumigationChargeVal =
      fumigationChargeInvs == NaN
        ? 0
        : Number(
            (Number(fumigationChargeInvs) * 6) /
              100 /
              Number(invoiceQuantity) /
              35.315
          ).toFixed(2);

    // CFS CHARGES
    const cfscharge = cfsChargesNew * Number(containersNumber);
    setValue("cfsCharges", (cfscharge && cfscharge) || undefined);
    const cfsGst = (Number(cfsCharges) * 18) / 100;
    const cfsChargesInvs = Number(cfsCharges) + Number(cfsGst);

    const cfsChargesVal = Number(
      (Number(cfsChargesInvs) * 6) / 100 / Number(invoiceQuantity) / 35.315
    ).toFixed(2);
    // CHA SURVEY
    const chasurvey = chaSurveyNew * Number(containersNumber);
    setValue("chaSurvey", (chasurvey && chasurvey) || undefined);
    const chaGst = (Number(chaSurvey) * 18) / 100;
    const chaSurveyInvs = Number(chaSurvey) + Number(chaGst);
    const chaSurveyVal = Number(
      (Number(chaSurveyInvs) * 6) / 100 / Number(invoiceQuantity) / 35.315
    ).toFixed(2);
    //ExaminationCharges
    const examinationChargenews =
      examinationChargenew * Number(containersNumber);
    setValue(
      "examinationCharge",
      (examinationChargenews && examinationChargenews) || undefined
    );
    const cfsGroundRentNews = cfsGroundRentNew * Number(containersNumber);
    setValue(
      "cfsGroundRent",
      (cfsGroundRentNews && cfsGroundRentNews) || undefined
    );

    // FREIGHTCHARGES
    const freightcharge = freightChargeNew * Number(containersNumber);
    setValue("freightCharge", (freightcharge && freightcharge) || undefined);
    const freightChargeGst = (Number(freightCharge) * 5) / 100;

    const freightChargeInvs = Number(freightCharge) + Number(freightChargeGst);
    const freightChargeVal = Number(
      (Number(freightChargeInvs) * 6) / 100 / Number(invoiceQuantity) / 35.315
    ).toFixed(2);
    //UNLOADING
    const unloading = unloadingNew * Number(containersNumber);
    setValue("unLoading", (unloading && unloading) || undefined);
    const unLoadingGst = (Number(unLoading) * 18) / 100;

    const unLoadingInv = Number(unLoading) + Number(unLoadingGst);
    const unLoadingVal = Number(
      (Number(unLoadingInv) * 6) / 100 / Number(invoiceQuantity) / 35.315
    ).toFixed(2);

    // EXTRAEXPENSES
    const yardRentNews = yardRentNew * Number(containersNumber);
    // setValue("unLoading", (unloading && unloading) || undefined);

    setValue("extraExpenses", yardRentNews);
    //  sec49New
    const sec49News = sec49New * Number(containersNumber);
    // setValue("unLoading", (unloading && unloading) || undefined);

    setValue("sec49", sec49News);

    const extraExpensesGst = (Number(extraExpenses) * 18) / 100;
    // console.log("extraExpensesGst", extraExpensesGst);

    const extraExpensesInvs = Number(extraExpenses) + extraExpensesGst;
    // console.log("extraExpensesInvs", extraExpensesInvs);
    const extraExpensesVal = Number(
      (Number(extraExpensesInvs) * 6) / 100 / Number(invoiceQuantity) / 35.315
    ).toFixed(2);
    // console.log("extraExpensesVal", extraExpensesVal);
    // TOTAL INT
    // PQweight*4
    const pqweightss =
      (Number(pqWeight) * 6) / 100 / Number(invoiceQuantity) / 35.315;
    const pqfumigationss =
      (Number(pqFumigation) * 6) / 100 / Number(invoiceQuantity) / 35.315;
    const totalintval = [
      bgval,
      fronval,
      advisval,
      marginval,
      marginvalfive,
      customDutyval,
      socialWelfareChargeval,
      shippingBillval,
      pqWeightval,
      pqFumigationVal,
      fumigationChargeVal,
      cfsChargesVal,
      chaSurveyVal,
      freightChargeVal,
      unLoadingVal,
      extraExpensesVal,
      pqfumigationss,
      pqweightss,
    ]
      .reduce((sum, val) => sum + (Number(val) || 0), 0)
      .toFixed(2);
    // const totalintval =
    //   Number(bgval) +
    //   Number(fronval) +
    //   Number(advisval) +
    //   Number(marginval) +
    //   Number(marginvalfive) +
    //   Number(customDutyval) +
    //   Number(socialWelfareChargeval) +
    //   Number(shippingBillval) +
    //   Number(pqWeightval) +
    //   Number(pqFumigationVal) +
    //   Number(fumigationChargeVal) +
    //   Number(cfsChargesVal) +
    //   Number(chaSurveyVal) +
    //   Number(freightChargeVal) +
    //   Number(unLoadingVal) +
    //   Number(extraExpensesVal) +
    //   Number(pqfumigationss) +
    //   Number(pqweightss)
    //   ;

    let totalintvals = totalintval;

    if (totalintval !== NaN) {
      setValue("totalInt", (totalintvals && totalintvals) || undefined);
    }
    // SBLC INTEREST AND CHARGES
    const sblcInterestChargesVal = Number(
      (Number(sblcInterestCharges) * Number(sblcInterestUsdRate)) /
        Number(invoiceQuantity) /
        35.315
    ).toFixed(2);

    // BGCHARGES CFT
    const bgChargesCft = Number(
      Number(bgCharges) / Number(invoiceQuantity) / 35.315
    ).toFixed(2);

    // UPFRONTCHARGES CFT
    const upFrontChargesCft = Number(
      Number(upFrontCharges) / Number(invoiceQuantity) / 35.315
    ).toFixed(2);

    // ADVICECFT
    const adviceCft = Number(advice) / Number(invoiceQuantity) / 35.315;
    // CUSTOMDUTYCFT
    const customDutyCft = Number(
      Number(customDuty) / Number(invoiceQuantity) / 35.315
    ).toFixed(2);

    // SOCIALWELFARECHARGECFT
    const socialWelfareChargeCft = Number(
      Number(socialWelfareCharges) / Number(invoiceQuantity) / 35.315
    ).toFixed(2);
    // SHIPPINGBILLCFT
    const shippingBillCft = Number(
      Number(shippingBill) / Number(invoiceQuantity) / 35.315
    ).toFixed(2);

    // PQWEIGHTCFT
    const pqWeightCft = Number(
      Number(pqWeightInvs) / Number(invoiceQuantity) / 35.315
    ).toFixed(2);

    // PQFUMIGATION
    const pqFumigationCft = Number(
      Number(pqFumigationInvs) / Number(invoiceQuantity) / 35.315
    ).toFixed(2);
    // FUMIGATIONCHARGES
    const fumigationChargesCft =
      fumigationChargeInvs == NaN
        ? 0
        : Number(
            Number(fumigationChargeInvs) / Number(invoiceQuantity) / 35.315
          ).toFixed(2);

    // CFSCHARGESCFT
    const cfsChargesCft = Number(
      Number(cfsCharges) / Number(invoiceQuantity) / 35.315
    ).toFixed(2);
    // CHA SURVEY
    const chaSurveyCft = Number(
      Number(chaSurvey) / Number(invoiceQuantity) / 35.315
    ).toFixed(2);
    // FREIGHTCHARGECFT
    const freightChargeCft = Number(
      Number(freightCharge) / Number(invoiceQuantity) / 35.315
    ).toFixed(2);
    // UNLOADINGCFT
    const unLoadingCft = Number(
      Number(unLoading) / Number(invoiceQuantity) / 35.315
    ).toFixed(2);
    // EXTRAEXPENSES CFT
    const extraExpensesCft = Number(
      Number(extraExpenses) / Number(invoiceQuantity) / 35.315
    ).toFixed(2);
    // PQweight*4
    const pqweights = Number(pqWeight) / Number(invoiceQuantity) / 35.315;
    const pqfumigations = Number(
      Number(pqFumigation) / Number(invoiceQuantity) / 35.315
    ).toFixed(2);
    // console.log("fumigationChargesCft", fumigationChargesCft);
    const totalCftVal = [
      sblcInterestChargesVal,
      bgChargesCft,
      upFrontChargesCft,
      adviceCft,
      customDutyCft,
      socialWelfareChargeCft,
      shippingBillCft,
      pqWeightCft,
      pqFumigationCft,
      fumigationChargesCft,
      cfsChargesCft,
      chaSurveyCft,
      freightChargeCft,
      unLoadingCft,
      extraExpensesCft,
    ]
      .reduce((sum, val) => sum + (Number(val) || 0), 0)
      .toFixed(2);
    // const totalcftval =
    //   Number(sblcInterestChargesVal) +
    //   Number(bgChargesCft) +
    //   Number(upFrontChargesCft) +
    //   Number(adviceCft) +
    //   Number(customDutyCft) +
    //   Number(socialWelfareChargeCft) +
    //   Number(shippingBillCft) +
    //   Number(pqWeightCft) +
    //   Number(pqFumigationCft) +
    //   Number(fumigationChargesCft || 0) +
    //   Number(cfsChargesCft) +
    //   Number(chaSurveyCft) +
    //   Number(freightChargeCft) +
    //   Number(unLoadingCft) +
    //   Number(extraExpensesCft) +
    //   Number(pqfumigations) +
    //   Number(pqweights);
    let a = totalCftVal;
    // console.log("KKKLLLK", a);
    if (a !== NaN) {
      setValue("totalCft", a || undefined);
    }

    // OD
    setValue("od", (totalInt && totalInt) || undefined);
    // AGENTS
    setValue("agents", 30);
    // YARD RENT
    const yardRentVal =
      (Number(containersNumber) * 3000) / Number(invoiceQuantity) / 35.315;
    let yardRents = yardRentVal.toFixed(2);
    setValue("yardRent", (yardRents && yardRents) || undefined);
    let totalIndirectExpensess = Number(totalInt) + 30 + Number(yardRentVal);
    setValue("totalIndirectExpenses", totalIndirectExpensess.toFixed(2));

    // COST
    const costVal =
      Number(totalCft) + Number(od) + Number(agents) + Number(yardRent);
    setValue("cost", (costVal && costVal) || undefined);
  }, [
    cfsChargesNew,
    chaSurveyNew,
    unloadingNew,
    sec49New,
    sblcInterestCharges,
    yardRentNew,
    sblcInterestUsdRate,
    totalInt,
    extraExpenses,
    unLoading,
    freightCharge,
    chaSurvey,
    containersNumber,
    cfsCharges,
    fumigationCharge,
    pqFumigation,
    pqWeight,
    shippingBill,
    socialWelfareCharges,
    customDuty,
    bgCharges,
    margin5,
    margin10,
    invoiceQuantity,
    upFrontCharges,
    adviceGst,
    advice,
    totalCft,
    cost,
    od,
    yardRent,
    agents,
    examinationChargenew,
    cfsGroundRentNew,
    freightChargeNew,
  ]);

  // useEffect(() => {
  //   const calculateValue = (base, gstRate, factor) => {
  //     const gst = (Number(base) * gstRate) / 100;
  //     const total = Number(base) + gst;
  //     const final = (total * factor) / 100 / Number(invoiceQuantity) / 35.315;
  //     return final;
  //   };
  //   const charges = {
  //     bg: calculateValue(bgCharges, 18, 6),
  //     upFront: calculateValue(upFrontCharges, 18, 6),
  //     advice:
  //       ((Number(advice) + Number(adviceGst)) * 6) /
  //       100 /
  //       Number(invoiceQuantity) /
  //       35.315,
  //     margin10: (margin10 * 2.5) / 100 / Number(invoiceQuantity) / 35.315,
  //     margin5: (margin5 * 2.5) / 100 / Number(invoiceQuantity) / 35.315,
  //     customDuty: calculateValue(customDuty, 0, 6),
  //     socialWelfare: calculateValue(socialWelfareCharge, 0, 6),
  //     shippingBill: calculateValue(shippingBill, 18, 6),
  //     pqWeight: calculateValue((Number(pqWeightcheck) - 1) * 200 + 3500, 0, 6),
  //     fumigation: calculateValue(fumigationCharge, 18, 6),
  //     cfs: calculateValue(cfsChargesNew * Number(containersNumber), 18, 6),
  //     chaSurvey: calculateValue(chaSurveyNew * Number(containersNumber), 18, 6),
  //     freight: calculateValue(
  //       freightChargeNew * Number(containersNumber),
  //       5,
  //       6
  //     ),
  //     unLoading: calculateValue(unloadingNew * Number(containersNumber), 18, 6),
  //     extraExpenses: calculateValue(
  //       yardRentNew * Number(containersNumber),
  //       18,
  //       6
  //     ),
  //   };
  //   const totalInt = Object.values(charges)
  //     .reduce((sum, val) => sum + (Number(val) || 0), 0)
  //     .toFixed(2);
  //   if (!isNaN(totalInt)) {
  //     setValue("totalInt", totalInt);
  //   }
  //   setValue("extraExpenses", yardRentNew * Number(containersNumber));
  //   setValue(
  //     "cfsCharges",
  //     cfsChargesNew * Number(containersNumber) || undefined
  //   );
  //   setValue("chaSurvey", chaSurveyNew * Number(containersNumber) || undefined);
  //   setValue(
  //     "freightCharge",
  //     freightChargeNew * Number(containersNumber) || undefined
  //   );
  //   setValue("unLoading", unloadingNew * Number(containersNumber) || undefined);
  //   setValue("sec49", sec49New * Number(containersNumber));
  //   const calculateCft = (value) =>
  //     (Number(value) / Number(sblcInterestUsdRate) / 35.315).toFixed(2);
  //   const sblcInterestChargesVal = calculateCft(
  //     Number(sblcInterestCharges) * Number(sblcInterestUsdRate)
  //   );
  //   const pqWeightInvs = (Number(pqWeightcheck) - 1) * 200 + 3500;
  //   const pqFumigationInvs = pqFumigation * 0;
  //   const fumigationChargeGst = (Number(fumigationCharge) * 18) / 100;
  //   const fumigationChargeInvs =
  //     Number(fumigationCharge) + Number(fumigationChargeGst);
  //   const bgChargesCft = calculateCft(bgCharges);
  //   const upFrontChargesCft = calculateCft(upFrontCharges);
  //   const adviceCft = calculateCft(advice);
  //   const customDutyCft = calculateCft(customDuty);
  //   const socialWelfareChargeCft = calculateCft(socialWelfareCharge);
  //   const shippingBillCft = calculateCft(shippingBill);
  //   const pqWeightCft = Number(pqWeightInvs) / Number(invoiceQuantity) / 35.315;
  //   const pqFumigationCft =
  //     Number(pqFumigationInvs) / Number(invoiceQuantity) / 35.315;
  //   const fumigationChargesCft =
  //     Number(fumigationChargeInvs) / Number(invoiceQuantity) / 35.315;
  //   const cfsChargesCft = calculateCft(cfsCharges);
  //   const chaSurveyCft = calculateCft(chaSurvey);
  //   const freightChargeCft = calculateCft(freightCharge);
  //   const unLoadingCft = calculateCft(unLoading);
  //   const extraExpensesCft =
  //     Number(extraExpenses) / Number(invoiceQuantity) / 35.315;
  //   const pqWeights = calculateCft(pqWeight);
  //   const pqFumigations = calculateCft(pqFumigation);
  //   console.log("pqWeights", pqWeights, pqWeight)

  //   if (!isNaN(totalCftVal)) {
  //     setValue("totalCft", totalCftVal);
  //   }
  //   setValue("od", totalInt || undefined);
  //   setValue("agents", 30);
  //   const yardRentVal = calculateCft(Number(containersNumber) * 3000);
  //   setValue("yardRent", yardRentVal);
  //   const totalIndirectExpenses = (
  //     Number(totalInt) +
  //     30 +
  //     Number(yardRentVal)
  //   ).toFixed(2);
  //   setValue("totalIndirectExpenses", totalIndirectExpenses);
  //   const costVal = (
  //     Number(totalCftVal) +
  //     Number(totalInt) +
  //     30 +
  //     Number(yardRentVal)
  //   ).toFixed(2);
  //   if (!isNaN(costVal)) {
  //     setValue("cost", costVal);
  //   }
  // }, [
  //   cfsChargesNew,
  //   chaSurveyNew,
  //   unloadingNew,
  //   sec49New,
  //   yardRentNew,
  //   freightChargeNew,
  //   invoiceQuantity,
  //   bgCharges,
  //   upFrontCharges,
  //   advice,
  //   adviceGst,
  //   margin10,
  //   margin5,
  //   customDuty,
  //   socialWelfareCharge,
  //   shippingBill,
  //   pqWeightcheck,
  //   fumigationCharge,
  //   containersNumber,
  //   sblcInterestCharges,
  //   pqFumigation,
  //   cfsCharges,
  //   chaSurvey,
  //   freightCharge,
  //   unLoading,
  //   extraExpenses,
  //   pqWeight,
  // ]);
  // <<<<<END

  const [fileList, setFileList] = React.useState([]);
  const [drawerState, setDrawerState] = React.useState({});

  const pdfToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  //

  // DOcumentUpload API Start
  const useUserFileUploads = () => {
    return useMutation(
      (postData) => {
        return axios
          .post(
            `${process.env.REACT_APP_HOST}/copydocuments/create`,
            postData,
            {
              maxContentLength: Infinity,
              maxBodyLength: Infinity,
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            console.log("API response:", response.data);
            return response.data;
          })
          .catch((error) => {
            console.error("Error occurred:", error);
            throw error;
          });
      },
      {
        onSuccess: () => {
          console.log("Upload successful");
        },
        onError: (error) => {
          console.error("Upload failed:", error);
        },
      }
    );
  };

  // let { billno } = watch();
  const mutation = useUserFileUploads(); // Initialize the mutation here

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleFileChange = async (e, arrayName) => {
    if (!billno) {
      alert("Kindly Add a Bill No"); // Show an alert if billno is missing
      console.log("Error: Bill No is required");
      return; // Stop further execution
    }
    const files = Array.from(e.target.files);
    const promises = files?.map((file) => fileToBase64(file));

    try {
      const base64Strings = await Promise.all(promises);
      setBase64String(base64Strings);

      const value = {
        blNo: billno,
        copy: base64Strings.map((base64, index) => ({
          fileName: files[index].name,
          base64: base64,
        })),
      };

      console.log("Upload data:", value);
      mutation.mutate(JSON.stringify(value)); // Trigger the mutation
    } catch (error) {
      console.error("Error converting file to Base64:", error);
    }

    setFileList((prev) => ({
      ...prev,
      copydocs: [...(prev[arrayName] || []), ...files],
    }));
  };
  const HandlerDelete = async (i) => {
    try {
      // Ensure deleteIndex is used correctly
      const response = await axios.delete(
        `${process.env.REACT_APP_HOST}/copydocuments/${billno}/copy/${i}`
      );
      console.log("Delete response:", response.data);

      // Clear the deleteIndex
      return response.data;
    } catch (error) {
      console.log("Error", error);
    }
  };

  const removeFile = (arrayName, fileName, i) => {
    HandlerDelete(i);
    setFileList((prev) => ({
      ...prev,
      copydocs: prev[arrayName].filter((file) => file.name !== fileName),
    }));
    // setBase64String((prevArray) =>
    //   prevArray.filter((_, index) => {
    //     const fileListForArray = fileList[arrayName];
    //     if (!fileListForArray) return true;
    //     const fileToRemove = fileListForArray.find(
    //       (file) => file.name === fileName
    //     );
    //     return index !== fileListForArray.indexOf(fileToRemove);
    //   })
    // );
  };

  const toggleDrawer = (arrayName, open) => () => {
    setDrawerState((prev) => ({ ...prev, [arrayName]: open }));
  };

  const formData = new FormData();

  fileList &&
    fileList?.copydoc &&
    fileList?.copydoc.forEach((value) => {
      formData.append("copydocument[]", value);
    });

  const [files, setFiles] = useState(Array(10).fill(null));
  const [base64Files, setBase64Files] = useState(Array(10).fill(null));
  const handleFileChanges = (index, event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1];

        setFiles((prevFiles) => {
          const newFiles = [...prevFiles];
          newFiles[index] = file;
          return newFiles;
        });

        setBase64Files((prevBase64Files) => {
          const newBase64Files = [...prevBase64Files];
          newBase64Files[index] = base64String;
          return newBase64Files;
        });
      };

      reader.readAsDataURL(file);
    }

    setFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles[index] = file;
      return newFiles;
    });
  };

  const handleFileRemove = (index) => {
    setFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles[index] = null;
      return newFiles;
    });
    setBase64Files((prevBase64Files) => {
      const newBase64Files = [...prevBase64Files];
      newBase64Files[index] = null;
      return newBase64Files;
    });
  };
  console.log("base64Files", base64Files);
  const updateTotals = () => {
    const totalPcs = dataEntryForm?.reduce((total, item) => {
      const pcs = parseFloat(item?.pcs);
      return total + (!isNaN(pcs) ? pcs : 0);
    }, 0);
    const totalsellCbm = dataEntryForm?.reduce((total, item) => {
      const sellCbm = parseFloat(item?.sellCbm);
      return total + (!isNaN(sellCbm) ? sellCbm : 0);
    }, 0);
    dataEntryForm &&
      dataEntryForm?.forEach((item, index) => {
        let netCbm = item?.netCbm;
        let avgRate = item?.avgRate;
        if (netCbm && avgRate) {
          const amt = Number(netCbm) * Number(avgRate);
          setValue(`dataEntryForm.${index}.amt`, Number(amt) || 0); // Set calculated amt
        }
      });
    const totalAmt = dataEntryForm?.reduce((total, item) => {
      const amt = parseFloat(item?.amt);
      return total + (!isNaN(amt) ? amt : 0);
    }, 0);
    const totalgrossCbm = dataEntryForm?.reduce((total, item) => {
      const grossCbm = parseFloat(item?.grossCbm);
      return total + (!isNaN(grossCbm) ? grossCbm : 0);
    }, 0);
    const totalnetCbm = dataEntryForm?.reduce((total, item) => {
      const netCbm = parseFloat(item?.netCbm);
      return total + (!isNaN(netCbm) ? netCbm : 0);
    }, 0);
    const totalbrijesh = dataEntryForm?.reduce((total, item) => {
      const brijeshcomm = Number(item?.brijeshComm) * Number(item?.netCbm);
      return total + (!isNaN(brijeshcomm) ? brijeshcomm : 0);
    }, 0);

    setValue("brijesh", totalbrijesh.toFixed(2));

    setValue("totalNetCbm", totalnetCbm.toFixed(2));
    setValue("totalsellCbm", totalsellCbm.toFixed(2));
    setValue("totalGrossCbm", totalgrossCbm);
    setValue("totalPcs", totalPcs);
    setValue("totalAmount", totalAmt);
    let totalavgrateval = Number(totalAmt) / Number(totalnetCbm);
    setValue(
      "totalAvgRate",
      (totalavgrateval && totalavgrateval).toFixed(2) || undefined
    );
    let totalinvoice = Number(totalAmt) - Number(roundOff);
    setValue("totalInvoiceAmt", totalinvoice);
  };
  useEffect(() => {
    updateTotals();
  }, [dataEntryForm, roundOff]);

  const [drawerOpenIndex, setDrawerOpenIndex] = useState(null);

  // Open or close the drawer
  const toggleDrawers = (index) => () => {
    setDrawerOpenIndex((prev) => (prev === index ? null : index));
  };

  // containerImage
  // State to store Base64 strings and file names
  const [fileBase64, setFileBase64] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [fileNamesImg, setFileNamesImg] = useState([]);
  const [containerfileNamesImg, setContainerFileNamesImg] = useState([
    {
      fileName: [],
      base64Files: [],
    },
  ]);
  const [fileUploading, setFileUploading] = useState([
    {
      summaryDoc: {
        name: "",
        file: "",
      },
    },
  ]);
  const imageRefs = useRef([]);
  // Handle file input changes
  const handleImageChange = async (index, event) => {
    const files = Array.from(event.target.files);
    try {
      // Convert files to Base64 strings
      const base64Strings = await convertFilesToBase64(files);
      let stringa = base64Strings.forEach((e, i) => {
        e && e?.replace("data:image/jpeg;base64,", "");
      });
      setContainerFileNamesImg((prev) => {
        const updatedFileNames = [...prev];
        updatedFileNames[index] = {
          ...updatedFileNames[index],
          fileName: files.map((file) => file.name),
          base64Files: base64Strings,
        };

        return updatedFileNames; // Return the updated state
      });

      // updateLocalState(index, files, stringa);
      setValue(`dataEntryForm.${index}.containerImage`, files);
    } catch (error) {
      console.error("Error converting files to Base64:", error);
    }
  };
  const handlerChangebase64 = async (index, event) => {};
  // Convert files to Base64 strings
  const convertFilesToBase64 = (files) => {
    return Promise.all(
      files.map(
        (file) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onloadend = () => {
              try {
                // Check if the file starts with a data URI prefix
                if (
                  typeof reader.result === "string" &&
                  reader.result.startsWith("data:")
                ) {
                  // Extract the base64 portion after the first comma
                  const base64String = reader.result.split(",")[1];
                  resolve(base64String);
                } else {
                  reject("Invalid file format");
                }
              } catch (error) {
                reject("Error processing file: " + error.message);
              }
            };

            reader.onerror = () => reject("Error reading file");
            reader.readAsDataURL(file);
          })
      )
    );
  };

  // const convertFilesToBase64 = (files) => {
  //   return Promise.all(
  //     files.map(
  //       (file) =>
  //         new Promise((resolve, reject) => {
  //           const reader = new FileReader();
  //           reader.onloadend = () => resolve(reader.result);
  //           reader.onerror = () => reject("Error reading file");
  //           reader.readAsDataURL(file);
  //         })
  //     )
  //   );
  // };

  // Update local state for Base64 strings and file names

  const updateLocalState = (index, files, base64Strings) => {
    setFileBase64((prevState) => {
      const updatedFileBase64 = [...prevState];
      updatedFileBase64[index] = base64Strings;
      return updatedFileBase64;
    });

    setFileNames((prevState) => {
      const updatedFileNames = [...prevState];
      updatedFileNames[index] = files.map((file) => file.name);
      return updatedFileNames;
    });
    setFileNamesImg((prevState) => {
      const updatedFileNames = [...prevState];
      updatedFileNames[index] = files.map((file) => file);
      return updatedFileNames;
    });
  };

  // Remove a specific file from the list
  const [isactive, setIsActive] = useState([]);
  const handleRemoveFile = (formIndex, fileIndex) => {
    setContainerFileNamesImg((prev) => {
      const updatedFileBase64 = [...prev];
      updatedFileBase64[formIndex] = {
        ...updatedFileBase64[formIndex],
        fileName: updatedFileBase64[formIndex].fileName.filter(
          (_, i) => i !== fileIndex
        ),
        base64Files: updatedFileBase64[formIndex].base64Files.filter(
          (_, i) => i !== fileIndex
        ),
      };
      return updatedFileBase64;
    });
  };
  useEffect(() => {
    if (isactive.length > 0) {
      handleRemoveFile(...isactive);
      setIsActive([]);
    }
  }, [isactive]);
  // Remove a field and its associated files
  const handleRemoveField = (index) => {
    remove(index);
    setFileBase64((prevState) => prevState.filter((_, i) => i !== index));
    setFileNames((prevState) => prevState.filter((_, i) => i !== index));
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const triggerExcelInput = () => {
    if (excelInputRef.current) {
      excelInputRef.current.click();
    }
  };
  // Handle file input change Excle UPLOAD
  const handleExcelFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const binaryStr = event.target.result;
        const workbook = XLSX.read(binaryStr, { type: "binary" });

        // Convert workbook to Base64 using XLSX built-in function
        const base64String = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "base64",
        });

        // Now, set the file uploading state after base64String is generated
        setFileUploading((prevState) => [
          {
            summaryDoc: {
              file: base64String,
              name: file.name, // Use file.name instead of the file object
            },
          },
        ]);
      };
      reader.readAsBinaryString(file);
    }
  };
  // Remove the selected file
  const removeExcelFile = () => {
    setFileUploading((prevState) => [
      {
        summaryDoc: {
          file: "",
          name: "", // Use file.name instead of the file object
        },
      },
    ]);
  };
  const onSubmit = (data) => {
    let values = {
      pqRelaxcheck: data && data?.pqRelaxcheck,
      pqWeightcheck: data && data?.pqWeightcheck,
      blNo: data && data?.billno ? data && data?.billno : "",
      exporter: data && data?.exporter ? data && data?.exporter : "",
      address: data && data?.address ? data && data?.address : "",
      invoiceNo: data && data?.invoiceno ? data && data?.invoiceno : "",
      invoiceDt: data && data?.invoicedt ? data && data?.invoicedt : "",
      invoiceQuantity:
        data && data?.invoiceQuantity
          ? Number(data && data?.invoiceQuantity)
          : 0,
      invoiceRate:
        data && data?.invoiceRate ? Number(data && data?.invoiceRate) : 0,
      sblcInterestAndCharges:
        data && data?.sblcInterestCharges
          ? Number(data && data?.sblcInterestCharges)
          : 0,
      sblcInterestUsdRate:
        data && data?.sblcInterestUsdRate
          ? Number(data && data?.sblcInterestUsdRate)
          : 0,
      bgAmtAndInterestAmt:
        data && data?.bgAmtInterestAmt
          ? Number(data && data?.bgAmtInterestAmt)
          : 0,
      bgNumber: data && data?.bgNumber ? data && data?.bgNumber : "",
      bgdate: data && data?.bgDate ? data && data?.bgDate : "",
      foreignBankName:
        data && data?.foreignBankName ? data && data?.foreignBankName : "",
      bgCharges: data && data?.bgCharges ? Number(data && data?.bgCharges) : 0,
      upfrontCharges:
        data && data?.upFrontCharges ? Number(data && data?.upFrontCharges) : 0,
      advice: data && data?.advice ? data && data?.advice : "",
      margin10: data && data?.margin10 ? Number(data && data?.margin10) : 0,
      margin5: data && data?.margin5 ? Number(data && data?.margin5) : 0,
      adviceGst: data && data?.adviceGst,
      m1AccountNumber: data && data?.M1AccountNumber,
      m2AccountNumber: data && data?.M2AccountNumber,
      beNo: data && data?.beNo,
      beDate: data && data?.beDate ? data && data?.beDate : "",
      beUsed: data && data?.beUsd ? Number(data && data?.beUsd) : 0,
      customsDuty:
        data && data?.customDuty ? Number(data && data?.customDuty) : 0,
      socialWelfareCharges:
        data && data?.socialWelfareCharges
          ? Number(data && data?.socialWelfareCharges)
          : 0,
      beIgst: data && data?.beIgst ? Number(data && data?.beIgst) : 0,
      assessValue:
        data && data?.assessValue ? Number(data && data?.assessValue) : 0,
      shippingBill:
        data && data?.shippingBill ? Number(data && data?.shippingBill) : 0,
      pqWeight: data && data?.pqWeight,
      pqFumigation:
        data && data?.pqFumigation ? Number(data && data?.pqFumigation) : 0,
      fumigationCharges: data && data?.fumigationCharge,
      containersNumber: data && data?.containersNumber,
      chaSurveyNew: data && data?.chaSurveyNew,
      cfsChargesNew: data && data?.cfsChargesNew,
      dueDate: data && data?.dueDate,
      bankDeductionDate:
        data && data?.bankDeductionDate ? data && data?.bankDeductionDate : "",
      commodify: data && data?.commodify,
      exchangeRate:
        data && data?.exchangeRate ? Number(data && data?.exchangeRate) : 0,
      dateOfShipping:
        data && data?.dateOfShipping ? data && data?.dateOfShipping : "",
      forwardContractDate: data && data?.forwardContractDate,
      etaDate: data && data?.etaDate,
      fileName: data && data?.fileName,
      contractAmount: data && data?.forwardcontractamount,
      cfsCharges:
        data && data?.cfsCharges ? Number(data && data?.cfsCharges) : 0,
      chaSurvey: data && data?.chaSurvey ? Number(data && data?.chaSurvey) : 0,
      cfsGroundRent: data && data?.cfsGroundRent,
      examinationChargenew: data && data?.examinationChargenew,
      cfsGroundRentNew: data && data?.cfsGroundRentNew,
      freightChargeNew: data && data?.freightChargeNew,
      unloadingNew: data && data?.unloadingNew,
      sec49New: data && data?.sec49New,
      yardRentNew: data && data?.yardRentNew,
      examinationCharge: data && data?.examinationCharge,
      freightCharge:
        data && data?.freightCharge ? Number(data && data?.freightCharge) : 0,
      unloading: data && data?.unLoading,
      extraExpenses:
        data && data?.extraExpenses ? data && data?.extraExpenses : "",
      sec49: data && data?.sec49 ? data && data?.sec49 : "",
      totalCft: data && data?.totalCft !== "NaN" ? data && data?.totalCft : "",
      totalInt: data && data?.totalInt !== "NaN" ? data && data?.totalInt : "",
      od: data && data?.od !== "NaN" ? data && data?.od : "",
      agents: data && data?.agents,
      yardRent:
        data &&
        data?.yardRent !== "NaN" &&
        data &&
        data?.yardRent !== "Infinity"
          ? data && data?.yardRent
          : "",
      cfsName: data && data?.cfsName,
      totalIndirectExpenses:
        data && data?.totalIndirectExpenses !== "NaN"
          ? data && data?.totalIndirectExpenses
          : "",
      dutyPaymentDate: data && data?.dutyPaymentDate,
      cost: data && data?.cost,
      userForm:
        data &&
        data?.dataEntryForm.map((entry, index) => ({
          containerNo: entry.containerNo,
          averageLength: entry.averageLength,
          avgCftAvgGirth: entry.avgCftAvgGirth,
          pcs: entry.pcs,
          grossCbm: entry.grossCbm,
          netCbm: entry.netCbm,
          avgRate: entry.avgRate,
          amt: entry.amt,
          lorry: entry.lorry,
          sellRate: entry.sellRate,
          sellCbm: entry.sellCbm,
          brijeshComm: entry.brijeshComm,
          agent: entry.agent,
          pkl: entry.pkl,
          status: entry?.status,
          containerImage: containerfileNamesImg[index]?.base64Files
            ? containerfileNamesImg[index]?.base64Files
            : [],
        })),
      totalPcs: data && data?.totalPcs,
      totalGrossCbm: data && data?.totalGrossCbm,
      totalNetCbm: data && data?.totalNetCbm,
      totalAvgRate:
        data && data?.totalAvgRate !== null
          ? Number(data && data?.totalAvgRate)
          : 0,
      totalAmount:
        data && data?.totalAmount ? Number(data && data?.totalAmount) : 0,
      roundOff: data && data?.roundOff ? Number(data && data?.roundOff) : 0,
      totalInvoiceAmount:
        data && data?.totalInvoiceAmt
          ? Number(data && data?.totalInvoiceAmt)
          : 0,
      bruesh: data && data?.brijesh ? Number(data && data?.brijesh) : 0,
      // copydocument: base64String,
      sblcDocument: base64Files[0],
      summarydocument: fileUploading[0]?.summaryDoc?.file,
      invoicedocument: base64Files[1],
      phytodocument: base64Files[2],
      codocument: base64Files[3],
      bldocument: base64Files[4],
      quotedocument: base64Files[5],
      swiftintdocument: base64Files[6],
      advicedocument: base64Files[7],
      boedocument: base64Files[8],
      shippingbilldocument: base64Files[9],
    };
    if (values?.fileName !== undefined && state && state?.id) {
      UpdateAPI.mutate(values);
    } else if (values?.fileName !== undefined) {
      mutate(values);
    } else {
      alert("File a Data...");
    }

    // sessionStorage.setItem("imageNames", fileNames);
  };

  useEffect(() => {
    if (state) {
      remove();
      setValue("pqRelaxcheck", state?.pqRelaxcheck);
      setValue("pqWeightcheck", state?.pqWeightcheck);
      setValue("billno", state?.blNo);
      setValue("exporter", state?.exporter);
      setValue("cfsChargesNew", state?.cfsChargesNew);
      setValue("chaSurveyNew", state?.chaSurveyNew);
      setValue("address", state?.address);
      setValue("invoiceno", state?.invoiceNo);
      setValue("invoicedt", state?.invoiceDt);
      setValue("invoiceQuantity", state?.invoiceQuantity);
      setValue("invoiceRate", state?.invoiceRate);
      setValue("sblcInterestCharges", state?.sblcInterestAndCharges);
      setValue("sblcInterestUsdRate", state?.sblcInterestUsdRate);
      setValue("bgAmtInterestAmt", state?.bgAmtAndInterestAmt);
      setValue("bgNumber", state?.bgNumber);
      setValue("bgDate", state?.bgdate);
      setValue("foreignBankName", state?.foreignBankName);
      setValue("bgCharges", state?.bgCharges);
      setValue("upFrontCharges", state?.upfrontCharges);
      setValue("advice", state?.advice);
      setValue("margin10", state?.margin10);
      setValue("adviceGst", state?.adviceGst);
      setValue("margin5", state?.margin5);
      setValue("M1AccountNumber", state?.m1AccountNumber);
      setValue("M2AccountNumber", state?.m2AccountNumber);
      setValue("beNo", state?.beNo);
      setValue("beDate", state?.beDate);
      setValue("beUsd", state?.beUsed);
      setValue("customDuty", state?.customsDuty);
      setValue("socialWelfareCharges", state?.socialWelfareCharges);
      setValue("beIgst", state?.beIgst);
      setValue("assessValue", state?.assessValue);
      setValue("shippingBill", state?.shippingBill);
      setValue("pqWeight", state?.pqWeight);
      setValue("pqFumigation", state?.pqFumigation);
      setValue("fumigationCharge", state?.fumigationCharges);
      setValue("containersNumber", state?.containersNumber);
      setValue("dueDate", state?.dueDate);
      setValue("bankDeductionDate", state?.bankDeductionDate);
      setValue("commodify", state?.commodify);
      setValue("exchangeRate", state?.exchangeRate);
      setValue("dateOfShipping", state?.dateOfShipping);
      setValue("forwardContractDate", state?.forwardContractDate);
      setValue("etaDate", state?.etaDate);
      setValue("fileName", state?.fileName);
      setValue("forwardcontractamount", state?.contractAmount);
      setValue("cfsCharges", state?.cfsCharges);
      setValue("chaSurvey", state?.chaSurvey);
      setValue("freightCharge", state?.freightCharge);
      setValue("unLoading", state?.unloading);
      setValue("extraExpenses", state?.extraExpenses);
      setValue("totalCft", state?.totalCft);
      setValue("totalInt", state?.totalInt);
      setValue("od", state?.od);
      setValue("sec49", state?.sec49);
      setValue("cfsGroundRent", state?.cfsGroundRent);
      setValue("examinationCharge", state?.examinationCharge);
      setValue("agents", state?.agents);
      setValue("yardRent", state?.yardRent);
      setValue("cfsName", state?.cfsName);
      setValue("totalIndirectExpenses", state?.totalIndirectExpenses);
      setValue("dutyPaymentDate", state?.dutyPaymentDate);
      setValue("cost", state?.cost);
      setValue("examinationChargenew", state?.examinationChargenew);
      setValue("cfsGroundRentNew", state?.cfsGroundRentNew);
      setValue("freightChargeNew", state?.freightChargeNew);
      setValue("unloadingNew", state?.unloadingNew);
      setValue("sec49New", state?.sec49New);
      setValue("yardRentNew", state?.yardRentNew);

      if (state?.userForm) {
        state?.userForm?.map((formItem, index) => {
          append();
          setValue(`dataEntryForm.${index}.containerNo`, formItem?.containerNo);
          setValue(
            `dataEntryForm.${index}.averageLength`,
            formItem?.averageLength
          );
          setValue(
            `dataEntryForm.${index}.avgCftAvgGirth`,
            formItem?.avgCftAvgGirth
          );
          setValue(`dataEntryForm.${index}.pcs`, formItem?.pcs);
          setValue(`dataEntryForm.${index}.grossCbm`, formItem?.grossCbm);
          setValue(`dataEntryForm.${index}.netCbm`, formItem?.netCbm);
          setValue(`dataEntryForm.${index}.avgRate`, formItem?.avgRate);
          setValue(`dataEntryForm.${index}.amt`, formItem?.amt);
          setValue(`dataEntryForm.${index}.lorry`, formItem?.lorry);
          setValue(`dataEntryForm.${index}.sellRate`, formItem?.sellRate);
          setValue(`dataEntryForm.${index}.sellCbm`, formItem?.sellCbm);
          setValue(`dataEntryForm.${index}.brijeshComm`, formItem?.brijeshComm);
          setValue(`dataEntryForm.${index}.agent`, formItem?.agent);
          setValue(`dataEntryForm.${index}.pkl`, formItem?.pkl);
          setValue(`dataEntryForm.${index}.status`, formItem?.status);
          setContainerFileNamesImg((prev) => {
            const updatedFileNames = [
              {
                fileName: [],
                base64Files: [],
              },
            ];
            state?.userForm.forEach((item, index) => {
              if (!item?.containerImage) {
                console.warn(`Missing containerImage at index ${index}`);
                return;
              }

              item.containerImage.forEach((base64File, idx) => {
                if (base64File === "") {
                  console.log(
                    `Base64 file at index ${idx} in item ${index} is empty.`
                  );
                } else {
                  if (!updatedFileNames[index]) {
                    updatedFileNames[index] = { fileName: [], base64Files: [] };
                  }
                  updatedFileNames[index].fileName.push(`file${idx + 1}`);
                  updatedFileNames[index].base64Files.push(base64File);
                }
              });
            });

            return updatedFileNames; // Return the updated state.
          });
        });
        const newFieldsCount = state?.userForm.length;
        const currentFieldsCount = fields?.length;
      }
      {
        copydocs &&
          setFileList((prev) => ({
            ...prev,
            // copydocs: [...(prev?.copydocs || []), ...copydocs && copydocs],
            copydocs: [
              ...(prev?.copydocs || []),
              ...Object.values(copydocs && copydocs),
            ],
          }));
      }

      // setFileList
      setFileUploading((prevState) => [
        {
          summaryDoc: {
            file: state?.summarydocument !== null ? state?.summarydocument : "",
            name: state?.summarydocument !== null ? "SummaryDoc" : "",
          },
        },
      ]);
      setValue("summaryDoc", state?.summaryDocument);
      setValue("totalPcs", state?.totalPcs);
      setValue("totalGrossCbm", state?.totalGrossCbm);
      setValue("totalNetCbm", state?.totalNetCbm);
      setValue("totalAvgRate", state?.totalAvgRate);
      setValue("totalAmount", state?.totalAmount);
      setValue("roundOff", state?.roundOff);
      setValue("totalInvoiceAmt", state?.totalInvoiceAmount);
      setValue("brijesh", state?.bruesh);

      state?.summarydocument !== null ? (
        setSelectedFile("summarydocument")
      ) : (
        <></>
      );

      // setValue("summaryDoc", selectedFile?.name);
    }
  }, [setValue, state, append, remove]);
  const filesvalue = [
    {
      name: "SBLC DOCUMENT",
      state: state?.sblcDocument ? state?.sblcDocument : null,
    },
    {
      name: "INVOICE DOCUMENT",
      state: state?.invoicedocument ? state?.invoicedocument : null,
    },
    {
      name: "PHYTO DOCUMENT",
      state: state?.phytodocument ? state?.phytodocument : null,
    },
    {
      name: "CO DOCUMENT",
      state: state?.codocument ? state?.codocument : null,
    },
    {
      name: "BL DOCUMENT",
      state: state?.bldocument ? state?.bldocument : null,
    },
    {
      name: "QUOTE DOCUMENT",
      state: state?.quotedocument ? state?.quotedocument : null,
    },
    {
      name: "SWIFT INT DOCUMENT",
      state: state?.swiftintdocument ? state?.swiftintdocument : null,
    },
    {
      name: "ADVICE DOCUMENT",
      state: state?.advicedocument ? state?.advicedocument : null,
    },
    {
      name: "BOE DOCUMENT",
      state: state?.boedocument ? state?.boedocument : null,
    },
    {
      name: "SHIPPING BILL DOCUMENT",
      state: state?.shippingbilldocument ? state?.shippingbilldocument : null,
    },
  ];
  // NEw Update Start
  const [isEditable, setIsEditable] = useState({
    fileName: false,
    etaDate: false,
    billno: false,
    dateOfShipping: false,
    pqWeightcheck: false,
    pqWeight: false,
    pqfumigation: false,
    containersNumber: false,
    commodify: false,
    exporter: false,
    address: false,
    invoicedt: false,
    invoiceQuantity: false,
    invoiceRate: false,
    sblcInterestUsdRate: false,
    bgAmtInterestAmt: false,
    upFrontCharges: false,
    foreignBankName: false,
    M1AccountNumber: false,
    M2AccountNumber: false,
    margin10: false,
    margin5: false,
    bgNumber: false,
    bgDate: false,
    bgCharges: false,
    sblcInterestCharges: false,
    advice: false,
    adviceGst: false,
    dueDate: false,
    bankDeductionDate: false,
    forwardContractDate: false,
    exchangeRate: false,
    forwardcontractamount: false,
    beNo: false,
    beDate: false,
    beUsd: false,
    dutyPaymentDate: false,
    socialwelfarecharge: false,
    beIgst: false,
    assessValue: false,
    shippingBill: false,
    cfsName: false,
    chaSurvey: false,
    cfsCharges: false,
    examinationCharge: false,
    cfsGroundRent: false,
    freightCharge: false,
    unLoading: false,
    extraExpenses: false,
    sec49: false,
    fumigationCharge: false,
    dataEntryForm: {},
  });

  // const handleEditConfirmation = (fieldName) => {
  //   const existingValue = watch(fieldName);
  //   if (existingValue) {
  //     const userConfirmed = window.confirm("Are you sure you want to edit?");
  //     if (userConfirmed) {
  //       setIsEditable((prev) => ({ ...prev, [fieldName]: true }));
  //     }
  //   }
  // };

  // const handleInputFocus = (fieldName) => {
  //   handleEditConfirmation(fieldName);
  // };

  const handleEditConfirmation = (fieldName, index = null) => {
    const existingValue = watch(fieldName);

    if (existingValue) {
      const userConfirmed = window.confirm("Are you sure you want to edit?");
      if (userConfirmed) {
        setIsEditable((prev) => {
          if (index !== null) {
            // For dynamic fields
            return {
              ...prev,
              dataEntryForm: {
                ...prev?.dataEntryForm,
                [index]: true,
              },
            };
          }
          // For normal fields
          return { ...prev, [fieldName]: true };
        });
      }
    }
  };

  const handleInputFocus = (fieldName, index = null) => {
    handleEditConfirmation(fieldName, index);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [isIndexing, setIsIndexing] = useState();

  const toggleDrawergf =
    (open, index = null) =>
    (event) => {
      if (
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }
      setIsOpen(open);
      setIsIndexing(index);
    };
  // const [totals, setTotals] = useState({
  //   pcs: 0,
  //   grossCbm: 0,
  //   netCbm: 0,
  //   avgRate: 0,
  //   amt: 0,
  //   sellRate: 0,
  //   sellCbm: 0,
  //   brijeshComm: 0,
  //   pkl: 0,
  // });

  // // Watch all the necessary fields in the form
  // const watchedFields = watch(
  //   fields
  //     .map((_, index) => [
  //       `dataEntryForm.${index}.pcs`,
  //       `dataEntryForm.${index}.grossCbm`,
  //       `dataEntryForm.${index}.netCbm`,
  //       `dataEntryForm.${index}.avgRate`,
  //       `dataEntryForm.${index}.amt`,
  //       `dataEntryForm.${index}.sellRate`,
  //       `dataEntryForm.${index}.sellCbm`,
  //       `dataEntryForm.${index}.brijeshComm`,
  //       `dataEntryForm.${index}.pkl`,
  //     ])
  //     .flat()
  // );

  // // Recalculate totals when the watched fields change
  // useEffect(() => {
  //   const newTotals = {
  //     pcs: watchedFields.reduce(
  //       (sum, value, index) => sum + (parseFloat(value) || 0),
  //       0
  //     ),
  //     grossCbm: watchedFields.reduce(
  //       (sum, value, index) => sum + (parseFloat(value) || 0),
  //       0
  //     ),
  //     netCbm: watchedFields.reduce(
  //       (sum, value, index) => sum + (parseFloat(value) || 0),
  //       0
  //     ),
  //     avgRate: watchedFields.reduce(
  //       (sum, value, index) => sum + (parseFloat(value) || 0),
  //       0
  //     ),
  //     amt: watchedFields.reduce(
  //       (sum, value, index) => sum + (parseFloat(value) || 0),
  //       0
  //     ),
  //     sellRate: watchedFields.reduce(
  //       (sum, value, index) => sum + (parseFloat(value) || 0),
  //       0
  //     ),
  //     sellCbm: watchedFields.reduce(
  //       (sum, value, index) => sum + (parseFloat(value) || 0),
  //       0
  //     ),
  //     brijeshComm: watchedFields.reduce(
  //       (sum, value, index) => sum + (parseFloat(value) || 0),
  //       0
  //     ),
  //     pkl: watchedFields.reduce(
  //       (sum, value, index) => sum + (parseFloat(value) || 0),
  //       0
  //     ),
  //   };
  //   setTotals(newTotals);
  // }, [watchedFields]);

  const downloadExcle = () => {
    let formData = {
      pqRelaxcheck,
      pqWeightcheck,
      billno,
      exporter,
      address,
      invoiceno,
      invoicedt,
      invoiceQuantity,
      invoiceRate,
      sblcInterestCharges,
      sblcInterestUsdRate,
      bgAmtInterestAmt,
      bgNumber,
      bgDate,
      foreignBankName,
      bgCharges,
      upFrontCharges,
      advice,
      margin10,
      margin5,
      adviceGst,
      M1AccountNumber,
      M2AccountNumber,
      beNo,
      beDate,
      beUsd,
      customDuty,
      socialWelfareCharges,
      beIgst,
      assessValue,
      shippingBill,
      pqFumigation,
      pqWeight,
      fumigationCharge,
      containersNumber,
      chaSurveyNew,
      cfsChargesNew,
      dueDate,
      bankDeductionDate,
      commodify,
      exchangeRate,
      dateOfShipping,
      forwardContractDate,
      etaDate,
      fileName,
      forwardcontractamount,
      cfsCharges,
      chaSurvey,
      cfsGroundRent,
      examinationChargenew,
      cfsGroundRentNew,
      freightChargeNew,
      unloadingNew,
      sec49New,
      yardRentNew,
      examinationCharge,
      freightCharge,
      unLoading,
      extraExpenses,
      sec49,
      totalCft,
      totalInt,
      od,
      agents,
      yardRent,
      cfsName,
      totalIndirectExpenses,
      dutyPaymentDate,
      cost,
      totalPcs,
      totalGrossCbm,
      totalNetCbm,
      totalAvgRate,
      totalAmount,
      roundOff,
      totalInvoiceAmt,
      brijesh,
      dataEntryForm: dataEntryForm.map((ele) => ({
        containerNo: ele?.containerNo,
        averageLength: ele?.averageLength,
        avgCftAvgGirth: ele?.avgCftAvgGirth,
        pcs: ele?.pcs,
        grossCbm: ele?.grossCbm,
        netCbm: ele?.netCbm,
        avgRate: ele?.avgRate,
        amt: ele?.amt,
        lorry: ele?.lorry,
        sellRate: ele?.sellRate,
        sellCbm: ele?.sellCbm,
        brijeshComm: ele?.brijeshComm,
        agent: ele?.agent,
        pkl: ele?.pkl,
        status: ele?.status !== "Inactive" ? "Active" : ele?.status,
      })),
    };
    const flattenedData = [];

    // Add top-level fields
    const topLevelData = { ...formData };
    delete topLevelData.dataEntryForm; // Exclude array for separate processing
    flattenedData.push(topLevelData);

    // Add `dataEntryForm` fields, one per row
    formData.dataEntryForm.forEach((item, index) => {
      flattenedData.push({ ...item });
    });

    // Create a worksheet
    const worksheet = XLSX.utils.json_to_sheet(flattenedData);

    // Create a workbook
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Form Data");

    // Download the Excel file
    XLSX.writeFile(workbook, "form_data.xlsx");
  };

  // NEw Update End

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className=" px-3 py-4">
        {/* <div style={{ display: "flex", justifyContent: "end" }}>
          <div onClick={() => downloadExcle()} style={{ background: "#f2b327", display: "flex", width: "116px", borderRadius: "15px", padding: "9px" }}>
            <div style={{ width: "100%", height: "100%" }}>
              <img src={downloadfile} style={{ width: "100%", height: "100%" }} />
            </div>
            <p style={{ margin: 0, color: "#fff" }}>Download</p>
          </div>
        </div> */}
        <div className="py-3">
          <h6 className="data-entry-sub-title">BL DETAILS</h6>
        </div>
        {/* BL DETAILS START */}

        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.fileName}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="fileName"
                render={({ field }) => {
                  const hasValue = watch("fileName");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="text"
                      placeholder="F1"
                      readOnly={hasValue && !isEditable.fileName}
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      onClick={() => handleInputFocus("fileName")}
                    />
                  );
                }}
              />
            </div>
          </div>

          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.billnumber}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="billno"
                render={({ field }) => {
                  const hasValue = watch("billno");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="text"
                      placeholder="3421631"
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      readOnly={hasValue && !isEditable.billno}
                      disabled={state && state?.blNo}
                      // disable=
                      onClick={() => handleInputFocus("billno")}
                    />
                  );
                }}
              />
            </div>
          </div>

          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.dateofshipping}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="dateOfShipping"
                render={({ field }) => {
                  const hasValue = watch("dateOfShipping");
                  return (
                    <DatePicker
                      {...field}
                      placeholderText="DD/MM/YYYY"
                      onChange={(date) => {
                        handleInputFocus("dateOfShipping");
                        setIsEditable((prev) => ({
                          ...prev,
                          dateOfShipping: false,
                        }));
                        field.onChange(date);
                      }}
                      selected={field.value}
                      dateFormat="dd/MM/yyyy"
                      className={`data-entry-date-field ${
                        hasValue && hasValue ? "--BG_VVV" : ""
                      }`}
                      readOnly={hasValue && isEditable.dateOfShipping}
                    />
                  );
                }}
              />
            </div>
          </div>

          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.etadate}</label>
            <div className="data-entry-input">
              <Controller
                name="etaDate"
                control={control}
                defaultValue={null}
                render={({ field }) => {
                  const hasValue = watch("etaDate");
                  return (
                    <DatePicker
                      placeholderText="DD/MM/YYYY"
                      onChange={(date) => {
                        handleInputFocus("etaDate");
                        setIsEditable((prev) => ({ ...prev, etaDate: false }));
                        field.onChange(date);
                      }}
                      className={`data-entry-date-field ${
                        hasValue && hasValue ? "--BG_VVV" : ""
                      }`}
                      readOnly={hasValue && isEditable.dateOfShipping}
                      selected={field.value}
                      dateFormat="dd/MM/yyyy"
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label"> {Strings.pqweight}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="pqWeightcheck"
                render={({ field }) => {
                  const hasValue = watch("pqWeightcheck");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="text"
                      placeholder="pqWeightcheck"
                      readOnly={hasValue && !isEditable.pqWeightcheck}
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      // onFocus={() => setIsEditable((prev) => ({ ...prev, fileName: true }))}
                      onClick={() => handleInputFocus("pqWeightcheck")}
                    />
                  );
                }}
              />
            </div>
          </div>

          <div className="data-entry-input-block">
            <label className="data-entry-label">PQ WEIGHT 4*:</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="pqWeight"
                render={({ field }) => {
                  const hasValue = watch("pqWeight");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="number"
                      placeholder="3421631"
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      readOnly={hasValue && !isEditable.pqWeight}
                      // disable={state?.blNo ? true : false}
                      // onFocus={() => setIsEditable((prev) => ({ ...prev, exporter: true }))}
                      onClick={() => handleInputFocus("pqWeight")}
                    />
                  );
                }}
              />
            </div>
          </div>

          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.pqfumigation}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="pqFumigation"
                render={({ field }) => {
                  const hasValue = watch("pqfumigation");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="number"
                      placeholder="3421631"
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      readOnly={hasValue && !isEditable.pqfumigation}
                      // disable={state?.blNo ? true : false}
                      // onFocus={() => setIsEditable((prev) => ({ ...prev, exporter: true }))}
                      onClick={() => handleInputFocus("pqfumigation")}
                    />
                  );
                }}
              />
            </div>
          </div>

          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.containersno}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="containersNumber"
                render={({ field }) => {
                  const hasValue = watch("containersNumber");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="number"
                      placeholder="3421631"
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      readOnly={hasValue && !isEditable.containersNumber}
                      // disable={state?.blNo ? true : false}
                      // onFocus={() => setIsEditable((prev) => ({ ...prev, exporter: true }))}
                      onClick={() => handleInputFocus("containersNumber")}
                    />
                  );
                }}
              />
            </div>
            {/* <label className="data-entry-label">exporter</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="exporter"
                render={({ field }) => {
                  const hasValue = watch("exporter");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="text"
                      placeholder="exporter"
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                      }}
                      readOnly={hasValue && !isEditable.exporter}
                      // onFocus={() => setIsEditable((prev) => ({ ...prev, exporter: true }))}
                      onClick={() => handleInputFocus("exporter")}
                    />
                  )
                }}
              />
            </div> */}
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div
            className="data-entry-input-block"
            style={{ flexDirection: "column", alignItems: "baseline" }}
          >
            <label
              className="data-entry-label"
              //   style={{
              //   display: "flex",
              //   alignItems: "center",
              //   gap: "10px"
              // }}
            >
              {" "}
              {Strings.commodify}
            </label>
            <div className="data-entry-input" style={{ width: "46%" }}>
              <Controller
                control={control}
                name="commodify"
                render={({ field }) => {
                  const hasValue = watch("commodify");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="text"
                      placeholder="Commodify"
                      readOnly={hasValue && !isEditable.commodify}
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      // onFocus={() => setIsEditable((prev) => ({ ...prev, fileName: true }))}
                      onClick={() => handleInputFocus("commodify")}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>
        {/* BL DETAILS END */}

        {/* Invoice details Start */}
        <div className="py-3">
          <h6 className="data-entry-sub-title">INVOICE DETAILS</h6>
        </div>
        {/* INVOICE DETAILS START */}

        <div className="dataEntryInputContainer py-2">
          <div
            className="data-entry-input-block"
            style={{
              flexDirection: "column",
              alignItems: "baseline",
              width: "24%",
            }}
          >
            <label className="data-entry-label">{Strings.exporter}</label>
            <div className="data-entry-input" style={{ width: "100%" }}>
              <Controller
                control={control}
                name="exporter"
                render={({ field }) => {
                  const hasValue = watch("exporter");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="text"
                      placeholder="THIRU RANI LOGISTICS PVT LTD"
                      readOnly={hasValue && !isEditable.exporter}
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      onClick={() => handleInputFocus("exporter")}
                    />
                  );
                }}
              />
            </div>
          </div>

          <div className="data-entry-input-block" style={{ width: "74%" }}>
            <label className="data-entry-label">{Strings.address}</label>
            <div className="data-entry-input" style={{ width: "100%" }}>
              <Controller
                control={control}
                name="address"
                render={({ field }) => {
                  const hasValue = watch("address");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="text"
                      placeholder="3421631"
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      readOnly={hasValue && !isEditable.address}
                      // disable={state?.blNo ? true : false}
                      onClick={() => handleInputFocus("address")}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label"> {Strings.invoiceno}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="invoiceno"
                render={({ field }) => {
                  const hasValue = watch("invoiceno");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="text"
                      placeholder="invoiceno"
                      readOnly={hasValue && !isEditable.invoiceno}
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      onClick={() => handleInputFocus("invoiceno")}
                    />
                  );
                }}
              />
            </div>
          </div>

          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.invoicedt}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="invoicedt"
                render={({ field }) => {
                  const hasValue = watch("invoicedt");
                  return (
                    <DatePicker
                      placeholderText="DD/MM/YYYY"
                      onChange={(date) => {
                        handleInputFocus("invoicedt");
                        setIsEditable((prev) => ({
                          ...prev,
                          invoicedt: false,
                        }));
                        field.onChange(date);
                      }}
                      selected={field.value}
                      dateFormat="dd/MM/yyyy"
                      className={`data-entry-date-field ${
                        hasValue && hasValue ? "--BG_VVV" : ""
                      }`}
                      readOnly={hasValue && isEditable.invoicedt}
                    />
                  );
                }}
              />
            </div>
          </div>

          <div className="data-entry-input-block">
            <label className="data-entry-label">
              {Strings.invoicequantity}
            </label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="invoiceQuantity"
                render={({ field }) => {
                  const hasValue = watch("invoiceQuantity");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="number"
                      placeholder="3421631"
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      readOnly={hasValue && !isEditable.invoiceQuantity}
                      // disable={state?.blNo ? true : false}
                      onClick={() => handleInputFocus("invoiceQuantity")}
                    />
                  );
                }}
              />
            </div>
          </div>

          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.invoicerate}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="invoiceRate"
                render={({ field }) => {
                  const hasValue = watch("invoiceRate");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="number"
                      placeholder="3421631"
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      readOnly={hasValue && !isEditable.invoiceRate}
                      // disable={state?.blNo ? true : false}
                      onClick={() => handleInputFocus("invoiceRate")}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div
            className="data-entry-input-block"
            style={{
              flexDirection: "column",
              alignItems: "baseline",
              width: "24%",
            }}
          >
            <label className="data-entry-label">
              {" "}
              {Strings.sblcinterestusdrate}
            </label>
            <div className="data-entry-input" style={{ width: "100%" }}>
              <Controller
                control={control}
                name="sblcInterestUsdRate"
                render={({ field }) => {
                  const hasValue = watch("sblcInterestUsdRate");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="text"
                      placeholder="sblcInterestUsdRate"
                      readOnly={hasValue && !isEditable.sblcInterestUsdRate}
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      onClick={() => handleInputFocus("sblcInterestUsdRate")}
                    />
                  );
                }}
              />
            </div>
          </div>
          <div
            className="data-entry-input-block"
            style={{
              flexDirection: "column",
              alignItems: "baseline",
              width: "24%",
            }}
          >
            <label className="data-entry-label">
              {" "}
              {Strings.bgamt_interestamt}
            </label>
            <div className="data-entry-input" style={{ width: "100%" }}>
              <Controller
                control={control}
                name="bgAmtInterestAmt"
                render={({ field }) => {
                  const hasValue = watch("bgAmtInterestAmt");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="text"
                      placeholder="bgAmtInterestAmt"
                      readOnly={hasValue && !isEditable.bgAmtInterestAmt}
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      onClick={() => handleInputFocus("bgAmtInterestAmt")}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>
        {/* Invoice details END */}
        {/* Banking Start */}
        <div className="py-3">
          <h6 className="data-entry-sub-title">BANK DETAILS</h6>
        </div>
        {/* Banking START */}
        <div className="dataEntryInputContainer py-2">
          <div
            className="data-entry-input-block"
            style={{
              flexDirection: "column",
              alignItems: "baseline",
              width: "24%",
            }}
          >
            <label className="data-entry-label">
              {" "}
              {Strings.upfrontcharges}
            </label>
            <div className="data-entry-input" style={{ width: "100%" }}>
              <Controller
                control={control}
                name="upFrontCharges"
                render={({ field }) => {
                  const hasValue = watch("upFrontCharges");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="text"
                      placeholder="upFrontCharges"
                      readOnly={hasValue && !isEditable.upFrontCharges}
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      onClick={() => handleInputFocus("upFrontCharges")}
                    />
                  );
                }}
              />
            </div>
          </div>
          <div
            className="data-entry-input-block"
            style={{
              flexDirection: "column",
              alignItems: "baseline",
              width: "24%",
            }}
          >
            <label className="data-entry-label">
              {" "}
              {Strings.foreignbankname}
            </label>
            <div className="data-entry-input" style={{ width: "100%" }}>
              <Controller
                control={control}
                name="foreignBankName"
                render={({ field }) => {
                  const hasValue = watch("foreignBankName");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="text"
                      placeholder="foreignBankName"
                      readOnly={hasValue && !isEditable.foreignBankName}
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      onClick={() => handleInputFocus("foreignBankName")}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>

        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label">
              {" "}
              {Strings.m1accountnumber}
            </label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="M1AccountNumber"
                render={({ field }) => {
                  const hasValue = watch("M1AccountNumber");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="text"
                      placeholder="M1AccountNumber"
                      readOnly={hasValue && !isEditable.M1AccountNumber}
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      onClick={() => handleInputFocus("M1AccountNumber")}
                    />
                  );
                }}
              />
            </div>
          </div>

          <div className="data-entry-input-block">
            <label className="data-entry-label">
              {Strings.m2accountnumber}
            </label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="M2AccountNumber"
                render={({ field }) => {
                  const hasValue = watch("M2AccountNumber");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="text"
                      placeholder="M2AccountNumber"
                      readOnly={hasValue && !isEditable.M2AccountNumber}
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      onClick={() => handleInputFocus("M2AccountNumber")}
                    />
                  );
                }}
              />
            </div>
          </div>

          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.margin10}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="margin10"
                render={({ field }) => {
                  const hasValue = watch("margin10");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="number"
                      placeholder="3421631"
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      readOnly={hasValue && !isEditable.margin10}
                      // disable={state?.blNo ? true : false}
                      onClick={() => handleInputFocus("margin10")}
                    />
                  );
                }}
              />
            </div>
          </div>

          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.margin5}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="margin5"
                render={({ field }) => {
                  const hasValue = watch("margin5");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="number"
                      placeholder="3421631"
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      readOnly={hasValue && !isEditable.margin5}
                      // disable={state?.blNo ? true : false}
                      onClick={() => handleInputFocus("margin5")}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>

        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label"> BG NUMBER </label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="bgNumber"
                render={({ field }) => {
                  const hasValue = watch("bgNumber");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="text"
                      placeholder="bgNumber"
                      readOnly={hasValue && !isEditable.bgNumber}
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      onClick={() => handleInputFocus("bgNumber")}
                    />
                  );
                }}
              />
            </div>
          </div>

          <div className="data-entry-input-block">
            <label className="data-entry-label">BG DATE </label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="bgDate"
                render={({ field }) => {
                  const hasValue = watch("bgDate");
                  return (
                    <DatePicker
                      placeholderText="DD/MM/YYYY"
                      onChange={(date) => {
                        handleInputFocus("bgDate");
                        setIsEditable((prev) => ({ ...prev, bgDate: false }));
                        field.onChange(date);
                      }}
                      selected={field.value}
                      dateFormat="dd/MM/yyyy"
                      className={`data-entry-date-field ${
                        hasValue && hasValue ? "--BG_VVV" : ""
                      }`}
                      readOnly={hasValue && isEditable.bgDate}
                    />
                  );
                }}
              />
            </div>
          </div>

          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.bgcharges}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="bgCharges"
                render={({ field }) => {
                  const hasValue = watch("bgCharges");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="number"
                      placeholder="3421631"
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      readOnly={hasValue && !isEditable.bgCharges}
                      // disable={state?.blNo ? true : false}
                      onClick={() => handleInputFocus("bgCharges")}
                    />
                  );
                }}
              />
            </div>
          </div>

          <div className="data-entry-input-block">
            <label className="data-entry-label">
              {Strings.sblcinterest_charges}
            </label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="sblcInterestCharges"
                render={({ field }) => {
                  const hasValue = watch("sblcInterestCharges");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="number"
                      placeholder="3421631"
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      readOnly={hasValue && !isEditable.sblcInterestCharges}
                      // disable={state?.blNo ? true : false}
                      onClick={() => handleInputFocus("sblcInterestCharges")}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>

        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label"> {Strings.advice}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="advice"
                render={({ field }) => {
                  const hasValue = watch("advice");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="text"
                      placeholder="advice"
                      readOnly={hasValue && !isEditable.advice}
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      onClick={() => handleInputFocus("advice")}
                    />
                  );
                }}
              />
            </div>
          </div>

          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.advicegst}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="adviceGst"
                render={({ field }) => {
                  const hasValue = watch("adviceGst");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="text"
                      placeholder="adviceGst"
                      readOnly={hasValue && !isEditable.adviceGst}
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      onClick={() => handleInputFocus("adviceGst")}
                    />
                  );
                }}
              />
            </div>
          </div>

          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.duedate}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="dueDate"
                render={({ field }) => {
                  const hasValue = watch("dueDate");
                  return (
                    <DatePicker
                      placeholderText="DD/MM/YYYY"
                      onChange={(date) => {
                        handleInputFocus("dueDate");
                        setIsEditable((prev) => ({ ...prev, dueDate: false }));
                        field.onChange(date);
                      }}
                      selected={field.value}
                      dateFormat="dd/MM/yyyy"
                      className={`data-entry-date-field ${
                        hasValue && hasValue ? "--BG_VVV" : ""
                      }`}
                      readOnly={hasValue && isEditable.dueDate}
                    />
                  );
                }}
              />
            </div>
          </div>

          <div className="data-entry-input-block">
            <label className="data-entry-label">
              {Strings.bankdeductiondate}
            </label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="bankDeductionDate"
                render={({ field }) => {
                  const hasValue = watch("bankDeductionDate");
                  return (
                    <DatePicker
                      placeholderText="DD/MM/YYYY"
                      onChange={(date) => {
                        handleInputFocus("bankDeductionDate");
                        setIsEditable((prev) => ({
                          ...prev,
                          bankDeductionDate: false,
                        }));
                        field.onChange(date);
                      }}
                      selected={field.value}
                      dateFormat="dd/MM/yyyy"
                      className={`data-entry-date-field ${
                        hasValue && hasValue ? "--BG_VVV" : ""
                      }`}
                      readOnly={hasValue && isEditable.bankDeductionDate}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>

        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label">
              {Strings.forwardcontractdate}
            </label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="forwardContractDate"
                render={({ field }) => {
                  const hasValue = watch("forwardContractDate");
                  return (
                    <DatePicker
                      placeholderText="DD/MM/YYYY"
                      onChange={(date) => {
                        handleInputFocus("forwardContractDate");
                        setIsEditable((prev) => ({
                          ...prev,
                          forwardContractDate: false,
                        }));
                        field.onChange(date);
                      }}
                      selected={field.value}
                      dateFormat="dd/MM/yyyy"
                      className={`data-entry-date-field ${
                        hasValue && hasValue ? "--BG_VVV" : ""
                      }`}
                      readOnly={hasValue && isEditable.forwardContractDate}
                    />
                  );
                }}
              />
            </div>
          </div>

          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.exchangerate}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="exchangeRate"
                render={({ field }) => {
                  const hasValue = watch("exchangeRate");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="text"
                      placeholder="exchangeRate"
                      readOnly={hasValue && !isEditable.exchangeRate}
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      onClick={() => handleInputFocus("exchangeRate")}
                    />
                  );
                }}
              />
            </div>
          </div>
          <div className="data-entry-input-block">
            <label className="data-entry-label">
              {Strings.forwardcontractamount}
            </label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="forwardcontractamount"
                render={({ field }) => {
                  const hasValue = watch("forwardcontractamount");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="text"
                      placeholder="forwardcontractamount"
                      readOnly={hasValue && !isEditable.forwardcontractamount}
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      onClick={() => handleInputFocus("forwardcontractamount")}
                    />
                  );
                }}
              />
            </div>
          </div>
          <div className="data-entry-input-block">
            {/* <label className="data-entry-label">{Strings.forwardcontractamount}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="forwardcontractamount"
                render={({ field }) => {
                  const hasValue = watch("forwardcontractamount");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="text"
                      placeholder="forwardcontractamount"
                      readOnly={hasValue && !isEditable.forwardcontractamount}
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))" : "#fff",
                      }}
                      onClick={() => handleInputFocus("forwardcontractamount")}
                    />
                  )
                }}
              />
            </div> */}
          </div>
        </div>

        {/* Banking END */}
        {/* BE details Start */}
        <div className="py-3">
          <h6 className="data-entry-sub-title">BE DETAILS</h6>
        </div>

        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label"> {Strings.beno}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="beNo"
                render={({ field }) => {
                  const hasValue = watch("beNo");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="text"
                      placeholder="beNo"
                      readOnly={hasValue && !isEditable.beNo}
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      onClick={() => handleInputFocus("beNo")}
                    />
                  );
                }}
              />
            </div>
          </div>

          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.bedate}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="beDate"
                render={({ field }) => {
                  const hasValue = watch("beDate");
                  return (
                    <DatePicker
                      placeholderText="DD/MM/YYYY"
                      onChange={(date) => {
                        handleInputFocus("beDate");
                        setIsEditable((prev) => ({ ...prev, beDate: false }));
                        field.onChange(date);
                      }}
                      selected={field.value}
                      dateFormat="dd/MM/yyyy"
                      className={`data-entry-date-field ${
                        hasValue && hasValue ? "--BG_VVV" : ""
                      }`}
                      readOnly={hasValue && isEditable.beDate}
                    />
                  );
                }}
              />
            </div>
          </div>

          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.beusd}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="beUsd"
                render={({ field }) => {
                  const hasValue = watch("beUsd");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="number"
                      placeholder="3421631"
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      readOnly={hasValue && !isEditable.beUsd}
                      // disable={state?.blNo ? true : false}
                      onClick={() => handleInputFocus("beUsd")}
                    />
                  );
                }}
              />
            </div>
          </div>

          <div className="data-entry-input-block">
            <label className="data-entry-label">
              {Strings.duty_payment_date}
            </label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="dutyPaymentDate"
                render={({ field }) => {
                  const hasValue = watch("dutyPaymentDate");
                  return (
                    <DatePicker
                      placeholderText="DD/MM/YYYY"
                      onChange={(date) => {
                        handleInputFocus("dutyPaymentDate");
                        setIsEditable((prev) => ({
                          ...prev,
                          dutyPaymentDate: false,
                        }));
                        field.onChange(date);
                      }}
                      selected={field.value}
                      dateFormat="dd/MM/yyyy"
                      className={`data-entry-date-field ${
                        hasValue && hasValue ? "--BG_VVV" : ""
                      }`}
                      readOnly={hasValue && isEditable.dutyPaymentDate}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>

        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label"> {Strings.customduty}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="customDuty"
                render={({ field }) => {
                  const hasValue = watch("customDuty");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="text"
                      placeholder="customDuty"
                      readOnly={hasValue && !isEditable.customDuty}
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      onClick={() => handleInputFocus("customDuty")}
                    />
                  );
                }}
              />
            </div>
          </div>

          <div className="data-entry-input-block">
            <label className="data-entry-label">
              {Strings.socialwelfarecharge}
            </label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="socialWelfareCharges"
                render={({ field }) => {
                  const hasValue = watch("socialWelfareCharges");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="text"
                      placeholder="socialWelfareCharges"
                      readOnly={hasValue && !isEditable.socialWelfareCharges}
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      onClick={() => handleInputFocus("socialWelfareCharges")}
                    />
                  );
                }}
              />
              {/* <Controller
                control={control}
                name="socialWelfareCharges"
                render={({ field }) => {
                  const hasValue = watch("socialWelfareCharges");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="text"
                      placeholder="socialwelfarecharge"
                      readOnly={hasValue && !isEditable.socialWelfareCharges}
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))" : "#fff",
                      }}
                      onClick={() => handleInputFocus("socialWelfareCharges")}
                    />
                  )
                }}
              /> */}
            </div>
          </div>

          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.begst}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="beIgst"
                render={({ field }) => {
                  const hasValue = watch("beIgst");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="text"
                      placeholder="beIgst"
                      readOnly={hasValue && !isEditable.beIgst}
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      onClick={() => handleInputFocus("beIgst")}
                    />
                  );
                }}
              />
            </div>
          </div>

          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.assessvalue}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="assessValue"
                render={({ field }) => {
                  const hasValue = watch("assessValue");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="text"
                      placeholder="assessValue"
                      readOnly={hasValue && !isEditable.assessValue}
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      onClick={() => handleInputFocus("assessValue")}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>

        {/* BE details END */}
        {/* shipping bill Start */}
        <div className="py-3">
          <h6 className="data-entry-sub-title">SHIPPING BILL</h6>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div
            className="data-entry-input-block"
            style={{
              flexDirection: "column",
              alignItems: "baseline",
              width: "24%",
            }}
          >
            <label className="data-entry-label"> {Strings.shippingbill}</label>
            <div className="data-entry-input" style={{ width: "100%" }}>
              <Controller
                control={control}
                name="shippingBill"
                render={({ field }) => {
                  const hasValue = watch("shippingBill");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="text"
                      placeholder="shippingBill"
                      readOnly={hasValue && !isEditable.shippingBill}
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      onClick={() => handleInputFocus("shippingBill")}
                    />
                  );
                }}
              />
            </div>
          </div>
          <div
            className="data-entry-input-block"
            style={{
              flexDirection: "column",
              alignItems: "baseline",
              width: "75%",
            }}
          >
            <label className="data-entry-label"> {Strings.cfsname}</label>
            <div className="data-entry-input" style={{ width: "100%" }}>
              <Controller
                control={control}
                name="cfsName"
                render={({ field }) => {
                  const hasValue = watch("cfsName");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="text"
                      placeholder="cfsName"
                      readOnly={hasValue && !isEditable.cfsName}
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      onClick={() => handleInputFocus("cfsName")}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>
        {/* shipping bill END */}
        {/* CH & Other Expenses Start */}
        <div className="py-3">
          <h6 className="data-entry-sub-title">CH & Other Expenses</h6>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <div style={{ display: "flex", alignItems: "center" }}>
              <label className="data-entry-label"> {Strings.cfscharges}</label>
              <input
                placeholder="130000"
                {...register("cfsChargesNew")}
                style={{
                  width: "97px",
                  outline: "none",
                  border: "1px solid #000",
                  borderRadius: "9px",
                  background: "transparent",
                  fontSize: "12px",
                  fontWeight: 400,
                  padding: "5px 10px",
                }}
              />
            </div>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="cfsCharges"
                render={({ field }) => {
                  const hasValue = watch("cfsCharges");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="text"
                      placeholder="cfsCharges"
                      readOnly={hasValue && !isEditable.cfsCharges}
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      onClick={() => handleInputFocus("cfsCharges")}
                    />
                  );
                }}
              />
            </div>
          </div>
          <div className="data-entry-input-block">
            <div style={{ display: "flex", alignItems: "center" }}>
              <label className="data-entry-label"> {Strings.chasurvey}</label>
              <input
                placeholder="130000"
                {...register("chaSurveyNew")}
                style={{
                  width: "97px",
                  outline: "none",
                  border: "1px solid #000",
                  borderRadius: "9px",
                  background: "transparent",
                  fontSize: "12px",
                  fontWeight: 400,
                  padding: "5px 10px",
                }}
              />
            </div>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="chaSurvey"
                render={({ field }) => {
                  const hasValue = watch("chaSurvey");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="text"
                      placeholder="chaSurvey"
                      readOnly={hasValue && !isEditable.chaSurvey}
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      onClick={() => handleInputFocus("chaSurvey")}
                    />
                  );
                }}
              />
            </div>
          </div>
          <div className="data-entry-input-block">
            <div style={{ display: "flex", alignItems: "center" }}>
              <label className="data-entry-label">
                {" "}
                {Strings.examinationCharge}
              </label>
              <input
                placeholder="130000"
                {...register("examinationChargenew")}
                style={{
                  width: "97px",
                  outline: "none",
                  border: "1px solid #000",
                  borderRadius: "9px",
                  background: "transparent",
                  fontSize: "12px",
                  fontWeight: 400,
                  padding: "5px 10px",
                }}
              />
            </div>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="examinationCharge"
                render={({ field }) => {
                  const hasValue = watch("examinationCharge");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="text"
                      placeholder="examinationCharge"
                      readOnly={hasValue && !isEditable.examinationCharge}
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      onClick={() => handleInputFocus("examinationCharge")}
                    />
                  );
                }}
              />
            </div>
          </div>
          <div className="data-entry-input-block">
            <div style={{ display: "flex", alignItems: "center" }}>
              <label className="data-entry-label">
                {" "}
                {Strings.cfsGroundRent}
              </label>
              <input
                placeholder="130000"
                {...register("cfsGroundRentNew")}
                style={{
                  width: "97px",
                  outline: "none",
                  border: "1px solid #000",
                  borderRadius: "9px",
                  background: "transparent",
                  fontSize: "12px",
                  fontWeight: 400,
                  padding: "5px 10px",
                }}
              />
            </div>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="cfsGroundRent"
                render={({ field }) => {
                  const hasValue = watch("cfsGroundRent");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="text"
                      placeholder="cfsGroundRent"
                      readOnly={hasValue && !isEditable.cfsGroundRent}
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      onClick={() => handleInputFocus("cfsGroundRent")}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <div style={{ display: "flex", alignItems: "center" }}>
              <label className="data-entry-label">
                {" "}
                {Strings.freightcharge}
              </label>
              <input
                placeholder="130000"
                {...register("freightChargeNew")}
                style={{
                  width: "97px",
                  outline: "none",
                  border: "1px solid #000",
                  borderRadius: "9px",
                  background: "transparent",
                  fontSize: "12px",
                  fontWeight: 400,
                  padding: "5px 10px",
                }}
              />
            </div>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="freightCharge"
                render={({ field }) => {
                  const hasValue = watch("freightCharge");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="text"
                      placeholder="freightCharge"
                      readOnly={hasValue && !isEditable.freightCharge}
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      onClick={() => handleInputFocus("freightCharge")}
                    />
                  );
                }}
              />
            </div>
          </div>
          <div className="data-entry-input-block">
            <div style={{ display: "flex", alignItems: "center" }}>
              <label className="data-entry-label"> {Strings.unloading}</label>
              <input
                placeholder="130000"
                {...register("unloadingNew")}
                style={{
                  width: "97px",
                  outline: "none",
                  border: "1px solid #000",
                  borderRadius: "9px",
                  background: "transparent",
                  fontSize: "12px",
                  fontWeight: 400,
                  padding: "5px 10px",
                }}
              />
            </div>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="unLoading"
                render={({ field }) => {
                  const hasValue = watch("unLoading");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="text"
                      placeholder="unLoading"
                      readOnly={hasValue && !isEditable.unLoading}
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      onClick={() => handleInputFocus("unLoading")}
                    />
                  );
                }}
              />
            </div>
          </div>
          <div className="data-entry-input-block">
            <div style={{ display: "flex", alignItems: "center" }}>
              <label className="data-entry-label">
                {" "}
                {Strings.extraexpences}
              </label>
              <input
                placeholder="130000"
                {...register("yardRentNew")}
                style={{
                  width: "97px",
                  outline: "none",
                  border: "1px solid #000",
                  borderRadius: "9px",
                  background: "transparent",
                  fontSize: "12px",
                  fontWeight: 400,
                  padding: "5px 10px",
                }}
              />
            </div>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="extraExpenses"
                render={({ field }) => {
                  const hasValue = watch("extraExpenses");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="text"
                      placeholder="extraExpenses"
                      readOnly={hasValue && !isEditable.extraExpenses}
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      onClick={() => handleInputFocus("extraExpenses")}
                    />
                  );
                }}
              />
            </div>
          </div>
          <div className="data-entry-input-block">
            <div style={{ display: "flex", alignItems: "center" }}>
              <label className="data-entry-label"> {Strings.sec49}</label>
              <input
                placeholder="130000"
                {...register("sec49New")}
                style={{
                  width: "97px",
                  outline: "none",
                  border: "1px solid #000",
                  borderRadius: "9px",
                  background: "transparent",
                  fontSize: "12px",
                  fontWeight: 400,
                  padding: "5px 10px",
                }}
              />
            </div>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="sec49"
                render={({ field }) => {
                  const hasValue = watch("sec49");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="text"
                      placeholder="sec49"
                      readOnly={hasValue && !isEditable.sec49}
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      onClick={() => handleInputFocus("sec49")}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div
            className="data-entry-input-block"
            style={{
              flexDirection: "column",
              alignItems: "baseline",
              width: "24%",
            }}
          >
            <label className="data-entry-label">
              {" "}
              {Strings.fumigationcharge}
            </label>
            <div className="data-entry-input" style={{ width: "100%" }}>
              <Controller
                control={control}
                name="fumigationCharge"
                render={({ field }) => {
                  const hasValue = watch("fumigationCharge");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="text"
                      placeholder="fumigationCharge"
                      readOnly={hasValue && !isEditable.fumigationCharge}
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      onClick={() => handleInputFocus("fumigationCharge")}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>
        {/* CH & Other Expenses END */}
        {/* Calculation START */}
        <div>
          <div className="data-entry-input-block-two">
            <label className="data-entry-label --data-entry-label-two">
              {Strings.totalcft}
            </label>
            <div className="data-entry-input-two">
              <Controller
                control={control}
                name="totalCft"
                render={({ field }) => {
                  const hasValue = watch("totalCft");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="text"
                      placeholder="totalCft"
                      readOnly={true}
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                        color: "#000",
                      }}
                      // onClick={() => handleInputFocus("totalCft")}
                    />
                  );
                }}
              />
            </div>
          </div>
          <div className="dataEntryInputContainer py-2">
            <div className="data-entry-input-block-two">
              <label className="data-entry-label --data-entry-label-two">
                {Strings.totalint}
              </label>
              <div className="data-entry-input-two">
                <Controller
                  control={control}
                  name="totalInt"
                  render={({ field }) => {
                    const hasValue = watch("totalInt");
                    return (
                      <input
                        className="data-entry-input-field"
                        {...field}
                        type="text"
                        placeholder="totalInt"
                        // readOnly={hasValue && !isEditable.totalInt}
                        readOnly={true}
                        style={{
                          border: hasValue ? "1px solid #000" : "none",
                          background: hasValue
                            ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                            : "#fff",
                          color: "#000",
                        }}
                        onClick={() => handleInputFocus("totalInt")}
                      />
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="dataEntryInputContainer py-2">
            <div className="data-entry-input-block-two">
              <label className="data-entry-label --data-entry-label-two">
                {Strings.agents}
              </label>
              <div className="data-entry-input-two">
                <Controller
                  control={control}
                  name="agents"
                  render={({ field }) => {
                    const hasValue = watch("agents");
                    return (
                      <input
                        className="data-entry-input-field"
                        {...field}
                        type="text"
                        placeholder="agents"
                        readOnly={hasValue && !isEditable.agents}
                        disabled={true}
                        style={{
                          border: hasValue ? "1px solid #000" : "none",
                          background: hasValue
                            ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                            : "#fff",
                          color: "#000",
                        }}
                        onClick={() => handleInputFocus("agents")}
                      />
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="dataEntryInputContainer py-2">
            <div className="data-entry-input-block-two">
              <label className="data-entry-label --data-entry-label-two">
                {Strings.yardrent}
              </label>
              <div className="data-entry-input-two">
                <Controller
                  control={control}
                  name="yardRent"
                  render={({ field }) => {
                    const hasValue = watch("yardRent");
                    return (
                      <input
                        className="data-entry-input-field"
                        {...field}
                        type="text"
                        placeholder="yardRent"
                        readOnly={hasValue && !isEditable.yardRent}
                        disabled={true}
                        style={{
                          border: hasValue ? "1px solid #000" : "none",
                          background: hasValue
                            ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                            : "#fff",
                          color: "#000",
                        }}
                        onClick={() => handleInputFocus("yardRent")}
                      />
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="dataEntryInputContainer py-2">
            <div className="data-entry-input-block-two">
              <label className="data-entry-label --data-entry-label-two">
                {Strings.cost}
              </label>
              <div className="data-entry-input-two">
                <Controller
                  control={control}
                  name="cost"
                  render={({ field }) => {
                    const hasValue = watch("cost");
                    return (
                      <input
                        className="data-entry-input-field"
                        {...field}
                        type="text"
                        placeholder="cost"
                        readOnly={hasValue && !isEditable.cost}
                        disabled={true}
                        style={{
                          border: hasValue ? "1px solid #000" : "none",
                          background: hasValue
                            ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                            : "#fff",
                          color: "#000",
                        }}
                        onClick={() => handleInputFocus("cost")}
                      />
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Calculation END */}
        {/* Table START */}
        <div style={{ overflow: "hidden" }}>
          <div style={{ width: "auto", overflowX: "scroll" }}>
            <table>
              <thead>
                <tr>
                  <th className="data-entry-label --append-label">
                    CONTAINER NO
                  </th>
                  <th className="data-entry-label --append-label">AVG LENTH</th>
                  <th className="data-entry-label --append-label">
                    AVG CFT/AVG GIRTH
                  </th>
                  <th className="data-entry-label --append-label">PCS</th>
                  <th className="data-entry-label --append-label">GROSS CBM</th>
                  <th className="data-entry-label --append-label">NET CBM</th>
                  <th className="data-entry-label --append-label">RATE</th>
                  <th className="data-entry-label --append-label">AMT</th>
                  <th className="data-entry-label --append-label">LORRY</th>
                  <th className="data-entry-label --append-label">SELL RATE</th>
                  <th className="data-entry-label --append-label">SELL CBM</th>
                  <th className="data-entry-label --append-label">
                    BRIJESH COMM
                  </th>
                  <th className="data-entry-label --append-label">
                    AGENT NAME
                  </th>
                  <th className="data-entry-label --append-label">PKL</th>
                  <th className="data-entry-label --append-label">STATUS</th>
                  <th className="data-entry-label --append-label">IMAGE</th>
                </tr>
              </thead>
              <tbody>
                {fields?.map((item, index) => (
                  <tr key={item.id}>
                    <td>
                      <Controller
                        control={control}
                        name={`dataEntryForm.${index}.containerNo`}
                        render={({ field }) => {
                          const hasValue = watch(
                            `dataEntryForm.${index}.containerNo`
                          );
                          return (
                            <input
                              {...field}
                              className="data-entry-input-field"
                              placeholder="CMAU3843174"
                              style={{
                                border: hasValue ? "1px solid #000" : "none",
                                background: hasValue
                                  ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                                  : "#fff",
                                width: "auto",
                              }}
                              readOnly={
                                hasValue && !isEditable?.dataEntryForm[index]
                              }
                              onClick={() =>
                                handleInputFocus(
                                  `dataEntryForm.${index}.containerNo`,
                                  index
                                )
                              }
                            />
                          );
                        }}
                      />
                    </td>
                    <td>
                      <Controller
                        control={control}
                        name={`dataEntryForm.${index}.averageLength`}
                        render={({ field }) => {
                          const hasValue = watch(
                            `dataEntryForm.${index}.averageLength`
                          );
                          return (
                            <input
                              {...field}
                              className="data-entry-input-field"
                              type="number"
                              placeholder="2.27"
                              style={{
                                border: hasValue ? "1px solid #000" : "none",
                                background: hasValue
                                  ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                                  : "#fff",
                                width: "auto",
                              }}
                              readOnly={
                                hasValue && !isEditable?.dataEntryForm[index]
                              }
                              onClick={() =>
                                handleInputFocus(
                                  `dataEntryForm.${index}.averageLength`,
                                  index
                                )
                              }
                            />
                          );
                        }}
                      />
                    </td>
                    <td>
                      <Controller
                        control={control}
                        name={`dataEntryForm.${index}.avgCftAvgGirth`}
                        render={({ field }) => {
                          const hasValue = watch(
                            `dataEntryForm.${index}.avgCftAvgGirth`
                          );
                          return (
                            <input
                              {...field}
                              className="data-entry-input-field"
                              placeholder="5.43"
                              type="number"
                              style={{
                                border: hasValue ? "1px solid #000" : "none",
                                background: hasValue
                                  ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                                  : "#fff",
                                width: "auto",
                              }}
                              readOnly={
                                hasValue && !isEditable?.dataEntryForm[index]
                              }
                              onClick={() =>
                                handleInputFocus(
                                  `dataEntryForm.${index}.avgCftAvgGirth`,
                                  index
                                )
                              }
                            />
                          );
                        }}
                      />
                    </td>
                    <td>
                      <Controller
                        control={control}
                        name={`dataEntryForm.${index}.pcs`}
                        render={({ field }) => {
                          const hasValue = watch(`dataEntryForm.${index}.pcs`);
                          return (
                            <input
                              {...field}
                              className="data-entry-input-field"
                              type="number"
                              placeholder="121"
                              style={{
                                border: hasValue ? "1px solid #000" : "none",
                                background: hasValue
                                  ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                                  : "#fff",
                                width: "auto",
                              }}
                              readOnly={
                                hasValue && !isEditable?.dataEntryForm[index]
                              }
                              onChange={(e) => {
                                field.onChange(e);
                                updateTotals();
                              }}
                              onClick={() =>
                                handleInputFocus(
                                  `dataEntryForm.${index}.pcs`,
                                  index
                                )
                              }
                            />
                          );
                        }}
                      />
                    </td>
                    <td>
                      <Controller
                        control={control}
                        name={`dataEntryForm.${index}.grossCbm`}
                        render={({ field }) => {
                          const hasValue = watch(
                            `dataEntryForm.${index}.grossCbm`
                          );
                          return (
                            <input
                              {...field}
                              className="data-entry-input-field"
                              placeholder="121"
                              type="number"
                              style={{
                                border: hasValue ? "1px solid #000" : "none",
                                background: hasValue
                                  ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                                  : "#fff",
                                width: "auto",
                              }}
                              onChange={(e) => {
                                field.onChange(e);
                                updateTotals();
                              }}
                              readOnly={
                                hasValue && !isEditable?.dataEntryForm[index]
                              }
                              onClick={() =>
                                handleInputFocus(
                                  `dataEntryForm.${index}.grossCbm`,
                                  index
                                )
                              }
                            />
                          );
                        }}
                      />
                    </td>
                    <td>
                      <Controller
                        control={control}
                        name={`dataEntryForm.${index}.netCbm`}
                        render={({ field }) => {
                          const hasValue = watch(
                            `dataEntryForm.${index}.netCbm`
                          );
                          return (
                            <input
                              {...field}
                              className="data-entry-input-field"
                              placeholder="CMAU3843174"
                              style={{
                                border: hasValue ? "1px solid #000" : "none",
                                background: hasValue
                                  ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                                  : "#fff",
                                width: "auto",
                              }}
                              onChange={(e) => {
                                field.onChange(e);
                                updateTotals();
                              }}
                              readOnly={
                                hasValue && !isEditable?.dataEntryForm[index]
                              }
                              onClick={() =>
                                handleInputFocus(
                                  `dataEntryForm.${index}.netCbm`,
                                  index
                                )
                              }
                            />
                          );
                        }}
                      />
                    </td>

                    <td>
                      <Controller
                        control={control}
                        name={`dataEntryForm.${index}.avgRate`}
                        render={({ field }) => {
                          const hasValue = watch(
                            `dataEntryForm.${index}.avgRate`
                          );
                          return (
                            <input
                              {...field}
                              className="data-entry-input-field"
                              placeholder="17.160"
                              type="number"
                              style={{
                                border: hasValue ? "1px solid #000" : "none",
                                background: hasValue
                                  ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                                  : "#fff",
                                width: "auto",
                              }}
                              onChange={(e) => {
                                field.onChange(e);
                                updateTotals();
                              }}
                              readOnly={
                                hasValue && !isEditable?.dataEntryForm[index]
                              }
                              onClick={() =>
                                handleInputFocus(
                                  `dataEntryForm.${index}.avgRate`,
                                  index
                                )
                              }
                            />
                          );
                        }}
                      />
                    </td>
                    <td>
                      <Controller
                        control={control}
                        name={`dataEntryForm.${index}.amt`}
                        render={({ field }) => (
                          <input
                            {...field}
                            placeholder="5.84"
                            type="number"
                            className="data-entry-input-field"
                            style={{ width: "auto" }}
                            step="any"
                            readOnly={true}
                            // onChange={(e) => {
                            //   field.onChange(e);
                            //   updateTotals();
                            // }}
                          />
                        )}
                      />
                    </td>
                    <td>
                      <Controller
                        control={control}
                        name={`dataEntryForm.${index}.lorry`}
                        render={({ field }) => {
                          const hasValue = watch(
                            `dataEntryForm.${index}.lorry`
                          );
                          return (
                            <input
                              {...field}
                              className="data-entry-input-field"
                              type="text"
                              placeholder="TN 25 AQ 7388"
                              style={{
                                border: hasValue ? "1px solid #000" : "none",
                                background: hasValue
                                  ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                                  : "#fff",
                                width: "auto",
                              }}
                              readOnly={
                                hasValue && !isEditable?.dataEntryForm[index]
                              }
                              onClick={() =>
                                handleInputFocus(
                                  `dataEntryForm.${index}.lorry`,
                                  index
                                )
                              }
                            />
                          );
                        }}
                      />
                    </td>
                    <td>
                      <Controller
                        control={control}
                        name={`dataEntryForm.${index}.sellRate`}
                        render={({ field }) => {
                          const hasValue = watch(
                            `dataEntryForm.${index}.sellRate`
                          );
                          return (
                            <input
                              {...field}
                              className="data-entry-input-field"
                              placeholder="5.43"
                              type="number"
                              style={{
                                border: hasValue ? "1px solid #000" : "none",
                                background: hasValue
                                  ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                                  : "#fff",
                                width: "auto",
                              }}
                              readOnly={
                                hasValue && !isEditable?.dataEntryForm[index]
                              }
                              onClick={() =>
                                handleInputFocus(
                                  `dataEntryForm.${index}.sellRate`,
                                  index
                                )
                              }
                            />
                          );
                        }}
                      />
                    </td>
                    <td>
                      <Controller
                        control={control}
                        name={`dataEntryForm.${index}.sellCbm`}
                        render={({ field }) => {
                          const hasValue = watch(
                            `dataEntryForm.${index}.sellCbm`
                          );
                          return (
                            <input
                              {...field}
                              className="data-entry-input-field"
                              placeholder="7388"
                              type="number"
                              style={{
                                border: hasValue ? "1px solid #000" : "none",
                                background: hasValue
                                  ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                                  : "#fff",
                                width: "auto",
                              }}
                              readOnly={
                                hasValue && !isEditable?.dataEntryForm[index]
                              }
                              onChange={(e) => {
                                field.onChange(e);
                                updateTotals();
                              }}
                              onClick={() =>
                                handleInputFocus(
                                  `dataEntryForm.${index}.sellCbm`,
                                  index
                                )
                              }
                            />
                          );
                        }}
                      />
                    </td>
                    <td>
                      <Controller
                        control={control}
                        name={`dataEntryForm.${index}.brijeshComm`}
                        render={({ field }) => {
                          const hasValue = watch(
                            `dataEntryForm.${index}.brijeshComm`
                          );
                          return (
                            <input
                              {...field}
                              className="data-entry-input-field"
                              placeholder="5.84"
                              type="number"
                              style={{
                                border: hasValue ? "1px solid #000" : "none",
                                background: hasValue
                                  ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                                  : "#fff",
                                width: "auto",
                              }}
                              readOnly={
                                hasValue && !isEditable?.dataEntryForm[index]
                              }
                              onChange={(e) => {
                                field.onChange(e);
                                updateTotals();
                              }}
                              onClick={() =>
                                handleInputFocus(
                                  `dataEntryForm.${index}.brijeshComm`,
                                  index
                                )
                              }
                            />
                          );
                        }}
                      />
                    </td>
                    <td>
                      <Controller
                        control={control}
                        name={`dataEntryForm.${index}.agent`}
                        render={({ field }) => {
                          const hasValue = watch(
                            `dataEntryForm.${index}.agent`
                          );
                          return (
                            <input
                              {...field}
                              className="data-entry-input-field"
                              placeholder="Agent Name"
                              type="text"
                              style={{
                                border: hasValue ? "1px solid #000" : "none",
                                background: hasValue
                                  ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                                  : "#fff",
                                width: "auto",
                              }}
                              readOnly={
                                hasValue && !isEditable?.dataEntryForm[index]
                              }
                              onClick={() =>
                                handleInputFocus(
                                  `dataEntryForm.${index}.agent`,
                                  index
                                )
                              }
                            />
                          );
                        }}
                      />
                    </td>
                    <td>
                      <Controller
                        control={control}
                        name={`dataEntryForm.${index}.pkl`}
                        render={({ field }) => {
                          const hasValue = watch(`dataEntryForm.${index}.pkl`);
                          return (
                            <input
                              {...field}
                              className="data-entry-input-field"
                              placeholder="5.43"
                              type="number"
                              style={{
                                border: hasValue ? "1px solid #000" : "none",
                                background: hasValue
                                  ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                                  : "#fff",
                                width: "auto",
                              }}
                              readOnly={
                                hasValue && !isEditable?.dataEntryForm[index]
                              }
                              onClick={() =>
                                handleInputFocus(
                                  `dataEntryForm.${index}.pkl`,
                                  index
                                )
                              }
                            />
                          );
                        }}
                      />
                    </td>
                    <td>
                      <Controller
                        control={control}
                        name={`dataEntryForm.${index}.status`}
                        render={({ field }) => (
                          <select
                            className="data-entry-input-field"
                            {...field}
                            // onChange={(e) => field.onChange({ ...field.value })}
                            style={{ width: "auto" }}
                          >
                            <option value="Active">Active</option>
                            <option value="Inactive">Inactive</option>
                          </select>
                        )}
                      />
                    </td>
                    <td>
                      <Controller
                        control={control}
                        name={`dataEntryForm.${index}.containerImage`}
                        render={({ field }) => (
                          <>
                            <input
                              type="file"
                              accept="image/*"
                              ref={(el) => (imageRefs.current[index] = el)}
                              style={{ display: "none" }}
                              multiple
                              onChange={(e) => {
                                handleImageChange(index, e);
                                field.onChange(e);
                              }}
                            />
                            <div
                              className="data-entry-input-field"
                              style={{
                                width: "120px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: 0,
                              }}
                              onClick={() => imageRefs.current[index]?.click()}
                            >
                              <div>
                                <p style={{ margin: "0px" }}>ADD IMAGE</p>
                              </div>
                            </div>
                          </>
                        )}
                      />
                      <div style={{ display: "flex" }}>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          {containerfileNamesImg[index]?.fileName
                            ?.slice(0, 1)
                            .map((name, fileIndex) => (
                              <>
                                <div
                                  // key={fileIndex}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <span
                                    style={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      width: "90px",
                                    }}
                                  >
                                    {name}
                                  </span>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleRemoveFile(index, fileIndex)
                                    }
                                    style={{
                                      marginLeft: "10px",
                                      color: "red",
                                      border: "none",
                                      background: "transparent",
                                      cursor: "pointer",
                                    }}
                                  >
                                    &#x2716;
                                  </button>
                                </div>
                              </>
                            ))}

                          {containerfileNamesImg[index]?.fileName &&
                            containerfileNamesImg[index]?.fileName?.length >
                              1 && (
                              <Button
                                variant="contained"
                                onClick={toggleDrawergf(true, index)}
                                style={{ marginLeft: "10px" }}
                              >
                                +{" "}
                                {containerfileNamesImg[index]?.fileName.length -
                                  1}
                              </Button>
                            )}
                        </div>
                      </div>
                    </td>

                    <td>
                      <button
                        type="button"
                        onClick={() => {
                          append();
                          setIsEditable((prev) => ({
                            ...prev,
                            dataEntryForm: {
                              ...prev?.dataEntryForm,
                              [fields?.length]: false, // New field is not editable by default
                            },
                          }));
                        }}
                      >
                        +
                      </button>
                      {index > 0 && (
                        <div
                          className="imageIcon"
                          onClick={() => remove(index)}
                        >
                          <img
                            src={deleteIcon}
                            style={{ width: "100%", height: "100%" }}
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot style={{ background: "#9bc2e6" }}>
                <tr>
                  <td className="data-entry-label --append-label text-center">
                    Total:
                  </td>
                  <td className="data-entry-label --append-label text-center"></td>
                  <td className="data-entry-label --append-label text-center"></td>
                  <td className="data-entry-label --append-label text-center">
                    <Controller
                      name="totalPcs"
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          className="data-entry-input-field"
                          placeholder="845"
                          readOnly
                          disabled={true}
                        />
                      )}
                    />
                    {/* {fields.reduce((sum, field) => sum + (parseFloat(field.pcs) || 0), 0)} */}
                  </td>
                  <td className="data-entry-label --append-label text-center">
                    <Controller
                      name="totalGrossCbm"
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          className="data-entry-input-field"
                          placeholder="93.600"
                          readOnly
                          disabled={true}
                        />
                      )}
                    />
                    {/* {fields.reduce((sum, field) => sum + (parseFloat(field.grossCbm) || 0), 0).toFixed(2)} */}
                  </td>
                  <td className="data-entry-label --append-label text-center">
                    <Controller
                      name="totalNetCbm"
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          className="data-entry-input-field"
                          placeholder="93.600"
                          readOnly
                          disabled={true}
                        />
                      )}
                    />
                    {/* {fields.reduce((sum, field) => sum + (parseFloat(field.netCbm) || 0), 0).toFixed(2)} */}
                  </td>
                  <td className="data-entry-label --append-label text-center">
                    <Controller
                      name="totalAvgRate"
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          className="data-entry-input-field"
                          placeholder="845"
                          readOnly
                          disabled={true}
                        />
                      )}
                    />
                    {/* {fields.reduce((sum, field) => sum + (parseFloat(field.avgRate) || 0), 0).toFixed(2)} */}
                  </td>
                  <td className="data-entry-label --append-label text-center">
                    <Controller
                      name="totalAmount"
                      control={control}
                      render={({ field }) => {
                        <input
                          {...field}
                          className="data-entry-input-field"
                          type="number"
                          placeholder="845"
                          step="any"
                          readOnly
                          disabled={true}
                        />;
                      }}
                    />
                    {/* {fields.reduce((sum, field) => sum + (parseFloat(field.amt) || 0), 0).toFixed(2)} */}
                  </td>
                  <td></td>
                  <td className="data-entry-label --append-label text-center"></td>
                  <td className="data-entry-label --append-label text-center">
                    <Controller
                      name="totalsellCbm"
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          className="data-entry-input-field"
                          placeholder="845"
                          readOnly
                          disabled={true}
                        />
                      )}
                    />
                    {/* {fields.reduce((sum, field) => sum + (parseFloat(field.sellCbm) || 0), 0).toFixed(2)} */}
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        {/* Table END */}
        <br />
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block-two">
            <label className="data-entry-label --data-entry-label-two">
              {Strings.totalamt}
            </label>
            <div className="data-entry-input-two">
              <Controller
                name="totalAmount"
                control={control}
                render={({ field }) => {
                  const hasValue = watch("totalAmount");
                  return (
                    <input
                      {...field}
                      className="data-entry-input-field"
                      // readOnly
                      readOnly={true}
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                        color: "#000",
                      }}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block-two">
            <label className="data-entry-label --data-entry-label-two">
              {Strings.roundoff}
            </label>
            <div className="data-entry-input-two">
              <Controller
                control={control}
                name="roundOff"
                render={({ field }) => {
                  const hasValue = watch("roundOff");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="text"
                      placeholder="roundOff"
                      readOnly={hasValue && !isEditable.roundOff}
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                      }}
                      onClick={() => handleInputFocus("roundOff")}

                      // <input {...field} placeholder="5.43"
                      // // onChange={(e) => {
                      // //   updateTotals();
                      // //   field.onChange(e);
                      // // }}
                      // onClick={() => handleInputFocus("roundOff")}

                      // type="number"
                      // className="data-entry-input-field"
                      // readOnly={hasValue && isEditable.roundOff}
                      // step="any"
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block-two">
            <label className="data-entry-label --data-entry-label-two">
              {Strings.totalinvoiceamt}
            </label>
            <div className="data-entry-input-two">
              <Controller
                control={control}
                name="totalInvoiceAmt"
                render={({ field }) => {
                  const hasValue = watch("totalInvoiceAmt");
                  return (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="number"
                      placeholder="0"
                      readOnly={true}
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                        color: "#000",
                      }}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block-two">
            <label className="data-entry-label --data-entry-label-two">
              {Strings.bruesh}
            </label>
            <div className="data-entry-input-two">
              <Controller
                name="brijesh"
                control={control}
                render={({ field }) => {
                  const hasValue = watch("brijesh");
                  return (
                    <input
                      {...field}
                      className="data-entry-input-field"
                      readOnly={true}
                      style={{
                        border: hasValue ? "1px solid #000" : "none",
                        background: hasValue
                          ? "light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4))"
                          : "#fff",
                        color: "#000",
                      }}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>
        {/* {["copydoc"].map((arrayName) => (
          <FileUploadSection
            key={arrayName}
            control={control}
            handleFileChange={handleFileChange}
            fileList={fileList}
            removeFile={removeFile}
            arrayName={arrayName}
            drawerState={drawerState}
            toggleDrawer={toggleDrawer}
          />
        ))} */}
        {/* COPYDOC Start */}
        <Controller
          name="copydoc"
          control={control}
          defaultValue={null}
          render={({ field }) => (
            <>
              <input
                type="file"
                ref={fileInputRef}
                accept=".pdf"
                onChange={(e) => {
                  handleFileChange(e);
                  field.onChange(e);
                }}
                style={{ display: "none" }}
                multiple={false}
              />
            </>
          )}
        />
        <div
          className="dataEntryAddCopyDocument my-4"
          onClick={triggerFileInput}
        >
          <div className="dataEntryAddInnerCopyDocument">
            <h6 className="data-entry-image-head">ADD COPY DOCUMENT</h6>
          </div>
        </div>
        {fileList?.copydocs?.map((ele, i) => (
          <div
            style={{ marginTop: "10px", display: "flex", alignItems: "center" }}
          >
            <span>{ele.fileName ? ele.fileName : "File"}</span>
            <Button
              type="button"
              onClick={() => removeFile("copydocs", ele?.fileName, i)}
              style={{ marginLeft: "10px" }}
            >
              X
            </Button>
          </div>
        ))}
        {/* COPYDOC End */}
        <Controller
          name="summaryDoc"
          control={control}
          defaultValue={null}
          render={({ field }) => (
            <>
              <input
                type="file"
                ref={excelInputRef}
                accept=".xls,.xlsx"
                onChange={(e) => {
                  handleExcelFileChange(e);
                  field.onChange(e);
                }}
                style={{ display: "none" }}
                multiple={false}
              />
            </>
          )}
        />
        <div
          className="dataEntryAddCopyDocument my-4"
          onClick={triggerExcelInput}
        >
          <div className="dataEntryAddInnerCopyDocument">
            <h6 className="data-entry-image-head">ADD SUMMARY DOCUMENT</h6>
          </div>
        </div>
        {fileUploading[0]?.summaryDoc?.name !== "" && (
          <div
            style={{ marginTop: "10px", display: "flex", alignItems: "center" }}
          >
            <span>
              {fileUploading[0]?.summaryDoc?.name
                ? fileUploading[0]?.summaryDoc?.name
                : "File"}
            </span>
            <Button
              type="button"
              onClick={removeExcelFile}
              style={{ marginLeft: "10px" }}
            >
              X
            </Button>
          </div>
        )}
        {/* sblcDocument Start  */}

        {/* invoicedocument End  */}
        {filesvalue.map((val, index) => (
          <FileUploadContainer
            key={index}
            file={files[index]} // Pass uploaded files
            statepdf={val.state !== null ? val.name : null} // Set initial state
            onFileChange={(event) => handleFileChanges(index, event)}
            onRemove={() => handleFileRemove(index)}
            name={val.name}
          />
        ))}
        <div className="dataEntrySubmitBlock">
          <button className="data-entry-submit-btn">
            {isLoading ? <i class="fa fa-spinner fa-spin"></i> : <>SUBMIT</>}
          </button>
        </div>
      </form>
      <PopUpModal isOpen={isPopupOpen} onClose={togglePopup}>
        <div className="img-block">
          <div style={{ width: "40%", height: "60%" }} className="mx-2 pt-2">
            <img src={logo} style={{ width: "100%", height: "100%" }} />
          </div>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center py-2">
          <h6 className="popup-content mb-3">Form Added successfully</h6>
          <button
            className="user-add-button mt-2 mb-2"
            onClick={() => {
              navigate("/");
            }}
          >
            OK
          </button>
        </div>
      </PopUpModal>

      <RightSideDrawer
        toggleDrawer={toggleDrawergf}
        isOpen={isOpen}
        drawerContent={containerfileNamesImg[isIndexing]}
        isIndexing={isIndexing}
        setIsActive={setIsActive}
      />
    </>
  );
};

export default NewDataForm;
