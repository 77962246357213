import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PopUpModal from "../popupmodal/PopUpModal";
import logo from "../../assets/img/timber_logo.png";
import { useDispatch, useSelector } from "react-redux";
import { pl, plstatusdisactive } from "../../Redux/Actions";
export default function RightSidebar({ setIsCount, islabel }) {
  const [isActive, setIsActive] = useState();
  const [selectedAction, setSelectedAction] = useState("");

  const navigate = useNavigate();
  // console.log("setShowPasswordPrompt", setShowPasswordPrompt);

  const btn_label = [
    "COPY DOCUMENT",
    "SBLC",
    "FORM A1",
    "STATUS WAIVER",
    "FORWARD CONTRACT",
    "CLEARING CHARGES",
    "SUMMARY",
    "EWAY",
    "P&L",
    "FILE DOCUMENT",
  ];

  const [isLabel, setIsLabel] = useState();
  const dispatch = useDispatch()
  const handlerChanges = (ele) => {
    // Store the selected action
    setSelectedAction(ele);
    dispatch(plstatusdisactive())
    if (ele === "P&L") {
      console.log("LLLL")
      dispatch(pl())
    } else {
      handleNavigation(ele); // Navigate directly for other actions
    }
  };

  const handleNavigation = (ele) => {
    setIsActive(ele);
    if (ele === "COPY DOCUMENT") {
      navigate("/CopyDocumentConfirm", { state: isLabel });
    } else if (ele === "STATUS WAIVER") {
      navigate("/statusWaiverConfirm");
    } else if (ele === "SBLC") {
      navigate("/sblc");
    } else if (ele === "FORM A1") {
      navigate("/formA1");
    } else if (ele === "EWAY") {
      navigate("/Eway");
    } else if (ele === "SUMMARY") {
      navigate("/summary");
    }
    else if (ele === "FORWARD CONTRACT") {
      navigate("/ForwardContractScreen");
    }
    else if (ele === "P&L") {
      navigate("/PL");
    } else if (ele === "CLEARING CHARGES") {
      navigate("/clearingCharges");
    } else if (ele === "FILE DOCUMENT") {
      navigate("/FileDocument");
    }
    setIsCount(btn_label.findIndex((la) => la === ele) + 1);
  };
  const value = useSelector((state) => state?.plstatus);
  console.log("value_PL", value)
  const handlePasswordSubmit = () => {
    if (value) { // Replace with your validation logic
      handleNavigation(selectedAction);
      dispatch(pl())// Navigate after correct password
    } else {
      alert("Incorrect password! Please try again.");
    }
  };
  useEffect(() => {
    if (value) {

      handlePasswordSubmit()
    }
  }, [value])


  return (
    <>
      {btn_label.map((ele, i) => {
        return (
          <div
            key={i}
            className={`btn_labels ${islabel === ele ? "active_home" : ""}`}
            onClick={() => handlerChanges(ele)}
          >
            <p className="label_sidebar_right">{ele}</p>
          </div>
        );
      })}

      {/* Password Prompt Popup */}

    </>
  );
}
