import React, { useEffect, useState } from "react";
import leftArrow from "../../assets/img/left-arrow.png";
import rigthArrow from "../../assets/img/right-arrow.png";
import { useNavigate } from "react-router-dom";
import addIcon from "../../assets/img/addUser.png";
import deleteIcon from "../../assets/img/delete.svg";
// import searchIcon from "../../common/assets/img/search.png";
import searchIcon from "../../assets/img/search.png";
import Images from "../../assets";
import axios from "axios";
import logo from "../../assets/img/timber_logo.png";
import { useMutation } from "react-query";
import { useQuery } from "react-query";
import PopUpModal from "../popupmodal/PopUpModal";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { copydocument, formdataDetails } from "../../Redux/Actions";

const fetchFileName = async (searchTerm) => {
  const response = await axios.get(
    `https://api.2kvirtualworld.com/BESTTIMBERSDEV/invoices/byfilename?fileName=${searchTerm}`
  );
  return response.data;
};

const fetchBillNo = async (searchTerm) => {
  const response = await axios.get(
    `https://api.2kvirtualworld.com/BESTTIMBERSDEV/invoices/byblno?blNo=${searchTerm}`
  );
  return response.data;
};

const DatamanagementTable = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedData, setSelectedData] = useState(null);
  const recordsPerPage = 8; // Number of records per page
  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  const [deleteIndex, setDeleteIndex] = useState([]); // Array of IDs to delete
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);

  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };

  // Function to handle search button click
  const handleSearchClick = () => {
    if (searchTerm.trim()) {
      setDebouncedSearchTerm(searchTerm?.trim());
      setIsSearching(true);
    }
  };
  // Function to handle search box cleanup
  const handleClearSearch = () => {
    setSearchTerm("");
    setDebouncedSearchTerm("");
    setIsSearching(false);
  };
  // Filter data based on search term

  const fetchDataManagement = async (page) => {
    const response = await axios.get(
      `${process.env.REACT_APP_HOST}/invoices/pageandsize?page=${page}&size=${recordsPerPage}`
    );
    return response.data;
  };

  // React Query hook to fetch paginated data
  const {
    data: paginatedData,
    isLoading,
    isError,
    refetch,
  } = useQuery(["data", currentPage], () => fetchDataManagement(currentPage), {
    keepPreviousData: true,
    enabled: !isSearching,
  });

  // React Query hook to fetch bill details, only if searchTerm is available
  const { data: fetchBill, isLoading: loadingBillNo } = useQuery(
    ["fetchBillNo", debouncedSearchTerm],
    () => fetchBillNo(debouncedSearchTerm),

    {
      enabled: !!debouncedSearchTerm, // Only enable the query if searchTerm is not empty
    }
  );

  // React Query hook to fetch file details based on the debounced search term
  const { data: fetchFile, isLoading: loadingFileName } = useQuery(
    ["fetchFileName", debouncedSearchTerm],
    () => fetchFileName(debouncedSearchTerm),
    {
      enabled: !!debouncedSearchTerm, // Only trigger the query when the search term is not empty
    }
  );
  // console.log("sdfjksdfjksdfk", loadingFileName, loadingBillNo, isSearching);
  useEffect(() => {
    let normalizedData = [];
    if (fetchFile) {
      normalizedData = Array.isArray(fetchFile) ? fetchFile : [fetchFile];
    } else if (fetchBill) {
      normalizedData = Array.isArray(fetchBill) ? fetchBill : [fetchBill];
    }
    setSelectedData(normalizedData);
    setIsSearching(false); // Reset searching after data fetch
  }, [fetchFile, fetchBill]);

  // Extract the details of records from the fetched data
  const details = paginatedData?.content || [];

  const getBlNumbersById = (deleteIndex) => {
    return (
      details &&
      details
        ?.filter((item) => deleteIndex?.includes(item.id)) // Check if item's id is in the provided ids array
        .map((item) => item.blNo)
    ); // Extract blNo values from the matching objects
  };
  const blNumbers = getBlNumbersById(deleteIndex);

  // function compareNumbers(a, b) {
  //   if (!a.fileName) return 1; // Push objA to the end
  //   if (!b.fileName) return -1; // Push objB to the end

  //   // Normalize case and extract numeric part
  //   const numA = parseInt(a.fileName.toUpperCase().replace("F", ""), 10);
  //   const numB = parseInt(b.fileName.toUpperCase().replace("F", ""), 10);

  //   return numA - numB;
  //   // const numA = parseInt(a.fileName.toUpperCase().replace("F", ""), 10);
  //   // const numB = parseInt(b.fileName.toUpperCase().replace("F", ""), 10);
  //   // return numA - numB;
  // }
  // const currentItemss = filteredData?.sort(compareNumbers)
  // const currentItems = currentItemss?.slice(indexOfFirstItem, indexOfLastItem);

  // Determine if pagination buttons should be disabled
  const isPrevDisabled = currentPage === 1; // Disable "Prev" on the first page
  const isNextDisabled = details?.length < recordsPerPage; // Disable "Next" if less than 10 records are fetched

  // Handler for the "Prev" button
  const handlePrevPage = () => {
    if (!isPrevDisabled) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  // Handler for the "Next" button
  const handleNextPage = () => {
    if (!isNextDisabled) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handleCheckboxChange = (id) => {
    if (deleteIndex?.includes(id)) {
      // If the ID is already in the deleteIndex array, remove it
      setDeleteIndex(deleteIndex.filter((item) => item !== id));
    } else {
      // Otherwise, add the ID to the deleteIndex array
      setDeleteIndex([...deleteIndex, id]);
    }
  };

  // const handleNextPage = () => {
  //   if (!isNextDisabled) setCurrentPage(currentPage + 1);
  // };

  const HandlerDelete = async () => {
    try {
      // Ensure deleteIndex is used correctly
      const response = await axios.delete(
        `${process.env.REACT_APP_HOST}/invoices/delete`,
        {
          data: deleteIndex, // Assuming the backend expects an array of IDs in the body
        }
      );
      const responses = await axios.delete(
        `https://api.2kvirtualworld.com/bestTimbers/copydocuments/deleteByBlNos`,
        {
          data: blNumbers, // Assuming the backend expects an array of IDs in the body
        }
      );

      console.log("Delete response:", response.data);

      // Refetch data
      refetch();
      // Close popups and reset states
      setDeletePopUp(true);
      setPopupOpen(false);
      setDeleteIndex([]); // Clear the deleteIndex
      return response.data;
    } catch (error) {
      console.log("Error", error);
    }
  };
  // COPY DOC API GET START
  const [copyid, setcopyId] = useState();
  const [isheat, setIsheat] = useState(false);
  const fetchcopydoc = async () => {
    return await axios.get(
      `${process.env.REACT_APP_HOST}/copydocuments/${copyid}`
    );
  };
  const Copydoc = useQuery(["data", copyid], fetchcopydoc, {
    enabled: isheat,
    onSuccess: () => {
      setIsheat(false);
    },
    onError: () => {
      setIsheat(false);
    },
  });
  // COPY DOC API GET END
  const [isItemsval, setIsItemsval] = useState();
  const dispatch = useDispatch();
  const HandlerEdit = async (val) => {
    // Copydoc()
    setIsItemsval(val);
    var abc;
    if (val !== undefined) {
      abc = val;
    }
    setcopyId(val?.blNo);
    setIsheat(true);
    if (copyid !== false && copyid !== undefined) {
      // }
      // if (!isheat && val) {
      if (Copydoc) {
        // sessionStorage.setItem("copydoc", Copydoc?.data && Copydoc?.data?.data?.copydocuments ? JSON.stringify(Copydoc?.data && Copydoc?.data?.data?.copydocuments) : [])
        dispatch(copydocument(Copydoc?.data?.data?.copyDocumentsList));
      }
      // sessionStorage.setItem("formdata", JSON.stringify(val && val));
      dispatch(formdataDetails(val && val));
      // Now, let's store another value if needed, always overwrite the previous value
      // const newValue = { ...val, newField: "newValue" }; // Example of a new object
      // sessionStorage.setItem("formdata", JSON.stringify(newValue));

      // Confirm the value has been updated

      navigate("/dataEntryForm", { state: val });
    }
    // }
  };
  useEffect(() => {
    if (copyid) {
      HandlerEdit(isItemsval);
    }
  }, [copyid]);

  return (
    <>
      <div className="headerTimeLine  px-3 py-3">
        <div className="px-5 d-flex justify-content-between">
          <div className="d-flex align-items-center gap-2">
            <input
              type="search"
              className="--new-search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="search by blNo , fileName"
              title="search by blNo , fileName"
            />

            <div className="search-button" onClick={handleSearchClick}>
              <img src={searchIcon} style={{ width: "100%", height: "100%" }} />
            </div>
            {searchTerm && (
              <button
                onClick={handleClearSearch}
                style={{ border: "1px solid black" }}
              >
                Clear
              </button>
            )}
          </div>

          <div
            className="d-flex gap-2 align-items-center"
            style={{ width: "35%" }}
          >
            <div
              className="add-user-btn-block"
              onClick={() => {
                navigate("/dataEntryForm");
              }}
            >
              <button className="new-user-btn">NEW DATA ENTRY</button>
              <div style={{ width: "20px", height: "20px" }}>
                <img src={addIcon} className="img-icon"></img>
              </div>
            </div>
            {deleteIndex.length !== 0 ? (
              <div
                className="add-user-btn-block"
                onClick={() => {
                  setPopupOpen(true);
                }}
              >
                <button className="new-user-btn">DELETE</button>
                <div style={{ width: "20px", height: "20px" }}>
                  <img src={deleteIcon} className="img-icon"></img>
                </div>
              </div>
            ) : (
              <div
                className="add-user-btn-block"
                style={{
                  background: "transparent",
                  boxShadow: "none",
                }}
              ></div>
            )}
          </div>
        </div>
      </div>
      <div className="px-5  mt-3">
        <div className="view-table-block py-3 px-2">
          <table className="view-user-table">
            <thead>
              <tr>
                <td className="view-user-table-head --first-head">
                  SELECT/EDIT
                </td>
                <td className="view-user-table-head">REMAINING DAYS</td>
                <td className="view-user-table-head">BL NO</td>
                <td className="view-user-table-head">FILE NAME</td>

                <td className="view-user-table-head">EXPORTER</td>
                <td className="view-user-table-head">INVOICE NO</td>
                <td className="view-user-table-head">BE NO</td>
                <td className="view-user-table-head --last-head">
                  BG NUMBER & DATE
                </td>
                {/* <td className="view-user-table-head --last-head"></td> */}
              </tr>
            </thead>
            <tbody
              className={
                isLoading || loadingBillNo || loadingFileName
                  ? "loading_body"
                  : ""
              }
            >
              {isLoading || loadingBillNo || loadingFileName ? (
                <tr>
                  <td
                    colSpan="8"
                    style={{
                      textAlign: "center",
                      position: "relative",
                      height: "180px",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        width: "300px",
                        margin: "0 auto",
                      }}
                    >
                      <div className="loading" /> {/* CSS spinner */}
                      <img
                        alt="logo"
                        src={Images.logo}
                        style={{
                          opacity: 0.7,
                          width: "100%",
                          height: "auto",
                          position: "absolute",
                          top: 0,
                          left: 0,
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ) : isError ? (
                <tr>
                  <td
                    colSpan="8"
                    style={{
                      textAlign: "center",
                      color: "#000",
                      fontSize: "18px",
                      fontWeight: 800,
                    }}
                  >
                    No Network...
                  </td>
                </tr>
              ) : selectedData && selectedData?.length > 0 ? (
                selectedData?.map((val, i) => (
                  <tr key={i} className="view-user-table-row">
                    <td className="view-user-table-data">
                      <div style={{ display: "flex" }}>
                        <input
                          id="myCheckbox"
                          type="checkbox"
                          checked={deleteIndex.includes(val.id)}
                          onChange={() => handleCheckboxChange(val.id)}
                        />
                        <div onClick={() => HandlerEdit(val)}>
                          <img
                            src={Images?.editpencil}
                            style={{ width: "50px", height: "25px" }}
                            alt="Edit"
                          />
                        </div>
                      </div>
                    </td>
                    <td className="view-user-table-data">
                      {val?.remainingDays || "-"}
                    </td>
                    <td className="view-user-table-data">{val?.blNo || "-"}</td>
                    <td className="view-user-table-data">
                      {val?.fileName || "-"}
                    </td>
                    <td className="view-user-table-data">
                      {val?.exporter || "-"}
                    </td>
                    <td className="view-user-table-data">
                      {val?.invoiceNo || "-"}
                    </td>
                    <td className="view-user-table-data">{val?.beNo || "-"}</td>
                    <td className="view-user-table-data">
                      {val?.bgNumber || val?.bgdate
                        ? `${val?.bgNumber || "-"} / ${
                            val?.bgdate
                              ? moment(val?.bgdate).format("DD-MM-YYYY")
                              : "-"
                          }`
                        : "-"}
                    </td>
                  </tr>
                ))
              ) : details && details?.length > 0 ? (
                details?.map((val, i) => (
                  <tr key={i} className="view-user-table-row">
                    <td className="view-user-table-data">
                      <div style={{ display: "flex" }}>
                        <input
                          id="myCheckbox"
                          type="checkbox"
                          checked={deleteIndex.includes(val.id)}
                          onChange={() => handleCheckboxChange(val.id)}
                        />
                        <div onClick={() => HandlerEdit(val)}>
                          <img
                            src={Images?.editpencil}
                            style={{ width: "50px", height: "25px" }}
                            alt="Edit"
                          />
                        </div>
                      </div>
                    </td>
                    <td className="view-user-table-data">
                      {val?.remainingDays || "-"}
                    </td>
                    <td className="view-user-table-data">{val?.blNo || "-"}</td>
                    <td className="view-user-table-data">
                      {val?.fileName || "-"}
                    </td>
                    <td className="view-user-table-data">
                      {val?.exporter || "-"}
                    </td>
                    <td className="view-user-table-data">
                      {val?.invoiceNo || "-"}
                    </td>
                    <td className="view-user-table-data">{val?.beNo || "-"}</td>
                    <td className="view-user-table-data">
                      {val?.bgNumber || val?.bgdate
                        ? `${val?.bgNumber || "-"} / ${
                            val?.bgdate
                              ? moment(val?.bgdate).format("DD-MM-YYYY")
                              : "-"
                          }`
                        : "-"}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" style={{ textAlign: "center" }}>
                    No results found
                  </td>
                </tr>
              )}
            </tbody>

            {/* <div
              className="pt-2 px-1 table-bottom"
              style={{ width: "100%", border: "1px solid red" }}
            ></div> */}
          </table>
        </div>
        <div className="table-bottom">
          <button
            onClick={handlePrevPage}
            disabled={isPrevDisabled}
            className="arrow-btn"
          >
            &lt;
          </button>
          <button
            onClick={handleNextPage}
            disabled={isNextDisabled}
            className="arrow-btn"
          >
            &gt;
          </button>
        </div>
      </div>
      <PopUpModal isOpen={isPopupOpen} onClose={togglePopup}>
        <div className="img-block">
          <div style={{ width: "40%", height: "60%" }} className="mx-2 pt-2">
            <img
              src={logo}
              style={{ width: "100%", height: "100%" }}
              alt="logo"
            />
          </div>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center py-1">
          <h6 className="popup-content mb-3">
            Are you sure you want to delete this detail?
          </h6>
          <div className="d-flex justify-content-center gap-5 mb-3">
            <button
              className="user-delete-button"
              onClick={() => {
                HandlerDelete();
                // Optionally pass itemId here if needed
              }}
            >
              OK
            </button>
            <button className="user-delete-button" onClick={togglePopup}>
              NO
            </button>
          </div>
        </div>
      </PopUpModal>

      {/* Success Popup */}
      <PopUpModal isOpen={deletePopUp} onClose={() => setDeletePopUp(false)}>
        <div className="img-block">
          <div style={{ width: "40%", height: "60%" }} className="mx-2 pt-2">
            <img
              src={logo}
              style={{ width: "100%", height: "100%" }}
              alt="logo"
            />
          </div>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center py-1">
          <h6 className="popup-content mb-3">
            Form Added deleted successfully
          </h6>
          <button
            className="user-delete-button mb-3"
            onClick={() => {
              setDeletePopUp(false);
              window.location.reload();
              // Navigate to another page if needed
              // navigate("/agentManagement");
            }}
          >
            OK
          </button>
        </div>
      </PopUpModal>
    </>
  );
};

export default DatamanagementTable;
