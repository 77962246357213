import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { pl, plstatus } from '../../Redux/Actions';
import PopUpModal from '../popupmodal/PopUpModal';
import logo from '../../assets/img/logo.png';
import axios from 'axios';
import { useMutation } from 'react-query';
export default function PLPassword() {
    const dispatch = useDispatch()
    const [password, setPassword] = useState("");
    const value = useSelector((state) => state?.pl);
    const togglePopup = () => {
        dispatch(pl())
    };
    const { mutate, isLoading, isError, isSuccess } = useMutation(
        (postData) => {
            // Log postData before making the API request
            console.log("postData before API call:", postData);

            return axios
                .post(`${process.env.REACT_APP_HOST}plregister/login`, postData, {
                    maxContentLength: Infinity,
                    maxBodyLength: Infinity,
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    console.log("API response:", response.data);
                    return response.data; // Return response data if needed
                })
                .catch((error) => {
                    console.error("Error occurred:", error);
                    throw error; // Re-throw error to be caught by onError
                });
        },
        {
            onSuccess: () => {
                console.log("User added successfully..");
                dispatch(plstatus())

            },
            onError: (error) => {
                console.error("Mutation failed:", error);
            },
        }
    );

    const onSubmit = () => {
        let value = {
            "userName": "besttimbers@example.com",
            "password": password
            // "password": "bestimbersAdmin@123"
        }
        mutate(value)
    }
    return (
        <PopUpModal isOpen={value} onClose={togglePopup}>
            <div className="img-block">
                <div style={{ width: "40%", height: "60%" }} className="mx-2 pt-2">
                    <img src={logo} style={{ width: "100%", height: "100%" }} />
                </div>
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center py-2">

                <input className='data-entry-input-field ' style={{ width: "60%" }} onChange={(e) => setPassword(e.target.value)} value={password} placeholder='Password' />
                <button
                    className="user-add-button mt-2 mb-2"
                    onClick={onSubmit}
                >
                    OK
                </button>
            </div>
        </PopUpModal >
    )
}
