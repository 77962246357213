import React, { useRef, useState } from 'react'
import Sidebar from '../../common/components/Sidebar/sidebar'
import TimeLine from '../../common/components/TimeLine/TimeLine'
import RightSidebar from '../../common/components/RightSidebar/RightSidebar'
import StatusWaiver from '../../common/components/statusWaiver/StatusWaiver'
import Forwardcontract from '../../common/components/Forwardcontract/Forwardcontract'
import { useLocation } from 'react-router-dom'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import PLPassword from '../../common/components/plPassword/PLPassword'

const ForwardcontractScreen = ({ route }) => {
  const [isCount, setIsCount] = useState(5);
  const { state } = useLocation();
  // const { itemId, otherParam } = route&&route.params;
  // const openModal = () => {
  //   handleGeneratePdf()
  //     // setModalIsOpen(true);
  // };
  // const divRef1 = useRef();
  // const divRef2 = useRef();

  // const handleGeneratePdf = async () => {
  //   const pdf = new jsPDF("p", "mm", "a4");
  //   const options = {
  //     scale: 2,
  //     useCORS: true,
  //   };

  //   // Capture the first div as an image
  //   const canvas1 = await html2canvas(divRef1.current, options);
  //   const imgData1 = canvas1.toDataURL("image/png");
  //   const imgWidth = 210; // A4 width in mm
  //   const imgHeight = (canvas1.height * imgWidth) / canvas1.width;
  //   pdf.addImage(imgData1, "PNG", 0, 0, imgWidth, imgHeight);

  //   // Add a new page if the second div is on a new page
  //   pdf.addPage();

  //   // Capture the second div as an image
  //   const canvas2 = await html2canvas(divRef2.current, options);
  //   const imgData2 = canvas2.toDataURL("image/png");
  //   const imgHeight2 = (canvas2.height * imgWidth) / canvas2.width;
  //   pdf.addImage(imgData2, "PNG", 0, 0, imgWidth, imgHeight2);

  //   // Save the PDF
  //   pdf.save("combined_divs.pdf");
  // };

  // const handleDownload = () => {
  //   // Create and download PDF for Page 1
  //   // if (page1Canvas) {
  //   //   const doc1 = new jsPDF();
  //   //   const imgData1 = page1Canvas.toDataURL("image/png");
  //   //   doc1.addImage(imgData1, "PNG", 0, 0);
  //   //   doc1.save("page1.pdf");
  //   // }

  //   // Create and download PDF for Page 2
  //   const page2Element = document.getElementById("StatusWaiver_Screen")
  //   html2canvas(page2Element, { scale: 2 }).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new jsPDF("p", "mm", "a4");
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = pdf.internal.pageSize.getHeight();
  //     const imgWidth = pdfWidth;

  //     const imgHeight = (canvas.height * pdfWidth) / canvas.width;

  //     pdf.addImage(imgData, "PNG", 0, 0, imgWidth, pdfHeight);
  //     pdf.save("sblc-confirm.pdf");
  //   });

  // };
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2 p-0 col-md-3">
            <Sidebar />
          </div>

          <div className="col-sm-10 col-lg-10">
            <div className="row align-items-center">
              <div className="headerTimeLine px-5">
                <TimeLine currentStep={isCount} />
              </div>
            </div>
            <div className="row" style={{ paddingLeft: "3rem" }}>
              <div className="col-lg-9 p-0 m-0 d-flex align-items-center justify-content-center">
                <div className='statusWaiverMainContainer'>
                  <div id='StatusWaiver_Screen'>

                    <Forwardcontract />
                  </div>

                </div>

              </div>
              <div className="col-lg-3 p-0 right_col">
                <div className="headerTimeLine_left">
                  <RightSidebar setIsCount={setIsCount} islabel={'FORWARD CONTRACT'} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PLPassword />
    </>
  )
}

export default ForwardcontractScreen