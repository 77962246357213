import logo from './logo.svg';
import Images from './common/assets/index'
import Strings from './common/res/String';
import Navigation from './common/navigation/navigation';
import Sidebar from './common/components/Sidebar/sidebar';
import { store, persistor } from "./common/Redux/Store"
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>

        <Navigation />
      </PersistGate>
    </Provider>
    // <Sidebar/>
  );
}

export default App;
