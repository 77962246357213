import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
// import input from "../input/input";
import Model from "../model/Model";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { useSelector } from "react-redux";

const PLForm = () => {
  const { control, handleSubmit, setValue, reset, watch } = useForm();
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleConfirmModal = () => {
    // Perform delete operation
    console.log("User detail deleted");
    setModalOpen(false);
  };
  // const retrievedData = JSON.parse(sessionStorage.getItem("formdata"));
  const retrievedData = useSelector((state) => state?.formdata)
  const calculateTotalSellCbm = (data) => {
    return data.reduce((total, item) => {
      return total + item.sellCbm;
    }, 0);
  };
  console.log("retrievedData_PL", retrievedData);
  var totalAMT = retrievedData.userForm.reduce((total, item) => {
    return total + ((item?.avgRate + item?.brijeshComm) * item?.netCbm)
  }, 0)
  const calculateTotalRateDiff = (data) => {
    return data.reduce((total, item) => {
      const landing = (((item?.avgRate + item?.brijeshComm) * retrievedData?.sblcInterestUsdRate) / 35.315) + retrievedData?.cost
      const ratediff = -(landing && landing - item?.sellRate) * (item?.netCbm * 35.315)
      return total + ratediff;
    }, 0);
  };
  const calculateTotalCbmDiff = (data) => {
    return data.reduce((total, item) => {
      let cbmdiff = (item?.sellCbm - item?.netCbm) * (item?.sellRate * 35.315);
      return total + cbmdiff;
    }, 0);
  };
  var netCbm = 0
  const calculateTotalPL = (data) => {
    return data.reduce((total, item) => {
      const landing = (((item?.avgRate + item?.brijeshComm) * retrievedData?.sblcInterestUsdRate) / 35.315) + retrievedData?.cost
      const ratediff = -(landing && landing - item?.sellRate) * (item?.netCbm * 35.315)
      let cbmdiff = (item?.sellCbm - item?.netCbm) * (item?.sellRate * 35.315);
      let totalpl = ratediff + cbmdiff;
      return total + totalpl;
    }, 0);
  };
  const totalSellCbm = calculateTotalSellCbm(retrievedData && retrievedData?.userForm);
  const totalRateDiff = calculateTotalRateDiff(retrievedData?.userForm || []);
  const totalCbmiff = calculateTotalCbmDiff(retrievedData?.userForm || []);
  var totalpl = calculateTotalPL(retrievedData?.userForm || []);
  //  Excel File Download Start

  const downloadExcel = () => {
    // Collect the table data

    let ItemsValue = retrievedData && retrievedData?.userForm
      .map((ele, index) => {
        console.log("ele?.avgRate + ele?.brijeshComm", 35.315 / (ele?.avgRate + ele?.brijeshComm) * retrievedData?.sblcInterestUsdRate, retrievedData?.sblcInterestUsdRate, retrievedData?.cost)
        let landing = (((ele?.avgRate + ele?.brijeshComm) * retrievedData?.sblcInterestUsdRate) / 35.315) + retrievedData?.cost
        console.log("landing && landing - ele?.sellRate", landing && landing - ele?.sellRate)
        let ratediff = -(landing && landing - ele?.sellRate) * (ele?.netCbm * 35.315)
        let cbmdiff = (ele?.sellCbm - ele?.netCbm) * (ele?.sellRate * 35.315)
        return ratediff + cbmdiff
        // netCbm = Number(ele?.netCbm) + netCbm
      })
    console.log("totalpl", totalpl, ItemsValue)
    const tableData = retrievedData?.userForm.map((item, index) => ({
      "S.no": index + 1,
      "CONTAINER NO": item?.containerNo,
      "NOS": item?.pcs,
      "NET CBM": item?.netCbm,
      "RATE": item?.avgRate + item?.brijeshComm,
      "AMT": Number((item?.avgRate + item?.brijeshComm) * item?.netCbm).toFixed(2),
      "CFT/G": item?.avgCftAvgGirth,
      "LANDING": (((item?.avgRate + item?.brijeshComm) * retrievedData?.sblcInterestUsdRate) / 35.315) + retrievedData?.cost,
      "SELL": item?.sellRate,
      "CBM": item?.sellCbm,
      "RATE DIFF": -(((((item?.avgRate + item?.brijeshComm) * retrievedData?.sblcInterestUsdRate) / 35.315) + retrievedData?.cost) - item?.sellRate) * (item?.netCbm * 35.315),
      "CBM DIFF": Number((item?.sellCbm - item?.netCbm) * (item?.sellRate * 35.315)).toFixed(2),
      "TOTAL": Number(ItemsValue[index]).toFixed(2),
    }));

    // Collect the summary data
    const summaryData = [
      { Label: "BL NO :", Value: retrievedData?.blNo || "NULL" },
      { Label: "TOTAL PCS", Value: retrievedData?.totalPcs || "NULL" },
      { Label: "TOTAL NET CBM", Value: netCbm || "NULL" },
      { Label: "AVG RATE", Value: retrievedData?.rate + (retrievedData?.bruesh || 0) },
      { Label: "TOTAL AMT", Value: totalAMT || "NULL" },
      { Label: "DISCOUNT", Value: retrievedData?.roundOff || "NULL" },
      { Label: "INV AMT", Value: retrievedData?.bgAmtAndInterestAmt || "NULL" },
      { Label: "BRIJESH", Value: retrievedData?.bruesh || "NULL" },
      { Label: "TOTAL SELL CBM", Value: totalSellCbm },
      { Label: "TOTAL RATE DIFF", Value: Number(totalRateDiff).toFixed(2) },
      { Label: "TOTAL CBM DIFF", Value: Number(totalCbmiff).toFixed(2) },
      { Label: "TOTAL", Value: Number(totalpl).toFixed(2) },
      { Label: "CAPITAL INT,SALARIES & HOUSE EXP", Value: Number(300000).toFixed(2) },
      { Label: "NETT", Value: (Number(totalpl) - 300000).toFixed(2) }
    ];

    // Convert to worksheet
    const tableWorksheet = XLSX.utils.json_to_sheet(tableData, { origin: "A2" }); // Start from A2 to leave space for the header
    const summaryWorksheet = XLSX.utils.json_to_sheet(summaryData);

    // Merge the two worksheets
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, tableWorksheet, "Table Data");
    XLSX.utils.sheet_add_json(workbook.Sheets["Table Data"], summaryData, { origin: -1 });

    // Generate Excel file
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

    // Save the Excel file
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "PL_Data.xlsx");
  };

  //  Excel File Download End
  // let a = retrievedData && retrievedData?.userForm.reduce((total, val) =>
  // console.log("TOTTT", total)
  //   (val?.avgRate + val?.brijeshComm) * val?.netCbm)
  // console.log("LLSS_KDSD", retrievedData.userForm.reduce((total, item) => {
  //   return total + ((item?.avgRate + item?.brijeshComm) * item?.netCbm)
  // }, 0));

  return (
    <>
      <div className="pl-head py-2">
        <label className="summary-label">BL NO :</label>
        <div className="pl-search">
          <input
            className="data-entry-input-field --hiddens"
            disabled
            value={retrievedData && retrievedData?.blNo}
          />
        </div>
      </div>
      <div className={`${retrievedData && retrievedData?.userForm.length > 5 ? "summary-table-container" : "summary-table-container_single"} mt-2 px-4 pt-3`}>
        <table className={`${retrievedData && retrievedData?.userForm.length > 5 ? "summary-table" : "summary-table_single"}`}>
          <thead className="summary-table-head">
            <tr>
              <th>S.no</th>
              <th>CONTAINER NO</th>
              <th>NOS</th>
              <th>NET CBM</th>
              <th>RATE</th>
              <th>AMT</th>
              <th>CFT/G</th>
              <th>LANDING</th>
              <th>SELL</th>
              <th>CBM</th>
              <th>RATE DIFF</th>
              <th>CBM DIFF</th>
              <th>TOTAL</th>
            </tr>
          </thead>
          <tbody>
            {retrievedData && retrievedData?.userForm
              .map((ele, index) => {
                console.log("ele?.avgRate + ele?.brijeshComm", 35.315 / (ele?.avgRate + ele?.brijeshComm) * retrievedData?.sblcInterestUsdRate, retrievedData?.sblcInterestUsdRate, retrievedData?.cost)
                let landing = (((ele?.avgRate + ele?.brijeshComm) * retrievedData?.sblcInterestUsdRate) / 35.315) + retrievedData?.cost
                console.log("landing && landing - ele?.sellRate", landing && landing - ele?.sellRate)
                let ratediff = -(landing && landing - ele?.sellRate) * (ele?.netCbm * 35.315)
                let cbmdiff = (ele?.sellCbm - ele?.netCbm) * (ele?.sellRate * 35.315)
                let totalpl = ratediff + cbmdiff
                netCbm = Number(ele?.netCbm) + netCbm
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{ele?.containerNo}</td>
                    <td>{ele?.pcs}</td>
                    <td>{ele?.netCbm}</td>
                    <td>{ele?.avgRate + ele?.brijeshComm}</td>
                    <td>{Number((ele?.avgRate + ele?.brijeshComm) * ele?.netCbm).toFixed(2)}</td>
                    <td>{ele?.avgCftAvgGirth}</td>
                    <td>{Number(landing).toFixed(0)}</td>
                    <td>{ele?.sellRate}</td>
                    <td>{ele?.sellCbm}</td>
                    <td>{Number(ratediff).toFixed(2)}</td>
                    <td>{Number(cbmdiff).toFixed(2)}</td>
                    <td>{Number(totalpl).toFixed(2)}</td>


                  </tr>
                )
              }
              )}
          </tbody>
        </table>
      </div>

      <form>
        <div className="pl-head py-2">
          <label className="summary-label">TOTAL PCS :</label>
          <div className="pl-search">
            <input
              className="data-entry-input-field --hiddens"
              disabled
              value={retrievedData && retrievedData?.totalPcs}
            />
          </div>
        </div>
        <div className="pl-head py-2">
          <label className="summary-label">TOTAL NET CBM</label>
          <div className="pl-search">
            <input
              className="data-entry-input-field --hiddens"
              disabled
              // value={retrievedData && retrievedData?.totalNetCbm?retrievedData && retrievedData?.totalNetCbm:"NULL"}
              value={netCbm}
            />
          </div>
        </div>
        {/* <div className="pl-head py-2">
          <label className="summary-label">AVG RATE :</label>
          <div className="pl-search">
            <input
              className="data-entry-input-field --hiddens"
              disabled
              value={retrievedData && retrievedData?.rate + retrievedData && retrievedData?.bruesh}
            />
          </div>
        </div> */}
        <div className="pl-head py-2">
          <label className="summary-label">TOTAL AMT :</label>
          <div className="pl-search">
            <input
              className="data-entry-input-field --hiddens"
              disabled
              value={totalAMT}
            // value={retrievedData && retrievedData?.totalAmount ? retrievedData && retrievedData?.totalAmount : "NULL"}
            />
          </div>
        </div>
        <div className="pl-head py-2">
          <label className="summary-label">DISCOUNT :</label>
          <div className="pl-search">
            <input
              className="data-entry-input-field --hiddens"
              disabled
              value={retrievedData && retrievedData?.roundOff ? retrievedData && retrievedData?.roundOff : "NULL"}
            />
          </div>
        </div>
        <div className="pl-head py-2">
          <label className="summary-label">INV AMT :</label>
          <div className="pl-search">
            <input
              className="data-entry-input-field --hiddens"
              disabled
              value={retrievedData && retrievedData?.bgAmtAndInterestAmt ? retrievedData && retrievedData?.bgAmtAndInterestAmt : "NULL"}
            />
          </div>
        </div>
        <div className="pl-head py-2">
          <label className="summary-label">BRIJESH :</label>
          <div className="pl-search">
            <input
              className="data-entry-input-field --hiddens"
              disabled
              value={retrievedData && retrievedData?.bruesh ? retrievedData && retrievedData?.bruesh : "NULL"}
            />
          </div>
        </div>
        <div className="pl-head py-2">
          <label className="summary-label">TOTAL SELL CBM :</label>
          <div className="pl-search">
            <input
              className="data-entry-input-field --hiddens"
              disabled
              value={totalSellCbm}
            />
          </div>
        </div>
        <div className="pl-head py-2">
          <label className="summary-label">TOTAL RATE DIFF :</label>
          <div className="pl-search">
            <input
              className="data-entry-input-field --hiddens"
              disabled
              value={Number(totalRateDiff).toFixed(2)}
            />
          </div>
        </div>
        <div className="pl-head py-2">
          <label className="summary-label">TOTAL CBM DIFF :</label>
          <div className="pl-search">
            <input
              className="data-entry-input-field --hiddens"
              disabled
              value={Number(totalCbmiff).toFixed(2)}
            />
          </div>
        </div>
        <div className="pl-head py-2">
          <label className="summary-label">TOTAL :</label>
          <div className="pl-search">
            <input
              className="data-entry-input-field --hiddens"
              disabled
              value={Number(totalpl).toFixed(2)}
            />
          </div>
        </div>
        <div className="pl-head py-2">
          <label className="summary-label">
            CAPITAL INT,SALARIES & HOUSE EXP:
          </label>
          <div className="pl-search">
            <input
              className="data-entry-input-field --hiddens"
              disabled
              value={Number(300000).toFixed(2)}
            />
          </div>
        </div>
        <div className="pl-head py-2">
          <label className="summary-label">NETT :</label>
          <div className="pl-search">
            <input
              className="data-entry-input-field --hiddens"
              disabled
              value={Number(totalpl).toFixed(0) - 300000}
            />
          </div>
        </div>
        <div className="d-flex justify-content-center py-3">
          <button className="summary-download-btn" onClick={() => downloadExcel()}>Download</button>
        </div>
      </form>

    </>
  );
};

export default PLForm;
