import React, { useEffect, useState } from "react";
import "../../global.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link } from "react-router-dom";
import Images from "../../common/assets";
import Strings from "../../common/res/String";
// import OtpInput from 'react-otp-input';
import OTPInput from "react-otp-input";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Controller, useForm } from "react-hook-form";
import InputField from "../../common/components/inputField/InputField";
import OtpInput from "../../common/components/OTP/OtpInput";
import { useMutation } from "react-query";
import axios from "axios";
import { Flag } from "@mui/icons-material";
import { useQuery } from "react-query";
import PopUpModal from "../../common/components/popupmodal/PopUpModal";
import Index from "./Index";
import Forget from "./Forget";
import Otp from "./Otp";
import Reset from "./Reset";
export default function Login() {
  // const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [typepassword, setTypePassword] = useState("password");
  const [view, setView] = useState("login");
  const [timeLeft, setTimeLeft] = useState(30);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const value = sessionStorage.getItem("view");

    setView(value)
  }, [sessionStorage.getItem("view")])
  const handleForgetPasswordClick = () => {
    sessionStorage.removeItem("OTP")
    sessionStorage.setItem("view", "forget")
    setView("forget");
  };





  // OTP Start
  let OTP_value = sessionStorage.getItem("OTP")
  const { otpfiled } = watch();
  const handleSubmits = () => {
    if (Number(otpfiled) == Number(OTP_value)) {

      sessionStorage.setItem("view", "reset")
      setView("reset");
      setValue("otpfiled", '')
    }
    else {
      alert("OTP is Not Valid")
    }
    // Send OTP to server
  };
  // const resendOtp = () => {
  //   // Resend OTP to server
  //   setTimeLeft(30);
  //   setValue("otpfiled",'')
  // };
  // OTP END





  //Login API Start
  const [isLoad, setIsLoad] = useState(false)
  // const { mutate, isLoading, isError, isSuccess } = useMutation(
  //   (postData) =>
  //     axios.post(`${process.env.REACT_APP_HOST}/users/login`, postData),
  //   {
  //     onSuccess: (data) => {
  //       sessionStorage.setItem("login", true);
  //       window.location.reload();
  //       navigator("/")
  //       alert("Login is Successfull")
  //     },
  //     onError: (error) => {
  //       // alert("Login is Not Successfully")
  //       setIsError(true)
  //       console.error("Mutation failed:", error);
  //     },
  //   }
  // );
  //Login API END



  const LoginSlide = ({ param }) => {
    console.log("param", param);
    switch (param) {
      case "login":
        return (
          <Index setView={setView} />
        );
      case "forget":
        return (
          <Forget setView={setView} />
        );
      case "OTP":
        return (

          <Otp view={view} setView={setView} />
        );
      case "reset":
        return (

          <Reset />
        );
      default:
        return (
          <Index setView={setView} />
        );
    }
  };
  const togglePopup = () => {
    setIsLoad(false);
  };
  return (
    <div className="container-fluid  main_container ">
      <div className=" inner_container d-flex align-items-center justify-content-center">
        <div className="sign-in-container">
          {/* <PopUpModal isOpen={isLoad} onClose={togglePopup} /> */}
          <LoginSlide param={view} />
        </div>
      </div>
    </div>
  );
}
