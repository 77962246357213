import React, { useState } from "react";
import { Drawer, Button, Box } from "@mui/material";

const RightSideDrawer = ({ toggleDrawer, isOpen, drawerContent, setIsActive, isIndexing }) => {


    // const drawerContent = (
    //     <Box
    //         sx={{ width: 250 }}
    //         role="presentation"
    //         onClick={toggleDrawer(false)}
    //         onKeyDown={toggleDrawer(false)}
    //     >
    //         <h3>Drawer Content</h3>
    //         <p>Place your items here.</p>
    //     </Box>
    // );

    return (
        <div>

            <Drawer
                sx={{ '& .MuiDrawer-paper': { width: '35vw' } }}
                anchor="right"
                open={isOpen}
                onClose={toggleDrawer(false)}
            >
                <div style={{ padding: '20px' }}>

                    <div style={{ cursor: 'pointer' }} onClick={toggleDrawer(false)}><p>X</p></div>
                    <h1>Container Images</h1>
                    {/* {drawerContent} */}
                    <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>

                        {drawerContent && drawerContent.base64Files.map((ele, i) => {
                            return (
                                <div style={{ width: "45%", height: '45%' }} >
                                    <img src={`data:image/jpeg;base64,${ele}`} style={{ width: "100%", height: "100%", objectFit: "contain" }} onClick={() => setIsActive([isIndexing, i])} />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </Drawer>
        </div>
    );
};

export default RightSideDrawer;
