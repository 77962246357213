import React, { useState } from 'react'
import Sidebar from '../../common/components/Sidebar/sidebar'
import TimeLine from '../../common/components/TimeLine/TimeLine'
import RightSidebar from '../../common/components/RightSidebar/RightSidebar'
import StatusWaiver from '../../common/components/statusWaiver/StatusWaiver'
import DownloadModel from '../../common/components/downloadModel/DownloadModel'
import PLPassword from '../../common/components/plPassword/PLPassword'

const StatusWaiverConfirm = () => {
  const [isCount, setIsCount] = useState(4);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2 p-0 col-md-3">
            <Sidebar />
          </div>

          <div className="col-sm-10 col-lg-10">
            <div className="row align-items-center">
              <div className="headerTimeLine px-5">
                <TimeLine currentStep={isCount} />
              </div>
            </div>
            <div className="row" style={{ paddingLeft: "3rem" }}>
              <div className="col-lg-9 p-0 m-0 d-flex align-items-center justify-content-center">
                <div className='statusWaiverMainContainer'>
                  <StatusWaiver isCount={isCount} />

                </div>

              </div>
              <div className="col-lg-3 p-0 right_col">
                <div className="headerTimeLine_left">
                  <RightSidebar setIsCount={setIsCount} islabel={'STATUS WAIVER'} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PLPassword />
    </>
  )
}

export default StatusWaiverConfirm