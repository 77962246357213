import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import DataManagement from "../../screen/dataManagement/DataManagement";
import CopyDocumentConfirm from "../../screen/copyDocumentConfirm/CopyDocumentConfirm";
import DataEntryForm from "../../screen/dataEntryForm/DataEntryForm";
import EWAY from "../../screen/Eway/Eway";
import Summary from "../../screen/summary/Summary";
import AddUser from "../../screen/newUser/AddUser";
import ViewUser from "../../screen/viewUser/ViewUser";
import EditUser from "../../screen/editUser/EditUser";
import PL from "../../screen/pl/PL";
import StatusWaiverConfirm from "../../screen/statusWaiverConfirm/StatusWaiverConfirm";
import ForwardcontractScreen from "../../screen/ForwardcontractScreen/ForwardcontractScreen";
// import ForwardContractScreen from "../../screen/ForwardcontractScreen/ForwardContractScreen";
import Login from "../../screen/login/Login";
import Sblc from "../../screen/sblcConfirm/Sblc";
import UserManagement from "../../screen/userManagement/UserManagement";
import DatamanagementView from "../../screen/datamanagementview/DatamanagementView";
import FormA1 from "../../screen/formA1/FormA1";
// import Demo from "../../TestDemo/Demo";
import { QueryClient, QueryClientProvider } from "react-query";
import { GlobalStateProvider } from "../GlobalProvider/GlobalStateProvider";
import AgentManagement from "../../screen/AgentManagementForm/AgentManagement";
import NewPackingListScreen from "../../screen/newpackingListScreen/NewPackingListScreen";
import ClearingCharges from "../../screen/clearingCharges/CearingCharges";
import DataEntryEditForm from "../components/dataEntryFormComponent/DataEntryEditForm";
import FileDocumentPage from "../../screen/FileDocument/FileDocumentPage";
import CopyDocumentFileShow from "../../screen/copyDocumentConfirm/CopyDocumentFileShow";
import Demo from "../../TestDemo/Demo";
import ForwardCover from "../../screen/ForwardCover/ForwardCover";
import { useSelector } from "react-redux";

export default function Navigation() {
  const queryClient = new QueryClient();
  // const value = sessionStorage.getItem("login");
  const value = useSelector((state) => state.login);
  // let value=true;
  return (
    <React.StrictMode>
      <BrowserRouter>
        <GlobalStateProvider>
          <QueryClientProvider client={queryClient}>
            {value ? (
              <Routes>
                {/* <Route path="/" element={<DataManagement />} /> */}
                <Route
                  path="/CopyDocumentConfirm"
                  element={<CopyDocumentConfirm />}
                />
                <Route path="/dataEntryForm" element={<DataEntryForm />} />
                <Route path="/EWAY" element={<EWAY />} />
                <Route path="/summary" element={<Summary />} />
                <Route path="/clearingCharges" element={<ClearingCharges />} />
                <Route path="/addUser" element={<AddUser />} />
                <Route path="/viewUser" element={<ViewUser />} />
                <Route path="/editUser" element={<EditUser />} />
                <Route path="/pL" element={<PL />} />
                <Route
                  path="/statusWaiverConfirm"
                  element={<StatusWaiverConfirm />}
                />
                <Route
                  path="/ForwardContractScreen"
                  element={<ForwardcontractScreen />}
                />
                <Route path="/sblc" element={<Sblc />} />
                {/* <Route path="/login" element={<Login />} /> */}
                <Route path="/userManagement" element={<UserManagement />} />
                <Route path="/agentManagement" element={<AgentManagement />} />
                <Route
                  path="/newPackingList"
                  element={<NewPackingListScreen />}
                />
                <Route path="/" element={<DatamanagementView />} />
                <Route
                  path="/dataEntryEditForm"
                  element={<DataEntryEditForm />}
                />
                <Route path="/formA1" element={<FormA1 />} />
                <Route path="/FileDocument" element={<FileDocumentPage />} />
                <Route path="/filesdownload" element={<CopyDocumentFileShow />} />
                <Route path="/demo" element={<Demo />} />
                <Route path="/forwardCover" element={<ForwardCover />} />
                {/* <Route path="/demo" element={<Demo/>}/> */}
              </Routes>
            ) : (
              <Routes>
                <Route path="/" element={<Login />} />
              </Routes>
            )}
          </QueryClientProvider>
        </GlobalStateProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
}
