import React, { useRef, useState } from "react";
import Sidebar from "../../common/components/Sidebar/sidebar";
import Strings from "../../common/res/String";
import TimeLine from "../../common/components/TimeLine/TimeLine";
import Model from "../../common/components/model/Model";
import RightSidebar from "../../common/components/RightSidebar/RightSidebar";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import JSZip from "jszip";
import * as FileSaver from "file-saver";
import PLPassword from "../../common/components/plPassword/PLPassword";
import { useSelector } from "react-redux";
import FileShare from "./FileShare";
import { useMutation } from "react-query";
import axios from "axios";
const Summary = () => {
  const [isCount, setIsCount] = useState(7);
  const [isModalOpen, setModalOpen] = useState(false);
  // const retrievedData = useSelector((state) => state?.formdata)
  const data = useSelector((state) => state?.formdata)
  console.log("data_LFK", data && data?.userForm)
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleConfirmModal = () => {
    // Perform delete operation
    console.log("User detail deleted");
    setModalOpen(false);
  };
  const [isProcessing, setIsProcessing] = useState(false);
  const handleDownloadAll = async () => {
    setIsProcessing(true);
    try {
      const pdf = new jsPDF("p", "pt", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      for (let i = 0; i < data?.userForm.length; i++) {
        const pageElement = document.getElementById(`page-${i}`);

        if (pageElement) {
          const canvas = await html2canvas(pageElement, { scale: 2 }); // Higher scale for better quality
          const imgData = canvas.toDataURL("image/png");
          const imgProps = pdf.getImageProperties(imgData);
          const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

          pdf.addImage(imgData, "PNG", 0, 30, pdfWidth, imgHeight);

          // Add a new page for all except the last element
          if (i < data.userForm.length - 1) {
            pdf.addPage();
          }
        }
      }

      // Save the PDF file
      pdf.save("Summary.pdf");
    } catch (error) {
      console.error("Error during PDF generation:", error);
    } finally {
      setIsProcessing(false);
    }
  };

  // const handleDownloadAll = async () => {

  //   setIsProcessing(true);

  //   try {
  //     // Convert the content of the page to PDF
  //     const pageContent = document.getElementById("summary_page");
  //     if (pageContent) {
  //       const canvas = await html2canvas(pageContent);
  //       const imgData = canvas.toDataURL("image/png");

  //       // Create PDF with proper dimensions
  //       const pdf = new jsPDF("p", "pt", "a4");
  //       const imgProps = pdf.getImageProperties(imgData);
  //       const pdfWidth = pdf.internal.pageSize.getWidth();
  //       const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  //       const topGap = 48;
  //       pdf.addImage(imgData, "PNG", 0, topGap, pdfWidth, pdfHeight);
  //       pdf.save("Summary.pdf");
  //       // Save the PDF directly and convert to Base64

  //     }

  //   } catch (error) {
  //     console.error("Error during download:", error);
  //   } finally {
  //     setIsProcessing(false);
  //   }
  // };
  // const retrievedData = JSON.parse(sessionStorage.getItem("formdata"));
  // console.log("retrievedData", retrievedData);
  const payslipRef = useRef();
  // console.log("retrievedData_data_data", data.userForm);
  const { mutate, isLoading, isError, isSuccess } = useMutation(
    (postData) => {
      // Log postData before making the API request
      console.log("postData before API call:", postData);

      return axios
        .post(`${process.env.REACT_APP_HOST}api/file/upload`, postData, {
          maxContentLength: Infinity,
          maxBodyLength: Infinity,
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("API response:", response.data);
          return response.data; // Return response data if needed
        })
        .catch((error) => {
          console.error("Error occurred:", error);
          throw error; // Re-throw error to be caught by onError
        });
    },
    {
      onSuccess: () => {
        console.log("User added successfully..");
        // setPopupOpen(1);
      },
      onError: (error) => {
        console.error("Mutation failed:", error);
      },
    }
  );
  const handleShareIT = async (isShare = true) => {
    try {
      const pdf = new jsPDF("p", "mm", "a4", true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // If sharing, target the specific page element; if downloading, loop through the userForm data.
      const targetElement = isShare ? document.getElementById("summary_page") : null;
      const pageElements = isShare
        ? targetElement.querySelectorAll("#summary_page > div > div")
        : data?.userForm.map((_, index) => document.getElementById(`page-${index}`));

      if (!pageElements || pageElements.length === 0) {
        console.error("No pages to process");
        return;
      }

      // Process each page
      for (let i = 0; i < pageElements.length; i++) {
        const canvas = await html2canvas(pageElements[i], { scale: 2 });
        const imgData = canvas.toDataURL("image/png");
        const imgProps = pdf.getImageProperties(imgData);
        const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

        // Add image to PDF
        pdf.addImage(imgData, "PNG", 0, 30, pdfWidth, imgHeight);

        // Add a new page if not the last element
        if (i < pageElements.length - 1) {
          pdf.addPage();
        }
      }

      if (isShare) {
        // For sharing, return the PDF blob to be used for sharing
        return pdf.output("blob");
      } else {
        // For downloading, save the PDF as a file
        pdf.save("download.pdf");
      }
    } catch (error) {
      console.error("Error during PDF generation:", error);
    } finally {
      // Optionally handle any post-processing, e.g., updating state.
    }
  }

  // const handleShareIT = async (isShare = true) => {

  //   const shareInvoice = document.getElementById("summary_page");


  //   return
  //   try {
  //     const pdf = new jsPDF("p", "mm", "a4", true);
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = pdf.internal.pageSize.getHeight();

  //     // If sharing, target the specific page element; if downloading, loop through the userForm data.
  //     const targetElement = isShare
  //       ? document.getElementById("summary_page")
  //       : null; // For download, we will process multiple pages
  //     const pageElements = isShare
  //       ? targetElement.querySelectorAll("#summary_page > div > div")
  //       : data?.userForm.map((_, index) => document.getElementById(`page-${index}`));

  //     if (!pageElements || pageElements.length === 0) {
  //       console.error("No pages to process");
  //       return;
  //     }

  //     for (let i = 0; i < pageElements.length; i++) {
  //       const canvas = await html2canvas(pageElements[i], { scale: 2 });
  //       const imgData = canvas.toDataURL("image/png");
  //       const imgProps = pdf.getImageProperties(imgData);
  //       const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

  //       pdf.addImage(imgData, "PNG", 0, 30, pdfWidth, imgHeight);

  //       // Add a new page if not the last element
  //       if (i < pageElements.length - 1) {
  //         pdf.addPage();
  //       }
  //     }

  //     if (isShare) {
  //       // For sharing, return the PDF blob to be used for sharing
  //       return pdf.output("blob");
  //     } else {
  //       // For downloading, save the PDF as a file
  //       return pdf.output("blob");
  //     }
  //   } catch (error) {
  //     console.error("Error during PDF generation:", error);
  //   } finally {
  //     // setIsProcessing(false);
  //   }

  // }
  const shareOnWhatsApp = async () => {
    try {
      // Generate the PDF blob
      const pdfBlob = await handleShareIT();
      console.log("shareInvoice_formData", pdfBlob)
      // Create FormData for file upload
      if (!(pdfBlob instanceof Blob)) {
        console.error("pdfBlob is not a valid Blob object");
        return;
      }
      const formData = new FormData();
      formData.append("file", pdfBlob, "invoice.pdf");
      console.log("shareInvoice_formData2", formData.get("file"))
      for (let [key, value] of formData.entries()) {
        console.log("KDKFKD", key, value);
      }
      // Upload the PDF to the server and get the file URL
      axios.post(`${process.env.REACT_APP_HOST}api/file/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Optional: axios automatically sets this
        },
      })
        .then((response) => {
          console.log("File uploaded successfully:", response.data);
          let fileUrl = response?.data || "";

          if (!fileUrl) {
            console.error("No file URL received from the server");
            return;
          }

          // Replace the base URL (localhost) with the new base URL
          // const newBaseUrl = "https://api.2kvirtualworld.com/HOSTELBILLINGDEV";
          // fileUrl = fileUrl?.replace(
          //   "http://localhost:8080/hostel",
          //   newBaseUrl
          // );

          // WhatsApp message with the modified download URL
          const message = `Here is your invoice.Click here to download: ${fileUrl}`;

          // Construct the WhatsApp URL with the pre-filled message
          const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(message || '')}`;
          window.open(whatsappUrl, "_blank");
          alert("File Send on it")
          // const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;

          // Open WhatsApp with the pre-filled message
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
        });

    } catch (error) {
      console.error("Error generating or sharing invoice:", error);
    }
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row p-0">
          <div className="col-lg-2 p-0 col-md-3">
            <Sidebar />
          </div>

          <div className="col-lg-10">
            <div className="row align-items-center">
              <div className="headerTimeLine px-5">
                <TimeLine currentStep={isCount} />
              </div>
            </div>
            <div className="row " style={{ paddingLeft: "3rem" }}>
              <div className="col-lg-9 p-0 m-0 d-flex align-items-center justify-content-center">

                <div className="summaryMainContainer p-0 m-0">
                  <div id="summary_page">
                    <div ref={payslipRef} style={{ padding: "20px", backgroundColor: "#fff" }}>
                      <h1 style={{ textAlign: "center", margin: "20px 0", color: "#333" }}>
                        Container Details
                      </h1>
                      {
                        data && data?.userForm.map((item, index) => (

                          <>

                            {item?.status !== "Inactive" &&
                              <div
                                id={`page-${index}`} // Unique ID for each page
                                style={{
                                  padding: "20px",
                                  width: "210mm",
                                  height: "297mm",
                                  backgroundColor: "#fff",
                                  border: "1px solid #ddd",
                                  borderRadius: "8px",
                                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                                  boxSizing: "border-box",
                                  marginBottom: "20px",
                                  pageBreakAfter: "always",
                                  overflow: "hidden"
                                }}
                              >
                                <h3>Container: {item.containerNo}</h3>
                                <p><strong>Avg Length:</strong> {item.averageLength || "N/A"} m</p>
                                <p><strong>Avg Cft/Avg Girth:</strong> {item.avgCftAvgGirth || "N/A"}</p>
                                <p><strong>CBM:</strong> {item.grossCbm}</p>
                                <p><strong>Pieces:</strong> {item.pcs}</p>
                                <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                  {item.containerImage.map((imgSrc, imgIndex) => (
                                    <div
                                      key={imgIndex}
                                      style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        width: "30%"
                                      }}
                                    >
                                      <img
                                        src={`data:image/jpeg;base64,${imgSrc}`}
                                        alt="Container Image"
                                        style={{ width: "100%", height: "100%" }}
                                      />
                                    </div>
                                  ))}
                                </div>
                              </div>}
                          </>
                        ))
                      }
                    </div>
                    {/* <FileShare payslipRef={payslipRef} /> */}
                  </div>

                  {/* <div id="summary_page">
                    <div className="summary-head px-4">
                      <label className="summary-label">
                        {Strings.billnumber}
                      </label>
                      <div style={{ width: "35%" }}>
                        <input
                          type="text"
                          name="beno"
                          className="billNoSearchField --hiddens"
                          placeholder="3421631"
                          disabled
                          value={retrievedData && retrievedData?.blNo}
                        />
                      </div>
                    </div>
                    <div
                      className={`${retrievedData && retrievedData?.userForm.length > 5
                        ? "summary-table-container"
                        : "summary-table-container_single"
                        } mt-2 px-4 pt-3`}
                    >
                      <table
                        className={`${retrievedData && retrievedData?.userForm.length > 5
                          ? "summary-table"
                          : "summary-table_single"
                          }`}
                      >
                        <thead className="summary-table-head">
                          <tr>
                            <th>S.no</th>
                            <th>CONTAINER NO</th>
                            <th>LENGTH</th>
                            <th>AVG CFT/AVG GIRTH</th>
                            <th>PCS</th>
                            <th>CBM</th>
                          </tr>
                        </thead>
                        <tbody>
                          {retrievedData &&
                            retrievedData?.userForm?.map((ele, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{ele?.containerNo}</td>
                                <td>{ele?.averageLength}</td>
                                <td>{ele?.avgCftAvgGirth}</td>
                                <td>{ele?.pcs}</td>
                                <td>{ele?.grossCbm}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                    <div>
                      {

                      }
                      <div>
                         </div>
              </div>
            </div> */}
                  <div style={{ display: "flex", justifyContent: "center", gap: 10 }}>
                    <input
                      type="submit"
                      value="Share"
                      className="summary-download-btn"
                      onClick={() => shareOnWhatsApp()}
                    />
                    {/* <div
                      // className="summary-download"
                      onClick={() => {
                        handleDownloadAll();
                      }}
                    > */}
                    <button
                      className="summary-download-btn"
                      onClick={() => {
                        handleDownloadAll();
                      }}
                    >
                      {isProcessing ? "Loading" : "Download"}
                    </button>

                  </div>
                  <div>

                  </div>
                </div>
              </div>
              <div className="col-lg-3 p-0 right_col">
                <div className="headerTimeLine_left">
                  <RightSidebar setIsCount={setIsCount} islabel="SUMMARY" />
                </div>
              </div>
            </div>
          </div >
        </div >
      </div >
      <PLPassword />
    </>
  );
};

export default Summary;
