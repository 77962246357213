
import { FORMDATA_DETAILS, LOGIN_SUCCESS, LOGOUT, COPYDOC_DETAILS, PL, PLSTATUS, PLSTAPLSTATUSDISACTIVETUS } from "./ActionTypes";

export const loginSuccess = () => {
    return {
        type: LOGIN_SUCCESS,
    };
};
export const logout = () => {
    return {
        type: LOGOUT,
    };
};
export const pl = () => {
    return {
        type: PL,
    };
};
export const plstatus = () => {
    return {
        type: PLSTATUS,
    };
};
export const plstatusdisactive = () => {
    return {
        type: PLSTAPLSTATUSDISACTIVETUS,
    };
};

export const copydocument = (copydocument) => {
    return {
        type: COPYDOC_DETAILS,
        payload: copydocument,
    };
};
export const formdataDetails = (formdataDetails) => {
    return {
        type: FORMDATA_DETAILS,
        payload: formdataDetails,
    };
};
