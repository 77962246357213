import React, { useEffect, useState } from "react";
import Sidebar from "../../common/components/Sidebar/sidebar";
import TimeLine from "../../common/components/TimeLine/TimeLine";
import "react-step-progress-bar/styles.css";
import RightSidebar from "../../common/components/RightSidebar/RightSidebar";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { useLocation, useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import PLPassword from "../../common/components/plPassword/PLPassword";
import { useSelector } from "react-redux";

export default function CopyDocumentConfirm({ route }) {

  const base64ToPdfUrl = (base64String) => {
    if (!base64String) {
      console.error("Base64 string is undefined or empty");
      return null;
    }

    try {
      // Decode base64 string
      const byteCharacters = atob(base64String);

      // Convert to byte array
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      // Create a Blob and URL
      const blob = new Blob([byteArray], { type: "application/pdf" });
      return URL.createObjectURL(blob);
    } catch (error) {
      console.error("Error processing base64 string:", error);
      return null;
    }
  };



  const { state } = useLocation();
  console.log("COPYYVAl", route && route, state);

  const [isCount, setIsCount] = useState(1);
  const [isModalOpen, setModalOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [loading, setLoading] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleConfirmModal = () => {
    console.log("User detail deleted");
    setModalOpen(false);
  };

  const retrievedData = useSelector((state) => state?.copydoc);;
  console.log("retrievedData_ccppy", retrievedData);

  useEffect(() => {
    const url = retrievedData ? base64ToPdfUrl(retrievedData) : [];
    setPdfUrl(url);
  }, [retrievedData]);

  const navigate = useNavigate();

  let data = [
    { label: "FILE_NAME.pdf", files: pdfUrl },
    { label: "FILE_NAME2.pdf", files: pdfUrl },
    { label: "FILE_NAME3.pdf", files: pdfUrl },
    { label: "FILE_NAME4.pdf", files: pdfUrl },
    { label: "FILE_NAME5.pdf", files: pdfUrl },
    { label: "FILE_NAME6.pdf", files: pdfUrl },
    { label: "FILE_NAME7.pdf", files: pdfUrl },
    { label: "FILE_NAME8.pdf", files: pdfUrl },
    { label: "FILE_NAME9.pdf", files: pdfUrl },
    { label: "FILE_NAME10.pdf", files: pdfUrl },
    { label: "FILE_NAME11.pdf", files: pdfUrl },
    { label: "FILE_NAME12.pdf", files: pdfUrl },
  ];

  // Download All Files Start

  // Downloads a ZIP file containing multiple PDFs

  const base64ToBlob = (base64String, contentType) => {
    const sliceSize = 512;
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };
  const downloadZipWithPdfs = async (base64Pdfs, fileNames) => {
    const zip = new JSZip();
    setLoading(true)
    base64Pdfs.forEach((pdfBase64, index) => {
      // Remove base64 prefix
      const base64Data = pdfBase64.replace(/^data:application\/pdf;base64,/, "");
      const pdfBlob = base64ToBlob(base64Data, 'application/pdf');
      zip.file(`document${index + 1}.pdf`, pdfBlob);
    });

    const zipBlob = await zip.generateAsync({ type: "blob" });
    setLoading(false)
    saveAs(zipBlob, "documents.zip");
  };

  // Download All Files End

  console.log("pdfUrl", retrievedData);
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2 p-0 col-md-3">
            <Sidebar />
          </div>

          <div className="col-sm-10 col-lg-10">
            <div className="row align-items-center">
              <div className="headerTimeLine px-5">
                <TimeLine currentStep={isCount} />
              </div>
            </div>
            <div className="row" style={{ paddingLeft: "3rem" }}>
              <div
                className="col-lg-9 copy_doc_scroll"
                style={{
                  paddingTop: "4px",
                  height: "calc(100vh - 20px)",
                  overflow: "scroll",
                  paddingBottom: "3rem",
                }}
              >
                {
                  retrievedData == null ? <>
                    <div className="row mt-5">
                      <h3 style={{ color: "#000", fontSize: "18px", fontWeight: 500, }}>There is no file available. Please upload the file</h3>
                    </div>
                  </> :

                    <>

                      <div className="row mt-5">
                        {retrievedData && retrievedData?.map((ele, i) => (
                          <div className="col-lg-4 p-3" key={i}>
                            <div
                              onClick={() => navigate('/filesdownload', { state: { files: ele?.base64.replace(/^data:application\/pdf;base64,/, ''), count: 1 } })}
                              style={{
                                background: "#fff",
                                filter: "drop-shadow(-3px -3px 4px rgba(0, 0, 0, 0.25)) drop-shadow(3px 3px 4px rgba(0, 0, 0, 0.25))",
                                borderRadius: "10px",
                                position: "relative",
                                cursor: "pointer",
                              }}
                            >
                              <div style={{ textAlign: "center", height: 130 }}>
                                <iframe
                                  onClick={() => navigate('/filesdownload', { state: { files: ele?.base64, count: 1 } })}
                                  scrolling="no"
                                  src={ele?.base64.replace(/^data:application\/pdf;base64,/, '')}
                                  className="copy_docconfi"
                                  type="application/pdf"
                                  width="253px"
                                  height="130px"
                                  style={{
                                    border: "none",
                                    overflow: "hidden",
                                    background: "#fff",
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  background: "#543310",
                                  padding: "5px 20px",
                                  borderRadius: "0px 0px 10px 10px",
                                }}
                              >
                                <p style={{ margin: 0, color: "#fff" }}>
                                  {
                                    ele?.fileName ? ele?.fileName :

                                      `Files${i > 0 ? i : ""}`
                                  }
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="mt-3 text-center">
                        {/* <button className="btn_download_file" onClick={() => downloadZipWithPdfs(retrievedData && retrievedData?.copydocument, 'documents')}>{loading ? "Loading..." : 'Download All'}</button> */}
                      </div>
                    </>

                }
              </div>
              <div className="col-lg-3 p-0 right_col">
                <div className="headerTimeLine_left">
                  <RightSidebar setIsCount={setIsCount} islabel="COPY DOCUMENT" />
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
      <PLPassword />
    </>
  );
}
