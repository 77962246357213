import React, { useEffect, useState } from "react";
import Sidebar from "../../common/components/Sidebar/sidebar";
import TimeLine from "../../common/components/TimeLine/TimeLine";
import RightSidebar from "../../common/components/RightSidebar/RightSidebar";
import PLForm from "../../common/components/PLFormComponent/PLForm";
import FileDocument from "../../common/components/FileDocument/FileDocument";
import { useNavigate } from "react-router-dom";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import PLPassword from "../../common/components/plPassword/PLPassword";
import { useSelector } from "react-redux";

const base64ToPdfUrl = (base64String) => {
  if (!base64String) {
    console.error("Base64 string is undefined or empty");
    return null;
  }
  console.log("base64String_CCC", base64String);

  try {
    // Decode base64 string
    const byteCharacters = atob(base64String);

    // Convert to byte array
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    // Create a Blob and URL
    const blob = new Blob([byteArray], { type: "application/pdf" });
    return URL.createObjectURL(blob);
  } catch (error) {
    console.error("Error processing base64 string:", error);
    return null;
  }
};

// let retrievedData;

// console.log("retrievedData", retrievedData?.userForm[0]?.containerImage);

// if (
//   retrievedData?.advicedocument &&
//   retrievedData?.bldocument &&
//   retrievedData?.boedocument &&
//   retrievedData?.codocument &&
//   retrievedData?.invoicedocument &&
//   retrievedData?.phytodocument &&
//   retrievedData?.quotedocument &&
//   retrievedData?.sblcDocument &&
//   retrievedData?.shippingbilldocument &&
//   retrievedData?.swiftintdocument
// ) {
//   console.log("ENTER IF");
// } else {
//   console.log("ENTER else");
// }
const FileDocumentPage = () => {
  const [loading, setLoading] = useState(false);
  const retrievedData = useSelector((state) => state?.formdata);
  let array = [
    retrievedData && retrievedData?.advicedocument,
    retrievedData && retrievedData?.bldocument,
    retrievedData && retrievedData?.boedocument,
    retrievedData && retrievedData?.codocument,
    retrievedData && retrievedData?.invoicedocument,
    retrievedData && retrievedData?.phytodocument,
    retrievedData && retrievedData?.quotedocument,
    retrievedData && retrievedData?.sblcDocument,
    retrievedData && retrievedData?.shippingbilldocument,
    retrievedData && retrievedData?.swiftintdocument,
    // retrievedData?.userForm
    //  val.containerImage}),
  ];
  let formImg = retrievedData?.userForm;
  const [isCount, setIsCount] = useState(10);
  const [pdfUrl, setPdfUrl] = useState("");

  useEffect(() => {
    const url = base64ToPdfUrl(array);
    setPdfUrl(url);
  }, []);
  console.log("pdfUrl", pdfUrl, array);
  let data = [
    {
      label: "FILE_NAME.FILE_TYPE",
      files: pdfUrl,
    },
    {
      label: "FILE_NAME2.FILE_TYPE",
      files: pdfUrl,
    },
    {
      label: "FILE_NAME3.FILE_TYPE",
      files: pdfUrl,
    },
    {
      label: "FILE_NAME4.FILE_TYPE",
      files: pdfUrl,
    },
    {
      label: "FILE_NAME5.FILE_TYPE",
      files: pdfUrl,
    },
    {
      label: "FILE_NAME6.FILE_TYPE",
      files: pdfUrl,
    },
    {
      label: "FILE_NAME7.FILE_TYPE",
      files: pdfUrl,
    },
    {
      label: "FILE_NAME8.FILE_TYPE",
      files: pdfUrl,
    },
    {
      label: "FILE_NAME9.FILE_TYPE",
      files: pdfUrl,
    },
    {
      label: "FILE_NAME10.FILE_TYPE",
      files: pdfUrl,
    },
    {
      label: "FILE_NAME11.FILE_TYPE",
      files: pdfUrl,
    },
    {
      label: "FILE_NAME12.FILE_TYPE",
      files: pdfUrl,
    },
  ];
  const navigate = useNavigate();
  console.log("retrived data ", retrievedData);
  // console.log("array.length", formImg);

  const downloadFormImg = formImg.map((val) => {
    return val.containerImage;
  });
  const downloadFormNo = formImg.map((val) => {
    return val.containerNo;
  });

  // console.log("downloadFormImg", downloadFormImg);

  // console.log(
  //   "retrievedData?.userForm",
  //   retrievedData?.userForm[0]?.containerNo
  // );

  let label = [
    "ADVICE DOCUMENT",
    "BL DOCUMENT",
    "BOE DOCUMENT",
    "CO DOCUMENT",
    "INVOICE DOCUMENT",
    "PHYTO DOCUMENT",
    "QUOTE DOCUMENT",
    "SBLC DOCUMENT",
    "SHIPPING BILL DOCUMENT",

    "SWIFT INT DOCUMENT",

    // "SHIPPING BILL DOCUMENT",
    // "CONTAINER IMAGE"
    // retrievedData && retrievedData?.userForm?.containerNo
    retrievedData &&
      retrievedData?.userForm?.map((val) => {
        return val?.containerNo;
      }),
  ];

  const base64ToBlob = (base64String, contentType) => {
    const sliceSize = 512;
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters?.length; offset += sliceSize) {
      const slice = byteCharacters?.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  const downloadZipWithPdfs = async (
    base64Pdfs,
    downloadFormImg,
    // downloadFormNo,
    fileNames
  ) => {
    console.log("functionImage", downloadFormImg);

    const zip = new JSZip();
    setLoading(true);

    downloadFormImg?.forEach((imageArray, index) => {
      console.log("forEachData", imageArray);

      imageArray.forEach((image, imageIndex) => {
        const imageBlob = base64ToBlob(image, "application/jpeg");
        zip.file(`Container ${index + 1}/${imageIndex + 1}.jpeg`, imageBlob);
      });

      // const pdfBlob = base64ToBlob(data, "application/jpeg");
      // const folderName = "image s"; // You can change this to your desired folder name
      // zip.file(`${folderName}/${index}.jpeg`,  pdfBlob);
    });

    base64Pdfs.forEach((pdfBase64, index) => {
      const base64Data = pdfBase64?.replace(
        /^data:application\/pdf;base64,/,
        ""
      );
      const pdfBlob = base64ToBlob(base64Data, "application/pdf");
      zip.file(`${label[index]}.pdf`, pdfBlob);
    });

    const zipBlob = await zip.generateAsync({ type: "blob" });
    setLoading(false);
    saveAs(zipBlob, "FileDocument.zip");
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2 p-0 col-md-3">
            <Sidebar />
          </div>

          {/* <div className="col-sm-10 col-lg-10">
            <div className="row align-items-center">
              <div className="headerTimeLine px-5">
                <TimeLine currentStep={isCount} />
              </div>
            </div>
            <div className="row" style={{ paddingLeft: "3rem" }}>
              <div className="col-lg-9 p-0 m-0 d-flex align-items-center justify-content-center">
                <div className="plMainContainer">
                  <FileDocument />
                </div>
              </div>
              <div className="col-lg-3 p-0 right_col">
                <div className="headerTimeLine_left">
                  <RightSidebar setIsCount={setIsCount} islabel={'FILE DOCUMENT'} />
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-sm-10 col-lg-10">
            <div className="row align-items-center">
              <div className="headerTimeLine px-5">
                <TimeLine currentStep={isCount} />
              </div>
            </div>
            <div className="row" style={{ paddingLeft: "3rem" }}>
              <div
                className="col-lg-9 copy_doc_scroll"
                style={{
                  paddingTop: "4px",
                  height: "calc(100vh - 20px)",
                  overflow: "scroll",
                  paddingBottom: "3rem",
                }}
              >
                {array?.length == 0 || formImg?.length == 0 ? (
                  <>
                    <div className="row mt-5">
                      <h3
                        style={{
                          color: "#000",
                          fontSize: "18px",
                          fontWeight: 500,
                        }}
                      >
                        There is no file available. Please upload the file
                      </h3>
                    </div>
                  </>
                ) : (
                  <div className="row mt-5">
                    {array?.map((ele, i) => (
                      <div className="col-lg-4 p-3">
                        {array[i] == null ? (
                          <></>
                        ) : (
                          <div
                            onClick={() =>
                              navigate("/filesdownload", {
                                state: { files: ele, count: 10 },
                              })
                            }
                            style={{
                              background: "#fff",
                              filter:
                                "drop-shadow(-3px -3px 4px rgba(0, 0, 0, 0.25)) drop-shadow(3px 3px 4px rgba(0, 0, 0, 0.25))",
                              borderRadius: "10px",
                              position: "relative",
                              cursor: "pointer",
                              overflow: "hidden",
                            }}
                          >
                            <div style={{ textAlign: "center", height: 130 }}>
                              <iframe
                                onClick={() =>
                                  navigate("/filesdownload", { state: ele })
                                }
                                scrolling="no"
                                src={pdfUrl}
                                // src={data:text/html,<html><body style="margin:0;"><img src='${pdfUrl}' style='width:100%;height:100%;'/></body></html>}
                                className="copy_docconfi"
                                type="application/pdf"
                                width="240px"
                                height="130px"
                                style={{
                                  border: "none",

                                  overflow: "hidden",
                                  background: "#fff",
                                }}
                              />
                            </div>
                            <div
                              style={{
                                background: "#543310",
                                padding: "5px 20px",
                                // textAlign:'center',
                                borderRadius: "0px 0px 10px 10px",
                              }}
                            >
                              <p style={{ margin: 0, color: "#fff" }}>
                                {label[i]}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                    {formImg?.map((ele, i) => {
                      console.log("LLLL", ele?.containerImage?.length > 1);
                      return (
                        <>
                          {ele?.containerImage.length > 1 ? (
                            <div className="col-lg-4 p-3">
                              <div
                                // onClick={() =>
                                //   navigate("/filesdownload", {
                                //     state: { files: ele, count: 10 },
                                //   })
                                // }
                                style={{
                                  background: "#fff",
                                  filter:
                                    "drop-shadow(-3px -3px 4px rgba(0, 0, 0, 0.25)) drop-shadow(3px 3px 4px rgba(0, 0, 0, 0.25))",
                                  borderRadius: "10px",
                                  position: "relative",
                                  cursor: "pointer",
                                }}
                              >
                                <div
                                  style={{
                                    textAlign: "center",
                                    height: 130,
                                    overflow: "hidden",
                                  }}
                                >
                                  {/* <iframe
                              onClick={() =>
                                navigate("/filesdownload", { state: ele })
                              }
                              scrolling="no"
                              src={pdfUrl}
                              // src={data:text/html,<html><body style="margin:0;"><img src='${pdfUrl}' style='width:100%;height:100%;'/></body></html>}
                              className="copy_docconfi"
                              type="application/pdf"
                              width="253px"
                              height="130px"
                              style={{
                                border: "none",
                                overflow: "hidden",
                                background: "#fff",
                              }}
                            /> */}
                                  <div
                                    onClick={() =>
                                      navigate("/filesdownload", {
                                        state: { imgValue: ele.containerImage },
                                      })
                                    }
                                    style={{
                                      height: "100%",
                                      overflowY: "scroll",
                                    }}
                                  >
                                    {ele.containerImage.map((val) => {
                                      console.log("value", val);
                                      return (
                                        <>
                                          <div>
                                            <img
                                              style={{
                                                border: "none",
                                                overflowY: "scroll",
                                                background: "#fff",
                                                width: "100px",
                                              }}
                                              src={`data:image/png;base64,${val}`}
                                              alt={ele?.containerNo}
                                            />
                                          </div>
                                        </>
                                      );
                                    })}
                                  </div>
                                </div>
                                <div
                                  style={{
                                    background: "#543310",
                                    padding: "5px 20px",
                                    // textAlign:'center',
                                    borderRadius: "0px 0px 10px 10px",
                                  }}
                                >
                                  <p style={{ margin: 0, color: "#fff" }}>
                                    {ele?.containerNo}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      );
                    })}
                  </div>
                )}
                <div className="mt-3 text-center">
                  {/* {loading ? <p>Downloading...</p> : null} */}
                  <button
                    className="btn_download_file"
                    onClick={() =>
                      downloadZipWithPdfs(
                        array,
                        downloadFormImg,
                        // downloadFormNo,
                        "documents"
                      )
                    }
                  >
                    {loading ? "Loading..." : "Download All"}
                  </button>
                </div>
                {/* <div className="dataEntryMainContainer --copydocument">
              <FormHeader header={Strings.data_entry} />
              <div className="data-entry-form-block">

              <DataForm />
              </div>
            </div> */}
              </div>
              <div className="col-lg-3 p-0 right_col">
                <div className="headerTimeLine_left">
                  {/* <SimpleBar style={{ maxHeight: 300 }}> */}

                  <RightSidebar
                    setIsCount={setIsCount}
                    islabel="FILE DOCUMENT"
                  />
                  {/* </SimpleBar> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PLPassword />
    </>
  );
};

export default FileDocumentPage;
